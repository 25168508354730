<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="#">Geral</a></li>
          <li class="breadcrumb-item">
            <a href="#">Cadastros Administrativos</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ mainTitle }}
          </li>
        </ol>
        <h6 class="slim-pagetitle">{{ mainTitle }}</h6>
      </div>

      <div class="card">
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
              <a class="nav-link active" href="#general" data-toggle="tab">
                <i class="fa fa-file-text-o mg-r-5"></i> Geral
              </a>
            </li>
            <li class="nav-item" v-if="!isNewRecord()">
              <a class="nav-link" href="#contacts" data-toggle="tab">
                <i class="fa fa-users mg-r-5"></i> Contatos (Usuários)
              </a>
            </li>
            <li class="nav-item" v-if="isCustomer && !isNewRecord()">
              <a class="nav-link" href="#operational" data-toggle="tab">
                <i class="fa fa-binoculars mg-r-5"></i> Fiscal
              </a>
            </li>

            <li class="nav-item" v-if="isCustomer && !isNewRecord()">
              <a class="nav-link" href="#instructions" data-toggle="tab">
                <i class="fa fa-ship mg-r-5"></i> Instruções Operacionais
              </a>
            </li>

            <li class="nav-item" v-if="isCustomer && !isNewRecord()">
              <a class="nav-link" href="#providers" data-toggle="tab">
                <i class="fa fa-users mg-r-5"></i> Fornecedores</a
              >
            </li>

            <li class="nav-item" v-if="isCustomer && !isNewRecord()">
              <a class="nav-link" href="#new-procedure" data-toggle="tab">
                <i class="fa fa-check mg-r-5"></i> Requisitos
              </a>
            </li>

            <li class="nav-item" v-if="isCustomer && !isNewRecord()">
              <a class="nav-link" href="#sale" data-toggle="tab">
                <i class="fa fa-magic mg-r-5"></i> Comercial
              </a>
            </li>

            <!-- <li class="nav-item" v-if="isCustomer && !isNewRecord()">
                            <a class="nav-link" href="#documents" data-toggle="tab">  <i class="fa fa-inbox mg-r-5"></i> Documentos </a>
                        </li> -->

            <li class="nav-item" v-if="isCustomer && !isNewRecord()">
              <a class="nav-link" href="#history" data-toggle="tab">
                <i class="fa fa-calendar mg-r-5"></i> Histórico
              </a>
            </li>

            <li class="nav-item" v-if="isCustomer && !isNewRecord()">
              <a class="nav-link" href="#monitoring" data-toggle="tab">
                <i class="fa fa-eye mg-r-5"></i> Monitoramento
              </a>
            </li>
          </ul>
        </div>
        <!-- card-header -->

        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="general">
              <companies-form-general
                :company="company"
                :isNewRecord="isNewRecord()"
              />
            </div>

            <div class="tab-pane" id="contacts">
              <companies-list-contacts
                :company="company"
                :contacts="contacts"
              />
            </div>

            <div class="tab-pane" id="operational" v-if="!isNewRecord()">
              <companies-form-fiscal
                :company="company"
                :oldModel="oldModel"
                :isNewRecord="isNewRecord()"
              />
            </div>

            <div class="tab-pane" id="providers" v-if="!isNewRecord()">
              <companies-list-providers
                :company="company"
                :providers="providers"
                @updateProviders="updateProviders"
              />
            </div>

            <div class="tab-pane" id="new-procedure" v-if="!isNewRecord()">
              <companies-form-new-procedures :company="company" />
            </div>

            <div class="tab-pane" id="instructions" v-if="!isNewRecord()">
              <companies-form-instructions
                :company="company"
                :oldModel="oldModel"
                :isNewRecord="isNewRecord()"
              />
            </div>

            <div class="tab-pane" id="sale" v-if="!isNewRecord()">
              <companies-form-sales
                :company="company"
                :oldModel="oldModel"
                :isNewRecord="isNewRecord()"
              />
            </div>

            <!-- <div class="tab-pane" id="documents" v-if="!isNewRecord()">
                            <companies-form-documents :company="company" :oldModel="oldModel" :isNewRecord="isNewRecord()"/>
                        </div> -->

            <div class="tab-pane" id="history" v-if="!isNewRecord()">
              <companies-form-history
                :company="company"
                :oldModel="oldModel"
                :isNewRecord="isNewRecord()"
              />
            </div>

            <div class="tab-pane" id="monitoring" v-if="!isNewRecord()"></div>
          </div>
          <!-- tab-content -->
        </div>
        <!-- card-body -->
      </div>
      <!-- card -->
    </div>
  </div>
</template>

<script>
import config from "@/config.js";
import Axios from "axios";

// Children
import CompaniesFormGeneral from "@/views/general/companies/Form.General.vue";
import CompaniesFormFiscal from "@/views/general/companies/Form.Fiscal.vue";
import CompaniesFormInstructions from "@/views/general/companies/Form.Instructions.vue";
import CompaniesFormSales from "@/views/general/companies/Form.Sales.vue";
// import CompaniesFormDocuments   from '@/views/general/companies/Form.Documents.vue';
import CompaniesFormHistory from "@/views/general/companies/Form.History.vue";
import ContactList from "@/views/general/contacts/List.Partial.vue";

import CompaniesFormNewProcedures from "@/views/general/companies/Form/NewProcedures";
import CompaniesListProviders from "@/views/general/companies/Form/Providers";

import { EventBus } from "@/events/EventBus.js";

import Swal from "sweetalert2";

export default {
  name: "general-companies-form",
  components: {
    "companies-form-general": CompaniesFormGeneral,
    "companies-form-fiscal": CompaniesFormFiscal,
    "companies-list-providers": CompaniesListProviders,
    "companies-form-new-procedures": CompaniesFormNewProcedures,
    "companies-form-instructions": CompaniesFormInstructions,
    "companies-form-sales": CompaniesFormSales,
    // 'companies-form-documents': CompaniesFormDocuments,
    "companies-form-history": CompaniesFormHistory,
    "companies-list-contacts": ContactList,
  },
  data() {
    return {
      mainTitle: this.isNewRecord()
        ? "Cadastro de Empresas / Nova Empresa"
        : "Cadastro de Empresas / Editar Empresa",
      company: {
        customer: true,
        provider: false,
        enabled: true,
        stateRegistrationExempt: false,
        taxRegime: "LUCRO_PRESUMIDO",
      },
      oldModel: {},
      contacts: [],
      providers: [],
    };
  },
  methods: {
    isNewRecord() {
      if (this.$route.params.type === "create") {
        return true;
      }
      if (this.$route.params.id !== undefined && this.$route.params.id !== null)
        return false;
      return true;
    },
    onSubmit() {
      if (this.company.customer && this.company.federalID === null) {
        Swal.fire(
          "Empresas",
          "Você precisa digitar um CNPJ valido para a empresa.",
          "error"
        );
      } else if (
        this.company.completeName === null ||
        this.company.completeName === undefined ||
        this.company.completeName === ""
      ) {
        Swal.fire(
          "Empresas",
          "Você precisa digitar a Razão Social da empresa.",
          "error"
        );
      } else {
        this.federalIDExistsValidator((result) => {
          if (result) {
            this.$validator.validate().then((result) => {
              if (result) {
                if (this.isNewRecord()) {
                  this.addModel();
                } else {
                  this.updateModel();
                }
              }
            });
          }
        });
      }
    },
    addModel() {
      this.company.openingDate = this.$moment(this.company.openingDate).format(
        "Y-MM-DDTLTS"
      );
      this.company.account = `${config.env.API_DEFAULT.host}/accounts/${this.$store.state.credentials.data.account.id}`;

      if (this.company.country !== undefined && this.company.country !== null)
        this.company.country = this.company.country.key;

      Axios.post(`${config.env.API_DEFAULT.host}/companies`, this.company)
        .then((response) => {
          if (response) {
            Swal.fire(
              "Empresas",
              `A empresa ${response.data.completeName} foi adicionada com sucesso!`,
              "success"
            );
            this.$router.push(`/general/companies/update/${response.data.id}`);
            this.company = {};
          } else {
            Swal.fire(
              "Empresas",
              `Não foi possível adicionar a empresa ${this.company.completeName}.`,
              "error"
            );
          }
        })
        .catch((error) => {
          Swal.fire(
            "Empresas",
            `Houve algum erro ao tentar adicionar a empresa ${this.company.completeName}: ${error}`,
            "error"
          );
        });
    },
    updateModel() {
      if (this.company.openingDate !== null && this.company.openingDate) {
        this.company.openingDate = this.$moment(
          this.company.openingDate
        ).format("Y-MM-DDTLTS");
      }

      if (
        this.company.salesDateOfFirstBusiness !== null &&
        this.company.salesDateOfFirstBusiness
      ) {
        this.company.salesDateOfFirstBusiness = this.$moment(
          this.company.salesDateOfFirstBusiness
        ).format("Y-MM-DDTLTS");
      }

      if (this.company.country !== undefined && this.company.country !== null)
        this.company.country = this.company.country.key;

      this.company.account = `${config.env.API_DEFAULT.host}/accounts/${this.$store.state.credentials.data.account.id}`;

      this.oldModel.customBrokerModel = this.company.customBrokerModel;
      if (
        this.company.customBrokerModel !== null &&
        this.company.customBrokerModel !== undefined
      ) {
        this.company.customBrokerModel = `${config.env.API_DEFAULT.host}/companies/${this.company.customBrokerModel.id}`;
      }

      this.oldModel.team = this.company.team;
      if (
        this.company.team !== null &&
        this.company.team !== undefined &&
        this.company.team.id !== undefined
      ) {
        this.company.team = `${config.env.API_DEFAULT.host}/teams/${this.company.team.id}`;
      }

      this.oldModel.cargoStorage = this.company.cargoStorage;
      if (
        this.company.cargoStorage !== null &&
        this.company.cargoStorage !== undefined
      ) {
        this.company.cargoStorage = `${config.env.API_DEFAULT.host}/companies/${this.company.cargoStorage.id}`;
      }

      this.oldModel.commissioned = this.company.commissioned;
      if (
        this.company.commissioned !== null &&
        this.company.commissioned !== undefined
      ) {
        this.company.commissioned = `${config.env.API_DEFAULT.host}/users/${this.company.commissioned.id}`;
      }

      Axios.patch(
        `${config.env.API_DEFAULT.host}/companies/${this.company.id}`,
        this.company
      )
        .then((response) => {
          Swal.fire(
            "Empresas",
            `A empresa ${this.company.completeName} foi atualizada com sucesso!`,
            "success"
          );
          this.company.team = this.oldModel.team;
          this.company.customBrokerModel = this.oldModel.customBrokerModel;
          this.company.cargoStorage = this.oldModel.cargoStorage;
        })
        .catch((error) => {
          Swal.fire(
            "Empresas",
            `Não foi possível atualizar a empresa ${this.company.completeName} no banco de dados.`,
            "error"
          );
        });
    },
    federalIDExistsValidator(callback) {
      if (
        this.company.federalID !== null &&
        this.company.federalID !== undefined &&
        this.company.federalID !== ""
      ) {
        return Axios.get(
          `${config.env.API_DEFAULT.host}/companies/search/findByFederalID?federalID=${this.company.federalID}`
        )
          .then((response) => {
            if (
              response.data.id == this.company.id &&
              response.data.id == this.$route.params.id
            ) {
              callback(true);
            } else {
              Swal.fire(
                "Empresas",
                `O CNPJ ${this.company.federalID} já está sendo utilizado por outra empresa: `,
                "error"
              );
              this.company.federalID = "";
              callback(false);
            }
          })
          .catch((error) => {
            if (error.response.status == 404) {
              callback(true);
            } else {
              Swal.fire("Empresas", `Ops, houve algum erro: ${error}`, "error");
            }
          });
      }
      if (!this.company.customer) {
        callback(true);
      } else {
        callback(false);
      }
    },
    loadModel() {
      Axios.get(
        `${config.env.API_DEFAULT.host}/companies/${this.$route.params.id}`
      )
        .then((response) => {
          this.company = response.data;

          Axios.get(this.company._links.users.href)
            .then((response) => {
              this.contacts = response.data._embedded.users;
            })
            .catch((error) => {});

          if (response.data.customer === true) {
            Axios.get(this.company._links.customBrokerModel.href)
              .then((response) => {
                this.$set(this.company, "customBrokerModel", response.data);
              })
              .catch((error) => {});

            Axios.get(this.company._links.cargoStorage.href)
              .then((response) => {
                this.$set(this.company, "cargoStorage", response.data);
              })
              .catch((error) => {});

            Axios.get(this.company._links.team.href)
              .then((response) => {
                this.$set(this.company, "team", response.data);
              })
              .catch((error) => {});

            // Axios.get(this.company._links.comissions.href).then(response => {
            //     this.$set(this.company, "comissions", response.data._embedded.companyComissions);
            // });
          }
        })
        .catch((error) => {});
    },
    updateProviders(newProviders) {
      this.providers = newProviders;
    },
    loadProviders() {
      Axios.get(this.model._links.providers.href)
        .then((response) => {
          this.providers = response.data._embedded.companies;
        })
        .catch((error) => {
          swal(
            "Buscar Fornecedores",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
        });
    },
  },
  mounted() {
    EventBus.$on("companies.Form.onSubmit", () => {
      this.onSubmit();
    });

    EventBus.$on("companies.Form.loadModel", () => {
      this.loadModel();
    });

    if (!this.isNewRecord()) {
      this.loadModel();
    }
  },
  computed: {
    isCustomer() {
      return this.company.customer;
    },
  },
};
</script>
