import { render, staticRenderFns } from "./modalClosure.vue?vue&type=template&id=4f78de08&scoped=true"
import script from "./modalClosure.vue?vue&type=script&lang=js"
export * from "./modalClosure.vue?vue&type=script&lang=js"
import style0 from "./modalClosure.vue?vue&type=style&index=0&id=4f78de08&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f78de08",
  null
  
)

export default component.exports