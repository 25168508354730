<template>
  <div class="contact-container">
    <div class="contact-container-overflow">
      <form class="contact-form" @submit.prevent="addContact">
        <div class="sp-3">
          <h4>Informações Pessoais</h4>
        </div>

        <div>
          <v-input
            name="nome"
            label="Nome Completo"
            v-model="contactDetails.name"
          ></v-input>

          <div class="field-error">
            {{
              fieldErrors.find((err) => err.path == "name")
                ? fieldErrors.find((err) => err.path == "name").message
                : ""
            }}
          </div>
        </div>

        <select-wrapper
          v-model="contactDetails.gender"
          :options="[
            {
              label: 'Masculino',
              value: 'm',
            },
            {
              label: 'Feminino',
              value: 'f',
            },
          ]"
          label="Gênero"
        ></select-wrapper>

        <v-input
          type="date"
          name="nascimento"
          label="Data de Nascimento"
          v-model="contactDetails.dateOfBirth"
        ></v-input>

        <div class="sp-3 contact-form">
          <div>
            <v-input
              name="email1"
              label="Email Principal"
              v-model="contactDetails.email1"
              hint="Este email será utilizado para o usuário fazer login na plataforma de acordo com o perfil."
              @blur="canUseEmail"
            ></v-input>

            <div class="field-error">
              {{
                fieldErrors.find((err) => err.path == "email1")
                  ? fieldErrors.find((err) => err.path == "email1").message
                  : ""
              }}
            </div>
          </div>

          <v-input
            name="email2"
            label="Email Secundário"
            v-model="contactDetails.email2"
            hint="Este é um email secundário, para casos em que o contato tem mais do que um email."
          ></v-input>
        </div>

        <div class="sp-3 contact-form">
          <v-input
            name="fixo"
            label="Telefone Comercial"
            v-model="contactDetails.telephone1"
            :mask="['(##) ####-####', '(##) #####-####']"
          ></v-input>
          <v-input
            name="telephone1"
            label="Celular/Whatsapp"
            v-model="contactDetails.telephone2"
            :mask="['(##) ####-####', '(##) #####-####']"
          >
            <template #checkbox>
              <label>
                <input
                  type="checkbox"
                  name="allowsContactViaExternalApplications"
                  v-model="contactDetails.allowsContactViaExternalApplications"
                />
                <span
                  >Contato permite ser chamado por meio de apps que usa o número
                  dele?</span
                >
              </label>
            </template>
          </v-input>
        </div>

        <div class="sp-3" style="margin-top: 20px">
          <h4>Informações de Contato na Empresa</h4>
        </div>

        <div class="sp-3 contact-form" style="margin-top: -10px">
          <v-input
            name="jobRole"
            label="Cargo"
            v-model="contactDetails.jobRole"
          ></v-input>
          <select-wrapper
            v-model="contactDetails.sector"
            label="Setor / Departamento"
            :options="[
              { value: 'GERAL', label: 'Geral' },
              { value: 'ADMINISTRATIVO', label: 'Administrativo' },
              { value: 'EXPORTACAO', label: 'Exportação' },
              { value: 'IMPORTACAO', label: 'Importação' },
              { value: 'VENDAS', label: 'Vendas' },
              { value: 'COMPRAS', label: 'Compras' },
              { value: 'FATURAMENTO', label: 'Faturamento' },
              { value: 'FINANCEIRO', label: 'Financeiro' },
              { value: 'FISCAL', label: 'Fiscal' },
              { value: 'JURIDICO', label: 'Jurídico' },
              { value: 'LOGISTICA', label: 'Logística' },
              { value: 'OPERACIONAL', label: 'Operacional' },
              { value: 'PRODUCAO', label: 'Produção' },
            ]"
          ></select-wrapper>
          <v-checkbox
            name="receivesClousing"
            label="Recebe Encerramento"
            v-model="contactDetails.receivesClousing"
          ></v-checkbox>
          <v-checkbox
            name="receiveNps"
            label="Recebe NPS"
            v-model="contactDetails.receiveNps"
          ></v-checkbox>
        </div>

        <div class="sp-3 contact-form mt-3">
          <div>
            <select-wrapper
              v-model="contactDetails.category"
              label="Categoria de Contato"
              :options="[
                { label: 'Principal', value: 'MAIN_CONTACT' },
                { label: 'Próximo', value: 'CONTACT_NEXT' },
                { label: 'Esporádico', value: 'SPORADIC_CONTACT' },
                { label: 'Seguro', value: 'INSURANCE_CONTACT' },
                { label: 'Contabilidade', value: 'ACCOUNTING_CONTACT' },
                { label: 'Inativo', value: 'INACTIVE_CONTACT' },
              ]"
            ></select-wrapper>

            <div class="field-error">
              {{
                fieldErrors.find((err) => err.path == "category")
                  ? fieldErrors.find((err) => err.path == "category").message
                  : ""
              }}
            </div>
          </div>

          <v-input
            name="hiringDate"
            label="Data de Contratação"
            v-model="contactDetails.hiringDate"
            type="date"
            hint="Data para saber quando é que o contato faz aniversário de empresa"
          ></v-input>

          <v-checkbox
            name="archived"
            label="Contato Arquivado"
            v-model="contactDetails.archived"
          ></v-checkbox>
        </div>

        <div class="sp-3" style="margin-top: 20px">
          <h4>Informações de Usuário</h4>
        </div>
        <div class="sp-3 contact-form" style="margin-top: -10px">
          <v-checkbox
            name="enabled"
            label="Login Habilitado"
            v-model="contactDetails.enabled"
          ></v-checkbox>
          <v-checkbox
            v-if="contactDetails.enabled"
            name="enabled"
            label="Comunicar acesso por email"
            v-model="contactDetails.generatePassword"
          ></v-checkbox>

          <!--      <v-checkbox-->
          <!--        v-if="contactDetails.enabled"-->
          <!--        name="changePassword"-->
          <!--        label="Alterar senha"-->
          <!--        v-model="contactDetails.changePassword"-->
          <!--      ></v-checkbox>-->
        </div>
        <!--    <div class="sp-3 contact-form">-->
        <!--      <v-input-->
        <!--          name="senha"-->
        <!--          type="password"-->
        <!--          label="Senha"-->
        <!--          v-model="contactDetails.password"-->
        <!--          v-if=" contactDetails.changePassword === true && contactDetails.enabled === true "-->
        <!--      ></v-input>-->
        <!--      <v-input-->
        <!--          name="confPassword"-->
        <!--          type="password"-->
        <!--          label="Repeta a Senha"-->
        <!--          v-model="contactDetails.confPassword"-->
        <!--          v-if="contactDetails.changePassword === true && contactDetails.enabled === true"-->
        <!--      ></v-input>-->
        <!--    </div>-->

        <div class="sp-3" style="margin-top: 20px">
          <h4>Perfil de Usuário</h4>
          <h5 class="tx-normal tx-uppercase tx-15 mb-3 mt-2 tx-gray-400">
            Perfil de Usuários Externos
          </h5>
        </div>

        <div class="sp-3 contact-form" style="margin-top: -20px">
          <v-radio
            name="role"
            label="Cliente (Ext)"
            radio-value="CUSTOMER"
            v-model="contactDetails.role"
          ></v-radio>

          <v-radio
            name="role"
            label="Parceiro (Ext)"
            v-model="contactDetails.role"
            radio-value="PARTNER"
          ></v-radio>

          <v-radio
            name="role"
            label="Despachante (Ext)"
            v-model="contactDetails.role"
            radio-value="CUSTOM_BROKER"
          ></v-radio>

          <div class="sp-3">
            <div class="field-error">
              {{
                fieldErrors.find((err) => err.path == "role")
                  ? fieldErrors.find((err) => err.path == "role").message
                  : ""
              }}
            </div>
          </div>
        </div>

        <!-- <div class="sp-3" style="margin-top: -10px">
          <h5 class="tx-normal tx-uppercase tx-15 mb-3 mt-2">Perfil de Usuários Internos</h5>
        </div>

        <div class="sp-3 contact-form" style="margin-top: -20px">
          <v-radio
            name="operacional"
            label="Operacional (Int)"
            v-model="contactDetails.role"
            radio-value="OPERATIONAL"
          ></v-radio>

          <v-radio
            name="gerente"
            label="Gerente (Int)"
            v-model="contactDetails.role"
            radio-value="MANAGER"
          ></v-radio>

          <v-radio
            name="administrador"
            label="Adminstrador (Int)"
            v-model="contactDetails.role"
            radio-value="ADMINISTRATOR"
          ></v-radio>
        </div> -->

        <div class="sp-3" style="margin-top: 20px">
          <h4>Follow UP de Processos</h4>
        </div>

        <div class="sp-3 contact-form" style="margin-top: -10px">
          <v-checkbox
            name="followUP"
            label="Recebe Follow UP"
            v-model="contactDetails.followUP"
            hint="Follow UP Analítico! Quando o Follow UP Analítico estiver habilitado, uma vez na semana (sexta-feira) os contatos irão receber automaticamente um Follow UP de todos os processos em andamento."
          ></v-checkbox>
        </div>

        <div class="sp-3 contact-form" v-if="contactDetails.followUP === true">
          <v-checkbox
            name="followUPSynthetic"
            label="Follow UP Sintético"
            v-model="contactDetails.followUPSynthetic"
          ></v-checkbox>

          <select-wrapper
            v-if="contactDetails.followUPSynthetic"
            v-model="contactDetails.followUPSyntheticPeriod"
            :options="[
              {
                label: 'Semanal',
                value: 'SEMANAL',
              },
              {
                label: 'Quinzenal',
                value: 'QUINZENAL',
              },
              {
                label: 'Mensal',
                value: 'MENSAL',
              },
              {
                label: 'Bimestral',
                value: 'BIMESTRAL',
              },
              {
                label: 'Semestral',
                value: 'SEMESTRAL',
              },
            ]"
            label="Período"
          ></select-wrapper>

          <select-wrapper
            v-if="contactDetails.followUPSynthetic"
            v-model="contactDetails.followUPSyntheticWeekday"
            :options="[
              {
                label: 'Segunda',
                value: 'MONDAY',
              },
              {
                label: 'Terça',
                value: 'TUESDAY',
              },
              {
                label: 'Quarta',
                value: 'WEDNESDAY',
              },
              {
                label: 'Quinta',
                value: 'THURSDAY',
              },
              {
                label: 'Sexta',
                value: 'FRIDAY',
              },
              {
                label: 'Sábado',
                value: 'SATURDAY',
              },
              {
                label: 'Domingo',
                value: 'SUNDAY',
              },
            ]"
            label="Dia de Recebimento"
          ></select-wrapper>
        </div>

        <div class="sp-3 contact-form" v-if="contactDetails.followUP === true">
          <v-checkbox
            name="followUPAnalytical"
            label="Follow UP Analítico"
            v-model="contactDetails.followUPAnalytical"
          ></v-checkbox>

          <select-wrapper
            class="sp-2"
            v-if="contactDetails.followUPAnalytical"
            v-model="followUpEventsSelected"
            :options="followUpEvents"
            :multiple="true"
            label="Tipos"
          ></select-wrapper>
        </div>

        <div class="sp-3" style="margin-top: 20px">
          <h4>Redes Sociais</h4>
        </div>

        <div style="margin-top: -10px">
          <p class="mb-1">Contato permite marcações nas redes sociais?</p>
          <v-switch
            name="allowsTaggingonSocialNetworks"
            :labels="{
              checked: `Sim`,
              unchecked: `Não`,
            }"
            v-model="contactDetails.allowsTaggingonSocialNetworks"
          />
        </div>

        <v-input
          name="instagram"
          label="Instagram"
          v-model="contactDetails.instagramURL"
        >
          <template #icon>
            <SearchIcon />
          </template>
        </v-input>

        <v-input
          name="facebook"
          label="Facebook"
          v-model="contactDetails.facebookURL"
        >
          <template #icon>
            <SearchIcon />
          </template>
        </v-input>

        <v-input
          name="twitter"
          label="Twitter"
          v-model="contactDetails.twitterURL"
        >
          <template #icon>
            <SearchIcon />
          </template>
        </v-input>

        <v-input
          name="linkedin"
          label="LinkedIn"
          v-model="contactDetails.linkedInURL"
        >
          <template #icon>
            <SearchIcon />
          </template>
        </v-input>

        <div class="sp-3" style="margin-top: 20px; margin-bottom: -10px">
          <h4>Endereço</h4>
        </div>

        <div>
          <v-input
            name="cep"
            label="CEP"
            v-model="contactDetails.postalCode"
            :mask="['#####-###']"
            @blur="findCEP"
          ></v-input>

          <div class="field-error">
            {{
              fieldErrors.find((err) => err.path == "postalCode")
                ? fieldErrors.find((err) => err.path == "postalCode").message
                : ""
            }}
          </div>
        </div>

        <div>
          <v-input
            name="logradouro"
            label="Logradouro"
            v-model="contactDetails.address"
          ></v-input>

          <div class="field-error">
            {{
              fieldErrors.find((err) => err.path == "address")
                ? fieldErrors.find((err) => err.path == "address").message
                : ""
            }}
          </div>
        </div>

        <div>
          <v-input
            name="numero"
            label="Número"
            v-model="contactDetails.addressNumber"
            :mask="['#######']"
          ></v-input>

          <div class="field-error">
            {{
              fieldErrors.find((err) => err.path == "addressNumber")
                ? fieldErrors.find((err) => err.path == "addressNumber").message
                : ""
            }}
          </div>
        </div>

        <div>
          <v-input
            name="complemento"
            label="Complemento"
            v-model="contactDetails.complement"
          ></v-input>
        </div>

        <div>
          <v-input
            name="bairro"
            label="Bairro"
            v-model="contactDetails.district"
          ></v-input>

          <div class="field-error">
            {{
              fieldErrors.find((err) => err.path == "district")
                ? fieldErrors.find((err) => err.path == "district").message
                : ""
            }}
          </div>
        </div>

        <div>
          <v-input
            name="cidade"
            label="Cidade"
            v-model="contactDetails.city"
          ></v-input>

          <div class="field-error">
            {{
              fieldErrors.find((err) => err.path == "city")
                ? fieldErrors.find((err) => err.path == "city").message
                : ""
            }}
          </div>
        </div>

        <div>
          <v-input
            name="estado"
            label="Estado"
            v-model="contactDetails.federalUnity"
          ></v-input>

          <div class="field-error">
            {{
              fieldErrors.find((err) => err.path == "federalUnity")
                ? fieldErrors.find((err) => err.path == "federalUnity").message
                : ""
            }}
          </div>
        </div>

        <div class="sp-3 row justify-content-center mt-4">
          <button type="submit" class="report-button">Cadastrar Contato</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { SearchIcon } from '@/components/crm/Icons';

import VInput from '@/components/VInput.vue';
import VCheckbox from '@/components/VCheckbox.vue';
import VRadio from '@/components/VRadio.vue';
import SelectWrapper from '@/components/SelectWrapper.vue';
import VSwitch from '@/components/VSwitch.vue';
import * as yup from 'yup';

import config from '@/config.js';
import Axios from 'axios';
import Swal from 'sweetalert2';

export default {
  name: 'AddContact',
  data() {
    return {
      contactDetails: {
        dateOfBirth: null,
        hiringDate: null,
      },
      followUpEvents: [],
      followUpEventsSelected: [],
      fieldErrors: [],
    };
  },
  components: {
    SearchIcon,
    VInput,
    SelectWrapper,
    VCheckbox,
    VRadio,
    VSwitch,
  },
  mounted() {
    this.getFollowUpEvents();
    this.contactDetails.archived = false;
  },
  methods: {
    async canUseEmail() {
      this.fieldErrors = [];

      const schema = yup.object().shape({
        email1: yup
          .string()
          .email('O Email é inválido!')
          .required('O email é obrigatório!'),
      });

      try {
        await schema.validate(
          { email1: this.contactDetails.email1 },
          {
            abortEarly: false,
          },
        );

        await Axios.post(
          `${config.env.API_DEFAULT.host}/systemUsers/canUseEmail/`,
          { email1: this.contactDetails.email1 },
        );
      } catch (error) {
        if (error.name == 'ValidationError') {
          this.fieldErrors = error.inner;
          Swal.fire({
            title: 'Verificação de Email',
            html: '<p>Por favor, preencha o Email corretamente!</p>',
            type: 'error',
          });
        } else {
          if (error.response && error.response.data) {
            Swal.fire({
              title: 'Verificação de Email',
              html: `<p>${error.response.data.message}</p>`,
              type: 'error',
            });
          } else {
            Swal.fire({
              title: 'Verificação de Email',
              html: '<p>Não foi possível verificar se o Email já está em uso por outro contato!</p>',
              type: 'error',
            });
          }

          this.contactDetails.email1 = '';
        }
      }

      this.$store.commit('setLoading', {
        show: false,
      });
    },
    async findCEP() {
      if (this.contactDetails.postalCode) {
        const response = await this.$viaCep.buscarCep(
          this.contactDetails.postalCode,
        );
        if (response) {
          if (response.erro) {
            Swal.fire(
              {
                title: 'Consultar CEP',
                html: '<p>Não foi possível encontrar este endereço, verifique se o CEP é válido</p>',
                type: 'error',
              },
            );
          } else {
            this.$set(this.contactDetails, 'address', response.logradouro);
            this.$set(this.contactDetails, 'district', response.bairro);
            this.$set(this.contactDetails, 'city', response.localidade);
            this.$set(this.contactDetails, 'complement', response.complemento);
            this.$set(this.contactDetails, 'federalUnity', response.uf);
          }
        }
      }
    },
    async addContact() {
      this.fieldErrors = [];

      this.$store.commit('setLoading', {
        show: true,
        label: 'Adicionar Contato',
        description: 'Aguarde, o contato esta sendo cadastrado...',
      });

      const data = this.prepareData();

      const schema = yup.object().shape({
        name: yup
          .string()
          .required('O Nome é obrigatório!')
          .min(3, 'O Nome deve conter no mínimo 3 caracteres!'),
        email1: yup
          .string()
          .email('O Email é inválido!')
          .required('O Email é obrigatório!'),
        role: yup.string().required('O Perfil é obrigatório!'),
        category: yup.string().required('A Categoria é obrigatória!'),
      });

      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await Axios.post(
          `${config.env.API_DEFAULT.host}/systemUsers/create`,
          data,
        );

        if (response) {
          Swal.fire({
            title: 'Adicionar Contato',
            html: `<strong>${this.contactDetails.name}</strong> foi adicionado com sucesso.<br /><br /><small>A partir de agora, você pode continuar preenchendo todos os dados restantes do contato que eles serão automaticamente salvos.</small>`,
            type: 'success',
          });

          this.$router.push({
            name: 'CrmContact',
            params: { id: response.data.data.id },
          });
        }
      } catch (error) {
        if (error.name == 'ValidationError') {
          this.fieldErrors = error.inner;

          Swal.fire({
            title: 'Adicionar Contato',
            html: '<p>Por favor, preencha todos os campos obrigatórios!</p>',
            type: 'error',
          });
        } else {
          if (error.response && error.response.data) {
            Swal.fire({
              title: 'Adicionar Contato',
              html: `<p>${error.response.data.message}</p>`,
              type: 'error',
            });
          } else {
            Swal.fire({
              title: 'Adicionar Contato',
              html: '<p>Não foi possível Adicionar o Contato!</p>',
              type: 'error',
            });
          }

          console.error(error);
        }
      }

      this.$store.commit('setLoading', {
        show: false,
      });
    },
    prepareData() {
      return {
        name: this.contactDetails.name,
        gender: this.contactDetails.gender,
        dateOfBirth: this.contactDetails.dateOfBirth,
        email1: this.contactDetails.email1,
        email2: this.contactDetails.email2,
        telephone1: this.contactDetails.telephone1,
        telephone2: this.contactDetails.telephone2,
        allowsContactViaExternalApplications:
          this.contactDetails.allowsContactViaExternalApplications,
        jobRole: this.contactDetails.jobRole,
        sector: this.contactDetails.sector,
        category: this.contactDetails.category,
        hiringDate: this.contactDetails.hiringDate,
        archived: this.contactDetails.archived,

        enabled: this.contactDetails.enabled,
        role: this.contactDetails.role,

        followUP: this.contactDetails.followUP,
        followUPAnalytical: this.contactDetails.followUPAnalytical,
        followUPSynthetic: this.contactDetails.followUPSynthetic,
        followUPSyntheticPeriod: this.contactDetails.followUPSyntheticPeriod,
        followUPSyntheticWeekday: this.contactDetails.followUPSyntheticWeekday,
        followUPAnalyticalTypes: this.followUpEventsSelected,

        employee: null,
        postalCode: this.contactDetails.postalCode,
        address: this.contactDetails.address,
        addressNumber: this.contactDetails.addressNumber,
        complement: this.contactDetails.complement,
        district: this.contactDetails.district,
        city: this.contactDetails.city,
        federalUnity: this.contactDetails.federalUnity,
        receiveNps: this.contactDetails.receiveNps,
        receivesClousing: this.contactDetails.receivesClousing,

        password: null,
        team: null,
        generatePassword: this.contactDetails.generatePassword,

        instagramURL: this.contactDetails.instagramURL,
        facebookURL: this.contactDetails.facebookURL,
        twitterURL: this.contactDetails.twitterURL,
        linkedInURL: this.contactDetails.linkedInURL,
        allowsTaggingonSocialNetworks:
          this.contactDetails.allowsTaggingonSocialNetworks,
      };
    },
    async getFollowUpEvents() {
      await Axios.get(
        `${config.env.API_DEFAULT.host}/contactData/getFollowUpEvents/`,
      )
        .then((response) => {
          if (response.data.data.length > 0) {
            this.followUpEvents = response.data.data.map((event) => ({ label: event.description, value: event.id }));
          }
        })
        .catch((e) => {
          Swal.fire({
            title: 'Eventos de Processos',
            text: 'Não foi possível carregar o eventos disponível para vincular a este contato',
            type: 'error',
          });
        });
    },
  },
};
</script>

<style scoped>
.report-button {
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #104375;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
  color: white;
  padding: 10px 35px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out 0s;
  font-size: 1rem;
}

.report-button:hover {
  background-color: #1768a7;
}
</style>
