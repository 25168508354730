<template>
  <div class="dropdown-menu-i">
    <div
      @click.prevent="toggleDropDown"
      class="dropdown-btn-i"
      :class="{
        sm: buttonSize == 'sm',
        md: buttonSize == 'md',
        lg: buttonSize == 'lg'
      }"
      type="button"
    >
      <span>{{ label }}</span>
      <chevron-down-icon size="1rem" />
    </div>

    <transition name="notifications">
      <div v-if="open" class="dropdown-content" :class="[contentPosition]" :style="{ width: `${contentWidth}px  `}">
        <slot name="content" :close="closeDropDown" />
      </div>
    </transition>
  </div>
</template>

<script>
import ChevronDownIcon from '@/components/icons/ChevronDownIcon.vue';

export default {
  components: { ChevronDownIcon },
  name: 'DropDown',
  props: {
    label: {
      type: String,
      required: true,
    },
    contentWidth: {
      type: Number,
      default: 100,
    },
    contentPosition: {
      type: String,
      default: 'right',
      validator(value) {
        return ['left', 'right'].includes(value);
      },
    },
    buttonSize: {
      type: String,
      default: 'lg',
      validator: (value) => ['sm', 'md', 'lg'].includes(value),
    }
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggleDropDown() {
      this.open = !this.open;
    },
    closeDropDown() {
      this.open = false;
    },
  },
  mounted() {
    document.addEventListener('click', (event) => {
      if (!event.target.closest('.dropdown-menu-i')) {
        this.closeDropDown();
      }
    });
  },
};
</script>

<style scoped>
.dropdown-menu-i {
  position: relative;
}
.dropdown-btn-i {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.5rem;
  font-family: Poppins, sans-serif;
  border-radius: 100px;
  background-color: #F4F7F9;
  color: var(--primary-bg-header);
  font-weight: bold;
  border: 0;
  cursor: pointer;
  transition: all .2s ease-in-out 0s;
  text-transform: uppercase;
  font-size: 0.85rem;
}

.dropdown-btn-i.sm {
  padding: 0.3rem 0.4rem;
  font-size: 0.7rem;
}

.dropdown-btn-i.md {
  padding: 0.5rem 0.7rem;
  font-size: 0.8rem;
}

.dropdown-btn-i:hover {
  background-color: var(--primary-bg-header);
  color: #F4F7F9;
}
.dropdown-content {
  position: absolute;
  top: calc(100% + 0.5rem);
  z-index: 99;

  background-color: #ffffff;
  box-shadow: 0 0 20px rgb(0 0 0 / .2);
  border-radius: 10px;
}
.dropdown-content.right {
  right: 0;
}
.dropdown-content.left {
  left: 0;
}
</style>
