<template>
  <div class="contact-container">
    <div class="contact-container-overflow">
      <form @submit.prevent="addCustomer" class="contact-form">
        <div class="sp-3">
          <h4>Informações da Empresa</h4>
        </div>

        <!-- <div class="sp-3 mt-0 mb-0">
          <p class="mb-2">Tipo de Relacionamento</p>
          <div class="flex fw-w ai-c fgap1">
            <v-checkbox
                v-for="relation in relationTypes"
                :key="relation.label"
                name="relation"
                :label="relation.label"
                v-model="customerDetails[relation.value]"
                small
                :color="'#1B84E7'"
            ></v-checkbox>
          </div>
        </div> -->

        <div>
          <p class="mb-1">Situação do Cliente</p>
          <v-switch
            name="enabled"
            :labels="{
              checked: 'Habilitado',
              unchecked: 'Desabilitado',
            }"
            v-model="customerDetails.enabled"
          />
        </div>

        <div class="sp-2">
          <p class="mb-0">Regime Fiscal</p>
          <div class="flex fw-w ai-c fgap1">
            <v-radio
              name="taxRegime"
              label="Lucro Presumido"
              radio-value="LUCRO_PRESUMIDO"
              v-model="customerDetails.taxRegime"
              small
              :color="'#395B64'"
            ></v-radio>
            <v-radio
              name="taxRegime"
              label="Lucro Real"
              radio-value="LUCRO_REAL"
              :color="'#395B64'"
              v-model="customerDetails.taxRegime"
              small
            ></v-radio>
            <v-radio
              name="taxRegime"
              label="Simples"
              radio-value="SIMPLES"
              :color="'#395B64'"
              v-model="customerDetails.taxRegime"
              small
            ></v-radio>
          </div>
        </div>
        <div>
          <v-input
            name="federalID"
            type="tel"
            :mask="['##.###.###/####-##']"
            label="CNPJ"
            v-model="customerDetails.federalID"
            class="flex1"
            style="min-width: 150px"
          ></v-input>
          <div class="field-error">
            {{
              fieldErrors.find((err) => err.path == "federalID")
                ? fieldErrors.find((err) => err.path == "federalID").message
                : ""
            }}
          </div>
        </div>

        <div>
          <button
            class="btn btn-oblong bg-black-8 tx-white tx-bold btn-block mg-b-10 wd-200 tx-uppercase"
            @click.prevent="newFetchDataFromCNPJ"
          >
            Consultar CNPJ
          </button>
        </div>
        <div></div>

        <div>
          <v-input
            name="completeName"
            type="text"
            label="Razão Social"
            v-model="customerDetails.completeName"
          ></v-input>

          <div class="field-error">
            {{
              fieldErrors.find((err) => err.path == "completeName")
                ? fieldErrors.find((err) => err.path == "completeName").message
                : ""
            }}
          </div>
        </div>

        <div>
          <v-input
            name="name"
            type="text"
            label="Nome Fantasia"
            v-model="customerDetails.name"
          ></v-input>
          <div class="field-error">
            {{
              fieldErrors.find((err) => err.path == "name")
                ? fieldErrors.find((err) => err.path == "name").message
                : ""
            }}
          </div>
        </div>

        <v-input
          name="openingDate"
          type="date"
          label="Data de Fundação da Empresa"
          v-model="customerDetails.openingDate"
        ></v-input>

        <div>
          <p class="mb-0">I.E. Isenta?</p>
          <v-switch
            name="stateRegistrationExempt"
            :labels="{
              checked: `${
                customerDetails.name ? customerDetails.name : 'Empresa'
              } não tem IE`,
              unchecked: `${
                customerDetails.name ? customerDetails.name : 'Empresa'
              } tem IE`,
            }"
            v-model="customerDetails.stateRegistrationExempt"
          />
        </div>

        <div>
          <v-input
            v-if="!customerDetails.stateRegistrationExempt"
            name="stateRegistration"
            type="text"
            label="Inscrição Estadual"
            v-model="customerDetails.stateRegistration"
          ></v-input>
        </div>

        <v-input
          name="stateRegistration"
          type="text"
          label="Inscrição Municipal"
          v-model="customerDetails.municipalRegistration"
        ></v-input>

        <v-input
          name="telephone1"
          type="tel"
          label="Telefone Principal"
          v-model="customerDetails.telephone1"
          :mask="['(##) ####-####', '(##) #####-####']"
        ></v-input>

        <v-input
          name="telephone2"
          type="tel"
          label="Telefone Secundário"
          v-model="customerDetails.telephone2"
          :mask="['(##) ####-####', '(##) #####-####']"
        ></v-input>

        <v-input
          name="email"
          type="text"
          label="E-mail - Quando tiver mais do que um, separar por vírgula"
          v-model="customerDetails.email"
        ></v-input>

        <v-input
          name="website"
          type="text"
          label="Website"
          v-model="customerDetails.website"
        ></v-input>

        <select-wrapper
          v-model="customerDetails.origin"
          :options="[
            { label: 'Prospecção', value: 'PROSPECTION' },
            { label: 'Indicação', value: 'RECOMMENDATION' },
          ]"
          label="Origem"
        ></select-wrapper>

        <div class="sp-3 mt-3 bd-t pd-t-10">
          <h4>Endereço</h4>
        </div>

        <v-input
          name="postalCode"
          type="text"
          label="CEP"
          :mask="['#####-###']"
          v-model="customerDetails.postalCode"
          @blur="findCEP"
        ></v-input>

        <v-input
          name="address"
          type="text"
          label="Logradouro"
          v-model="customerDetails.address"
        ></v-input>

        <v-input
          name="addressNumber"
          type="text"
          label="Número"
          v-model="customerDetails.addressNumber"
        ></v-input>

        <v-input
          name="complement"
          type="text"
          label="Complemento"
          v-model="customerDetails.complement"
        ></v-input>

        <v-input
          name="district"
          type="text"
          label="Bairro"
          v-model="customerDetails.district"
        ></v-input>

        <v-input
          name="city"
          type="text"
          label="Cidade"
          v-model="customerDetails.city"
        ></v-input>
        <div>
          <v-input
            name="federalUnity"
            type="text"
            label="Estado"
            v-model="customerDetails.federalUnity"
          ></v-input>

          <div class="field-error">
            {{
              fieldErrors.find((err) => err.path == "federalUnity")
                ? fieldErrors.find((err) => err.path == "federalUnity").message
                : ""
            }}
          </div>
        </div>

        <select-wrapper
          v-model="customerDetails.country"
          :options="paises"
          label="País"
        ></select-wrapper>

        <div class="sp-3">
          <v-textarea
            name="observation"
            label="Observações"
            v-model="customerDetails.observation"
          ></v-textarea>
        </div>

        <div class="sp-3 row justify-content-center mt-4">
          <button type="submit" class="report-button">Cadastrar Cliente</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import VRadio from "@/components/VRadio.vue";
import VInput from "@/components/VInput.vue";
import VTextarea from "@/components/VTextarea.vue";
import VSwitch from "@/components/VSwitch.vue";
import SelectWrapper from "@/components/SelectWrapper.vue";
import * as yup from "yup";

import * as importDeclarationLists from "@/lists/import-declaration-lists.js";

import config from "@/config.js";
import Axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "AddCustomer",
  data() {
    return {
      codigosPaises: [...importDeclarationLists.codigosPaises],
      relationTypes: [
        {
          label: "Cliente",
          value: "customer",
        },
        {
          label: "Fornecedor da Empresa",
          value: "provider",
        },
        {
          label: "Exportador / Fabricante",
          value: "exporter",
        },
        {
          label: "Despachante Aduaneiro",
          value: "customBroker",
        },
        {
          label: "Recinto",
          value: "customsEnclosure",
        },
        {
          label: "Transportador",
          value: "conveyor",
        },
        {
          label: "Forwarder",
          value: "forwarder",
        },
        {
          label: "Armador",
          value: "shipowner",
        },
        {
          label: "Comissionado",
          value: "isCommissioned",
        },
      ],
      relations: [],
      teams: [],
      customerDetails: {
        enabled: true,
        openingDate: null,
        created: new Date(),
        stateRegistrationExempt: false,
        taxRegime: "",
      },
      fieldErrors: [],
    };
  },
  computed: {
    paises() {
      return this.codigosPaises.map((pais) => ({
        label: pais.value,
        value: pais.key,
      }));
    },
  },
  components: {
    VInput,
    VSwitch,
    VRadio,
    SelectWrapper,
    VTextarea,
  },
  mounted() {
    this.getTeams();

    this.customerDetails.customer = true;
    this.customerDetails.comissionPercentual = 0.0;
    this.customerDetails.sdaValue = 0.0;
    this.customerDetails.enabled = true;
    this.customerDetails.hasCompliance = false;
    this.customerDetails.isMinimalComissionPercentual = false;
    this.customerDetails.stateRegistrationExempt = false;
    this.customerDetails.taxRegime = "LUCRO_PRESUMIDO";
  },
  methods: {
    async newFetchDataFromCNPJ() {
      try {
        const response = await Axios.get(
          `https://publica.cnpj.ws/cnpj/${this.customerDetails.federalID.replace(
            /[^\w\s]/gi,
            ""
          )}`
        );

        if (response.status === 200) {
          const { data } = response;
          const { estabelecimento } = data;

          this.customerDetails.completeName = data.razao_social;
          this.customerDetails.name = estabelecimento.nome_fantasia;
          this.customerDetails.openingDate =
            estabelecimento.data_inicio_atividade;

          if (
            estabelecimento.inscricoes_estaduais &&
            estabelecimento.inscricoes_estaduais.length > 0
          ) {
            this.customerDetails.stateRegistration =
              estabelecimento.inscricoes_estaduais[0].inscricao_estadual;
          }

          this.customerDetails.telephone1 =
            estabelecimento.ddd1 + estabelecimento.telefone1;

          this.customerDetails.address = estabelecimento.logradouro;
          this.customerDetails.postalCode = estabelecimento.cep;
          this.customerDetails.addressNumber = estabelecimento.numero;
          this.customerDetails.federalUnity = estabelecimento.estado.nome;
          this.customerDetails.city = estabelecimento.cidade.nome;
          this.customerDetails.country = estabelecimento.pais.comex_id;
          this.customerDetails.district = estabelecimento.bairro;

          if (estabelecimento.complemento) {
            this.customerDetails.complement = estabelecimento.complemento;
          }

          if (data.simples) {
            this.customerDetails.taxRegime = "SIMPLES";
          }

          const currencyFormat = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          });

          let observation = `Capital social: ${currencyFormat.format(
            data.capital_social
          )} \n`;
          observation += `Natureza Jurídica: ${data.natureza_juridica.descricao} \n\n`;

          if (data.socios && data.socios.length > 0) {
            observation += "Sócios: \n";

            for (let i = 0; i < data.socios.length; i++) {
              const socio = data.socios[i];

              observation += `${socio.nome}; \n`;
            }
          }

          this.customerDetails.observation = observation;
        }
      } catch (e) {
        Swal.fire({
          title: "Consultar CNPJ",
          html: "<p>Não foi possível encontrar este CNPJ, verifique se é válido</p>",
          type: "error",
        });
      }
    },
    async findCEP() {
      const response = await this.$viaCep.buscarCep(
        this.customerDetails.postalCode
      );
      if (response) {
        if (response.erro) {
          Swal.fire({
            title: "Consultar CEP",
            html: "<p>Não foi possível encontrar este endereço, verifique se o CEP é válido</p>",
            type: "error",
          });
        } else {
          this.$set(this.customerDetails, "address", response.logradouro);
          this.$set(this.customerDetails, "district", response.bairro);
          this.$set(this.customerDetails, "city", response.localidade);
          this.$set(this.customerDetails, "complement", response.complemento);
          this.$set(this.customerDetails, "federalUnity", response.uf);
        }
      }
    },
    async addCustomer() {
      this.fieldErrors = [];

      if (
        this.customerDetails.completeName &&
        this.customerDetails.name == null
      ) {
        this.customerDetails.name = this.customerDetails.completeName;
      }

      if (
        this.customerDetails.name &&
        this.customerDetails.completeName == null
      ) {
        this.customerDetails.completeName = this.customerDetails.name;
      }

      if (
        this.customerDetails.openingDate !== null &&
        this.customerDetails.openingDate !== "NULL" &&
        this.customerDetails.openingDate !== undefined
      ) {
        const dateToSend = new Date(this.customerDetails.openingDate);
        this.customerDetails.openingDate = dateToSend.toISOString();
      } else {
        this.customerDetails.openingDate = null;
      }

      this.customerDetails.customer = true;

      const schema = yup.object().shape({
        name: yup
          .string()
          .required("O Nome Fantasia é obrigatório!")
          .min(3, "O Nome Fantasia deve conter no mínimo 3 caracteres!"),
        completeName: yup
          .string()
          .required("O Razão Social é obrigatório!")
          .min(5, "O Razão Social deve conter no mínimo 5 caracteres!"),
        federalID: yup.string().required("O CNPJ é obrigatório!"),
      });

      try {
        this.$store.commit("setLoading", {
          show: true,
          label: "Carregando...",
          description: "Adicionando Empresa",
        });

        await schema.validate(this.customerDetails, {
          abortEarly: false,
        });

        const existsResponse = await Axios.get(
          `${config.env.API_DEFAULT.host}/companies/search/findCustomersByFederalID?federalID=${this.customerDetails.federalID}`
        );

        if (existsResponse.data._embedded.companies.length > 0) {
          let htmlError = "<div>";
          htmlError += '<h4 class="text-danger mb-4">CNPJ já cadastrado!</h4>';

          htmlError += '<ul class="list-group">';
          for (
            let i = 0;
            i < existsResponse.data._embedded.companies.length;
            i++
          ) {
            const c = existsResponse.data._embedded.companies[i];
            htmlError += `
              <li class="list-group-item flex ai-c jc-sb fgap2">
                <p class="mg-b-0">${c.name} (${
              c.enabled ? "Habilitado" : "Desabilitado"
            })</p>
                <a href="/crm/clientes/${
                  c.id
                }" target="_blank" rel="nofollow noopener noreferrer" class="crm-button small">Ver Pefil</a>
              </li>
            `;
          }
          htmlError += "</ul>";

          htmlError += "</div>";

          Swal.fire({
            title: "Adicionar Empresa",
            html: htmlError,
            type: "error",
          });

          return;
        }

        const response = await Axios.post(
          `${config.env.API_DEFAULT.host}/companies/`,
          this.customerDetails
        );
        if (response) {
          Swal.fire({
            title: "Adicionar Empresa",
            html: `<strong>${this.customerDetails.name}</strong> foi adicionado com sucesso.<br /><br /><small>A partir de agora, você pode continuar preenchendo todos os dados restantes do cliente que eles serão automaticamente salvos.</small>`,
            type: "success",
          });
        }

        this.$router.push({
          name: "CrmCustomer",
          params: { id: response.data.id },
        });
      } catch (error) {
        if (error.name == "ValidationError") {
          this.fieldErrors = error.inner;
          Swal.fire({
            title: "Adicionar Empresa",
            html: "<p>Por favor, preencha todos os campos obrigatórios!</p>",
            type: "error",
          });
        } else {
          Swal.fire({
            title: "Adicionar Empresa",
            html: "<p>Não foi possível adicionar a empresa</p>",
            type: "error",
          });
        }
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    getTeams() {
      Axios.get(`${config.env.API_DEFAULT.host}/teams`)
        .then((response) => {
          const teams = response.data._embedded.teams.map((team) => ({
            label: `${team.name} - ${team.email}`,
            value: team.id,
          }));

          this.teams = teams;
        })
        .catch(async (error) => {
          Swal.fire({
            title: "Listar Times",
            html: `<p>Ops, houve algum erro:</p> <div>${error}</div>`,
            type: "error",
          });
        });
    },
  },
};
</script>

<style scoped>
.report-button {
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #104375;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
  color: white;
  padding: 10px 35px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out 0s;
  font-size: 1rem;
}

.report-button:hover {
  background-color: #1768a7;
}
</style>
