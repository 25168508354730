<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Operacional</a></li>
          <li class="breadcrumb-item"><a href="#">Importação</a></li>
          <li class="breadcrumb-item"><a href="#">Relatórios</a></li>
          <li class="breadcrumb-item active" aria-current="page">Seguro</li>
        </ol>
        <h6 class="slim-pagetitle">Relatório geral de Seguros</h6>
      </div>
      <div class="alert alert-info">
        <i class="fa fa-info-circle"></i> Caso algums dos filtros de período não
        for informado, o relatório leverá em consideração os dados do mês
        anterior.
      </div>
      <div class="bd bd-1 bd-b-0">
        <div
          class="bd-t bd-5 bd-gray-800 pd-x-10 bg-white d-flex justify-content-between align-items-center pd-b-5 pd-t-5"
        >
          <ul class="nav">
            <li class="mg-t-5">
              <div class="dropdown dropdown-demo">
                <a href="#" class="dd-link" data-toggle="dropdown">
                  <div v-if="filter.startDate !== '' && filter.endDate !== ''">
                    <span class="mg-l-0 pd-l-0">
                      <i class="fa fa-calendar"></i> Filtro de Período:
                      <span
                        class="pd-x-5 badge mg-b-0 mg-r-2-force tx-thin-force"
                      >
                        {{ filter.startDate | moment("DD/MM/YYYY") }} até
                        {{ filter.endDate | moment("DD/MM/YYYY") }}
                      </span>
                    </span>
                  </div>
                  <div v-else>
                    <span> <i class="fa fa-calendar mg-r-5"></i> Período</span>
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>
                <div class="dropdown-menu pd-15 wd-300">
                  <h6 class="tx-gray-800 tx-uppercase tx-bold mg-b-0 mg-t-10">
                    Data do Período
                  </h6>
                  <p class="tx-gray-600 mg-b-10">
                    Selecione uma data inicial e final
                  </p>
                  <div class="form-layout form-layout-2">
                    <div class="row no-gutters">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-control-label">
                            Data Inicial <span class="tx-danger">*</span></label
                          >
                          <date-picker lang="pt-br"
                                       v-model="filter.startDate"
                                       name="toPickedDate"
                                       format="DD/MM/YYYY"
                                       placeholder="Data Inicial"
                          ></date-picker>
                        </div>
                      </div>
                      <div class="col-md-6 mg-t--1 mg-md-t-0">
                        <div class="form-group mg-md-l--1">
                          <label class="form-control-label">
                            Data Final <span class="tx-danger">*</span></label
                          >
                          <date-picker lang="pt-br"
                                       v-model="filter.endDate"
                                       name="toPickedDate"
                                       format="DD/MM/YYYY"
                                       placeholder="Data Final"
                          ></date-picker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="mg-t-5 mg-l-10">
              <div class="dropdown dropdown-demo">
                <a href="#" class="dd-link" data-toggle="dropdown">
                  <div>
                    <span>
                      <i class="fa fa-building mg-r-5"></i>
                      Processos da
                      <span
                        v-if="filter.accountId"
                      >
                        {{
                          filter.accountId === 1 ? 'Prime' : 'Sowport'
                        }}
                      </span>
                    </span>
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>

                <div class="dropdown-menu pd-15 wd-300">
                  <div class="form-layout form-layout-2">
                    <div class="flex fd-c">
                      <label class="flex ai-c" style="gap: 0.3rem; padding: 0.5rem 0;">
                        <input type="radio" name="accountId" id="prime" :value="1" v-model="filter.accountId"/>
                        <span>Prime</span>
                      </label>
                      <label class="flex ai-c" style="gap: 0.3rem; padding: 0.5rem 0;">
                        <input type="radio" name="accountId" id="sowport" :value="3" v-model="filter.accountId"/>
                        <span>Sowport</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul class="nav nav-white-800 flex-column flex-md-row tx-white tx-15">
            <li class="mg-l-10">
              <button
                class="btn btn-primary"
                v-if="insuranceData.length > 0"
                type="button"
                @click.prevent="sendReport"
              >
                <i class="fa fa-paper-plane"></i> Enviar Relatório
              </button>
            </li>
            <li class="mg-l-10">
              <button
                class="btn btn-dark"
                type="button"
                @click.prevent="getInsuranceData"
              >
                <i class="fa fa-list"></i> Gerar Relatório
              </button>
            </li>
          </ul>
        </div>
        <div class="section-wrapper mg-t-0 pd-0 bd-1 bd-l-0 bd-r-0 bd-b-1">
          <div class="pd-10 mg-10">
            <label class="section-title mg-t-10 tx-16">
              Resultado da Consulta</label
            >
            <p class="mg-b-0">
              Foram encontrados {{ insuranceData.length }} registros de acordo
              com o filtro acima.
            </p>
            <div class="table-responsive mt-2">
              <table
                class="table table-striped table-bordered mg-b-0 tx-gray-800 tx-12"
              >
                <thead>
                <tr>
                  <th>Referência Prime</th>
                  <th>Cliente</th>
                  <th>Origem/País</th>
                  <th>Região da Origem</th>
                  <th>Destino no Brasil/UF</th>
                  <th>Cidade</th>
                  <th>Data de embarque/coleta</th>
                  <th>Mercadoria</th>
                  <th>Embalagem</th>
                  <th>Nome do navio/avião</th>
                  <th>Moeda</th>
                  <th>FOB</th>
                  <th>Frete</th>
                  <th>Despesas</th>
                  <th>Lucros</th>
                  <th>Impostos</th>
                  <th>Prêmio</th>
                </tr>
                </thead>
                <tbody>
                <template v-if="insuranceData.length > 0">
                  <tr v-for="process in insuranceData" :key="process.id">
                    <td>{{ process.observations }}</td>
                    <td>{{ process.customerName }}</td>
                    <td>{{ process.originCountry }}</td>
                    <td :class="process.originRegion === null || process.originRegion === '' ? 'bg-warning' : ''">
                      {{ process.originRegion !== null && process.originRegion !== '' ? process.originRegion : 'NÃO INFORMADO!' }}
                    </td>
                    <td>{{ process.destinyFederalUnit }}</td>
                    <td>{{ process.city }}</td>
                    <td>{{ process.boardingDate }}</td>
                    <td :class="process.product === null || process.product === '' ? 'bg-warning' : ''">
                      {{ process.product !== null && process.product !== '' ? process.product : 'NÃO INFORMADO!' }}
                    </td>
                    <td>{{ process.packaging }}</td>
                    <td>{{ process.vehicle }}</td>
                    <td>Reais (BRL)</td>
                    <td :class="process.fobValue === 0 ? 'bg-warning' : ''">
                      <span v-if="process.fobValue === 0">VALOR ZERADO</span>
                      <span v-else>
                        {{ process.fobValue | valueToBRL }}
                      </span>
                    </td>
                    <td :class="process.freightValue === 0 ? 'bg-warning' : ''">
                      <span v-if="process.freightValue === 0">VALOR ZERADO</span>
                      <span v-else>
                        {{ process.freightValue | valueToBRL }}
                      </span>
                    </td>
                    <td :class="process.expenses === 0 ? 'bg-warning' : ''">
                      <span v-if="process.expenses === 0">VALOR ZERADO</span>
                      <span v-else>
                        {{ process.expenses | valueToBRL }}
                      </span>
                    </td>
                    <td :class="process.profits === 0 ? 'bg-warning' : ''">
                      <span v-if="process.profits === 0">VALOR ZERADO</span>
                      <span v-else>
                        {{ process.profits | valueToBRL }}
                      </span>
                    </td>
                    <td :class="process.taxesValue === 0 ? 'bg-warning' : ''">
                      <span v-if="process.taxesValue === 0">VALOR ZERADO</span>
                      <span v-else>
                        {{ process.taxesValue | valueToBRL }}
                      </span>
                    </td>
                    <td :class="process.award === 0 ? 'bg-warning' : ''">
                      <span v-if="process.award === 0">VALOR ZERADO</span>
                      <span v-else>
                        {{ process.award | valueToBRL }}
                      </span>
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td class="text-center" colspan="17">
                    Nenhum processo encontrado.
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import config from "@/config.js";
import Swal from "sweetalert2";

export default {
  name: "OperationalInsurance",
  components: {
    DatePicker,
  },
  data() {
    return {
      filter: {
        startDate: moment().subtract(1, "month").startOf("month").toDate(),
        endDate: moment().subtract(1, "month").endOf("month").toDate(),
        accountId: 1
      },
      insuranceData: [],
    };
  },
  filters: {
    valueToBRL(value) {
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
  },
  methods: {
    async getInsuranceData() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Relatório de Seguro",
        description: "Aguarde enquanto o relatório está sendo gerado...",
      });

      try {
        const filters = {};

        if (
          this.filter.startDate !== null &&
          this.filter.startDate !== "" &&
          this.filter.startDate !== undefined &&
          this.filter.endDate !== null &&
          this.filter.endDate !== "" &&
          this.filter.endDate !== undefined
        ) {
          filters.startDate = moment(this.filter.startDate).format(
            "YYYY-MM-DDT00:00:00"
          );
          filters.endDate = moment(this.filter.endDate).format(
            "YYYY-MM-DDT23:59:00"
          );

          filters.accountId = this.filter.accountId;
        }

        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/insurance-registration-automation/get-dis`,
          {
            params: filters,
          }
        );

        this.insuranceData = response.data.data;

        this.$store.commit("setLoading", {
          show: false,
        });
      } catch (e) {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    async sendReport() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Enviando o Relatório de Seguro",
        description: "Aguarde enquanto o relatório está sendo enviado...",
      });

      try {
        const filters = {};

        if (
          this.filter.startDate !== null &&
          this.filter.startDate !== "" &&
          this.filter.startDate !== undefined &&
          this.filter.endDate !== null &&
          this.filter.endDate !== "" &&
          this.filter.endDate !== undefined
        ) {
          filters.startDate = moment(this.filter.startDate).format(
            "YYYY-MM-DDT00:00:00"
          );
          filters.endDate = moment(this.filter.endDate).format(
            "YYYY-MM-DDT23:59:00"
          );
          filters.accountId = this.filter.accountId;
        }

        const response = await Axios.post(
          `${config.env.API_DEFAULT.host}/insurance-registration-automation/send-report`,
          null,
          {
            params: filters,
          }
        );

        this.insuranceData = response.data.data;

        this.$store.commit("setLoading", {
          show: false,
        });

        Swal.fire(
          "Relatório de seguro",
          "Relatório enviado com sucesso!",
          "success"
        );
      } catch (e) {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
  },
  async mounted() {
    await this.getInsuranceData();
  },
};
</script>
