import * as yup from 'yup';

export const invoiceSchema = yup.object().shape({
  number: yup.string('Campo inválido').required('Campo obrigatório'),
  invoiceDate: yup.date('Campo inválido').typeError('Campo inválido').required('Campo obrigatório'),
  paymentTerms: yup.string('Campo inválido').required('Campo obrigatório'),
  currency: yup.string('Campo inválido').required('Campo obrigatório'),
  vendorName: yup.string('Campo inválido').required('Campo obrigatório'),
  invoiceAmount: yup.string('Campo inválido').required('Campo obrigatório'),
});
