<template>
  <div class="form-layout form-layout-2">
    <div class="row">
      <div class="col-lg-12">
        <form data-parsley-validate>
          <div class="form-layout form-layout-2">
            <label class="section-title mg-t-20">Informações de Negócios</label>

            <div class="row no-gutters">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-control-label"
                  >Modalidade do Radar aa</label
                  >
                  <select
                    class="custom-select form-control"
                    v-model="company.salesRadarModality"
                  >
                    <option selected="" value="">Selecione</option>
                    <option
                      v-for="salesRadarModality in salesRadarModalities"
                      :value="salesRadarModality.key"
                      :key="salesRadarModality.key"
                    >
                      {{ salesRadarModality.value }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mg-md-l--1">
                  <label class="form-control-label"
                  >Data do Primeiro Negócio</label
                  >
                  <date-picker lang="pt-br"
                               v-model="company.salesDateOfFirstBusiness"
                               class="form-control"
                               format="DD/MM/YYYY"
                               v-validate="'required'"
                  ></date-picker>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mg-md-l--1">
                  <label class="form-control-label"
                  >Vencimento da Procuração</label
                  >
                  <date-picker lang="pt-br"
                               v-model="company.salesDateOfDocumentOperationalValidation"
                               class="form-control"
                               format="DD/MM/YYYY"
                               v-validate="'required'"
                  ></date-picker>
                </div>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-md-12">
                <div class="form-group bd-t-0-force">
                  <label class="form-control-label">Observações</label>
                  <textarea
                    class="form-control is-valid mg-t-20"
                    name="salesObservations"
                    placeholder="Observações"
                    v-model="company.salesObservations"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="row no-gutters mg-t-10">
      <div class="col-lg-12 mg-b-20">
        <div class="card card-table mg-t-0 mg-sm-t-0">
          <div class="card-header">
            <div class="report-summary-header mg-t-0 mg-b-0">
              <div>
                <h4 class="tx-inverse mg-b-3">Comissionados</h4>
                <p class="mg-b-0">
                  <i class="fa fa-users mg-r-3"></i> No total foram adicionados
                  ## comisisonados para o clientes
                </p>
              </div>
              <div>
                <a
                  href="#"
                  class="btn btn-primary"
                  @click="changeShowAdd(true)"
                >
                  Adicionar</a
                >
              </div>
            </div>
          </div>

          <div
            class="align-items-center justify-content-center bg-gray-100 ht-md-80 bd pd-x-20 pd-y-15 mg-y-20 mg-x-20"
            v-if="showAdd"
          >
            <div class="d-md-flex pd-y-20 pd-md-y-0 pd-b-20">
              <v-select
                class="form-control"
                v-model="model.commissioned"
                label="completeName"
                :options="optionsUsers"
                name="customBrokerModel"
              >
                <template slot="option" slot-scope="option">
                  {{ option.name ? option.name : option.completeName }} -
                  <small>({{ option.federalID }})</small>
                </template>
              </v-select>
              <input
                class="form-control mg-md-l-10"
                type="text"
                name="siscoserv"
                v-model="model.description"
                placeholder="Descrição"
              />
              <money
                class="form-control mg-md-l-10"
                v-model="model.percetualOfComission"
                v-bind="percent"
              ></money>
              <button
                class="btn btn-primary pd-y-13 pd-x-20 bd-0 mg-md-l-10 mg-t-10 mg-md-t-0"
                @click.prevent="add"
                type="button"
              >
                ADICIONAR
              </button>
              <button
                class="btn btn-danger pd-y-13 pd-x-20 bd-0 mg-md-l-10 mg-t-10 mg-md-t-0"
                @click.prevent="changeShowAdd(false)"
                type="button"
              >
                CANCELAR
              </button>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table mg-b-0 tx-13">
              <thead>
              <tr class="tx-10">
                <th class="pd-y-5 tx-center">Nome</th>
                <th class="pd-y-5 tx-center">Email</th>
                <th class="pd-y-5 tx-center">Descrição</th>
                <th class="pd-y-5 tx-center">Percentual</th>
                <th class="pd-y-5 tx-center">Ações</th>
              </tr>
              </thead>
              <tbody>
              <template
                v-if="
                    company._embedded !== undefined &&
                    company._embedded.comissions !== undefined
                  "
              >
                <template v-for="comission in company._embedded.comissions">
                  <tr
                    :key="comission.id"
                    v-if="company._embedded.comissions.length > 0"
                  >
                    <td class="valign-middle tx-center">
                      <a
                        href="#"
                        class="tx-inverse tx-14 tx-medium d-block"
                      >{{
                        comission.commissioned.name
                        ? comission.commissioned.name
                        : comission.commissioned.completeName
                        }}</a
                      >
                      <span class="tx-11 d-block">{{
                          comission.commissioned.jobTitle
                        }}</span>
                    </td>
                    <td class="valign-middle tx-center">
                      <a href="#" class="tx-inverse tx-medium d-block">{{
                        comission.commissioned.email
                        }}</a>
                    </td>
                    <td class="valign-middle tx-center">
                      {{ comission.description }}
                    </td>
                    <td class="valign-middle tx-center">
                      <a href="#" class="tx-inverse tx-14 tx-medium d-block">
                        %
                        {{
                        comission.percetualOfComission
                        | numeralFormat("0,0.00")
                        }}</a
                      >
                    </td>
                    <td class="valign-middle tx-center">
                      <a
                        href="#"
                        @click="updateModel(comission)"
                        class="btn btn-primary btn-icon rounded-circle mg-r-5"
                      >
                        <div><i class="icon ion-edit tx-20"></i></div>
                      </a>

                      <a
                        href="#"
                        @click="deleteModel(comission)"
                        class="btn btn-danger btn-icon rounded-circle"
                      >
                        <div><i class="icon ion-trash-a tx-20"></i></div>
                      </a>
                    </td>
                  </tr>
                </template>
              </template>
              <tr
                v-if="
                    company._embedded === undefined ||
                    company._embedded.comissions === undefined
                  "
              >
                <td class="valign-middle tx-center" colspan="5">
                  Nenhuma comissão foi cadastrada.
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="row mg-t-20-force">
        <div class="col-md-3"></div>
        <div class="col-md-3">
          <button
            class="btn btn-primary btn-block mg-r-10-force"
            type="submit"
            @click.prevent="onSubmit()"
          >
            <i class="fa fa-floppy-o mg-r-5"></i>
            SALVAR
          </button>
        </div>
        <div class="col-md-3">
          <button
            class="btn btn-secondary btn-block mg-l-10-force"
            type="button"
            @click.prevent="$router.go(-1)"
          >
            <i class="fa fa-reply mg-r-5"></i>
            VOLTAR
          </button>
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config.js";
import Axios from "axios";
import {Money} from "v-money";
import vSelect from "vue-select";
import {EventBus} from "@/events/EventBus.js";
import DatePicker from "vue2-datepicker";

export default {
  name: "general-companies-form-sales",
  props: ["company", "oldModel", "isNewRecord"],
  components: {
    "v-select": vSelect,
    Money,
    DatePicker,
  },
  data() {
    return {
      model: {
        commissioned: null,
        percetualOfComission: 0.0,
        description: "",
      },
      percent: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      url: `${config.env.API_DEFAULT.host}/companies/search/findAllByNameContaining?name=`,
      optionsUsers: [],
      comissions: [],
      showAdd: false,
      salesRadarModalities: [
        {
          key: "EXPRESS",
          value: "Expressa USD 50.000",
        },
        {
          key: "LIMITED",
          value: "Limitada USD 150.000",
        },
        {
          key: "ILIMITED",
          value: "Ilimitada USD",
        },
      ],
    };
  },
  methods: {
    onSubmit() {
      EventBus.$emit("companies.Form.onSubmit");
    },
    changeShowAdd(showAdd) {
      this.showAdd = showAdd;
    },
    getUsers() {
      const self = this;
      const customerID = this.$route.params.id;

      Axios.get(
        `${config.env.API_DEFAULT.host}/commissioned/usuariosComissionados/`
      )
        .then((response) => {
          self.optionsUsers = response.data.data;
        })
        .catch((error) => {
          swal("Comissionados", `Ops, houve algum erro: ${error}`, "error");
        });
    },
    add() {
      const customerId = this.$route.params.id;

      if (this.model) {
        const commissionedID = this.model.commissioned.id;
        const customerID = this.$route.params.id;

        const data = {
          percentual: this.model.percetualOfComission,
          description: this.model.description,
        };

        Axios.post(
          `${config.env.API_DEFAULT.host}/commissioned/vincularComissionado/${customerID}/${commissionedID}`,
          data
        ).then((response) => {
          this.changeShowAdd(false);
          this.model = {
            commissioned: null,
            percetualOfComission: 0.0,
            description: "",
          };

          swal("Comissionados", "Comissão adicionada com sucesso", "success");

          EventBus.$emit("companies.Form.loadModel");
        });
      }
      // var self = this;

      // if (self.model.id !== undefined) {
      //     if (self.model.commissioned !== null && self.model.commissioned !== undefined) {
      //         let commissioned = self.model.commissioned;
      //         self.model.commissioned = config.env.API_DEFAULT.host + "/users/" + commissioned.id;
      //     }
      //     Axios.patch(config.env.API_DEFAULT.host + "/companyComissions/" + self.model.id, self.model).then((response) => {
      //         self.changeShowAdd(false);
      //             self.model = {
      //             commissioned: null,
      //             percetualOfComission: 0.0,
      //             description: '',
      //         };
      //         swal('Comissionados', 'Comissão adicionada com sucesso', "success");
      //         EventBus.$emit("companies.Form.loadModel");
      //     }).catch(function (error) {
      //         swal('Comissionados', 'Ops, houve algum erro: ' + error, "error");
      //     });
      // } else {
      //     self.model.account = self.$store.state.credentials.data.account.href;
      //     self.model.company = self.company._links.self.href;

      //     if (self.model.commissioned !== null && self.model.commissioned !== undefined) {
      //         let commissioned = self.model.commissioned;
      //         self.model.commissioned = commissioned._links.self.href;
      //     }

      //     Axios.post(config.env.API_DEFAULT.host + "/companyComissions", self.model).then((response) => {
      //         self.changeShowAdd(false);
      //         swal('Comissionados', 'Comissão adicionada com sucesso', "success");
      //         EventBus.$emit("companies.Form.loadModel");
      //         self.model = {
      //             commissioned: null,
      //             percetualOfComission: 0.0,
      //             description: '',
      //         };
      //     }).catch(function (error) {
      //         swal('Comissionados', 'Ops, houve algum erro: ' + error, "error");
      //     });

      //     // EventBus.$emit('companies.Form.onSubmit');
      // }
    },

    updateModel(model) {
      const self = this;
      self.changeShowAdd(true);
      self.model = model;
    },
    deleteModel(model) {
      const self = this;

      self
        .$swal({
          title: "Excluir Comissão?",
          text: "Se você excluir a comissão não será possível recuperá-la para uso posterior. Você realmente tem certeza disso?",
          icon: "warning",
          buttons: ["Não", "Sim"],
          dangerMode: true,
        })
        .then((willContinue) => {
          if (willContinue) {
            Axios.delete(
              `${config.env.API_DEFAULT.host}/companyComissions/${model.id}`
            )
              .then((response) => {
                EventBus.$emit("companies.Form.loadModel");
                self.$swal(
                  "Comissão",
                  "Comissão removida com sucesso!",
                  "success"
                );
              })
              .catch((error) => {
                self.$swal(
                  "Comissão",
                  `Ops, houve um erro! \n\n${error}`,
                  "error"
                );
              });
          }
        });
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>
