<template>
  <transition-group name="modal fade">
    <div key="div" class="card bd-0" v-if="this.profileScreen === false">
      <div
        class="card-header bg-primary bd-0 d-flex align-items-center justify-content-between pd-y-5"
      >
        <h6 class="mg-b-0 tx-14 tx-white tx-normal" v-if="this.isNewRecord()">
          ADICIONAR CONTATO
        </h6>
        <h6 class="mg-b-0 tx-14 tx-white tx-normal" v-else>
          ALTERAR CONTATO <small>{{ modelUpdated.name }}</small>
        </h6>
        <div class="card-option tx-24">
          <a href="#" class="tx-white-8 hover-white mg-l-10" @click="cancel"
          ><i class="icon ion-ios-close lh-0"></i
          ></a>
        </div>
      </div>
    </div>

    <form key="form" class="" @submit.prevent="submit()">
      <div class="form-layout form-layout-2">
        <label class="section-title mg-t-20">Dados Gerais do Contato</label>

        <div class="row no-gutters">
          <div class="col-md-3 col-sm-12">
            <div class="form-group">
              <label class="form-control-label"
              >Nome Completo <span class="tx-danger">*</span></label
              >
              <input
                type="text"
                class="form-control m-input"
                v-model="modelUpdated.name"
                name="name"
                v-validate="'required'"
              />
              <span
                v-show="errors.has('name')"
                class="parsley-errors-list filled"
              >Nome é um campo obrigatório.</span
              >
            </div>
          </div>

          <div class="col-md-3 col-sm-12">
            <div class="form-group mg-md-l--1">
              <label class="form-control-label">CPF</label>
              <the-mask
                mask="###.###.###-##"
                class="form-control m-input"
                v-model="modelUpdated.federalID"
                name="federalID"
                @keyup.native="verifyIfUserExistsEvent"
              />
              <span
                v-show="errors.has('federalID')"
                class="parsley-errors-list filled"
              >Digite o CPF</span
              >
            </div>
          </div>

          <div class="col-md-3 col-sm-12">
            <div class="form-group mg-md-l--1">
              <label class="form-control-label">Data de Nascimento</label>
              <date-picker lang="pt-br"
                           v-model="modelUpdated.dateOfBirth"
                           :first-day-of-week="1"
                           format="DD/MM/YYYY"
                           name="dateOfBirth"
                           placeholder="Selecione"
              ></date-picker>
            </div>
          </div>

          <div class="col-md-3 col-sm-12">
            <div class="form-group mg-md-l--1">
              <label class="form-control-label">
                Sexo <span class="tx-danger">*</span>
              </label>

              <div class="row">
                <div class="col-lg-6">
                  <label class="rdiobox rdiobox-success mg-t-0-force">
                    <input
                      id="gender-m"
                      name="gender"
                      type="radio"
                      v-model="modelUpdated.gender"
                      value="m"
                      v-validate="'required'"
                    />
                    <span> Masculino </span>
                  </label>
                </div>
                <div class="col-lg-6">
                  <label class="rdiobox rdiobox-danger mg-t-0-force">
                    <input
                      id="gender-f"
                      name="gender"
                      type="radio"
                      v-model="modelUpdated.gender"
                      value="f"
                      v-validate="'required'"
                    />
                    <span> Feminino </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row no-gutters">
          <div class="col-md-3 col-sm-12">
            <div class="form-group bd-t-0-force">
              <label class="form-control-label"
              >Email <span class="tx-danger">*</span></label
              >
              <input
                class="form-control"
                type="text"
                name="email1"
                placeholder="Email"
                v-model="modelUpdated.email1"
                v-validate="'required|email'"
              />
              <span
                v-show="errors.has('email1')"
                class="parsley-errors-list filled"
              >Email Principal de ser um email válido.
              </span>
            </div>
          </div>

          <div class="col-md-3 col-sm-12">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label">Celular </label>
              <the-mask
                :mask="['(##) ####-####', '(##) #####-####']"
                :masked="true"
                placeholder="Celular"
                v-model="modelUpdated.telephone2"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-md-3 col-sm-12">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label">Telefone Fixo</label>
              <the-mask
                :mask="['(##) ####-####', '(##) #####-####']"
                :masked="true"
                placeholder="Telefone Principal"
                v-model="modelUpdated.telephone1"
                name="telephone1"
                class="form-control"
              />
              <span
                v-show="errors.has('telephone1')"
                class="parsley-errors-list filled"
              >Digite o Telefone Principal</span
              >
            </div>
          </div>

          <div class="col-md-3 col-sm-12">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label">Email Secundário</label>
              <input
                class="form-control"
                type="text"
                name="email2"
                placeholder="Email"
                v-model="modelUpdated.email2"
                v-validate="'email'"
              />
              <span
                v-show="errors.has('email2')"
                class="parsley-errors-list filled"
              >Email Secundário de ser um email válido.</span
              >
            </div>
          </div>
        </div>

        <div class="row no-gutters">
          <div class="col-md-3">
            <div class="form-group bd-t-0-force">
              <label class="form-control-label">Cargo</label>
              <input
                class="form-control"
                type="text"
                name="jobRole"
                placeholder="Cargo"
                v-model="modelUpdated.jobRole"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label">Setor</label>
              <select
                class="custom-select form-control"
                v-model="modelUpdated.sector"
                name="sector"
              >
                <option selected="">Selecione</option>
                <option
                  v-for="sector in sectors"
                  :value="sector.key"
                  :key="sector.key"
                >
                  {{ sector.value }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label"> Situação </label>

              <div class="row">
                <div class="col-lg-5">
                  <label class="rdiobox rdiobox-success mg-t-0-force">
                    <input
                      id="contactEnabledTrue"
                      name="enabled"
                      type="radio"
                      v-model="modelUpdated.enabled"
                      :value="true"
                    />
                    <span>Habilitado</span>
                  </label>
                </div>
                <div class="col-lg-5">
                  <label class="rdiobox rdiobox-danger mg-t-0-force">
                    <input
                      id="contactEnabledFalse"
                      name="enabled"
                      type="radio"
                      v-model="modelUpdated.enabled"
                      :value="false"
                    />
                    <span>Desabilitado</span>
                  </label>
                </div>
                <span
                  v-show="errors.has('enabled')"
                  class="parsley-errors-list filled"
                >Selecione uma opção</span
                >
              </div>
            </div>
          </div>
        </div>

        <label class="section-title mg-t-20">Informações de Usuário</label>

        <div class="row no-gutters">
          <div class="col-md-12">
            <div class="form-group mg-md-l--1">
              <label class="form-control-label">
                Papel / Perfil <span class="tx-danger">*</span>
              </label>

              <div class="row">
                <div class="col-lg-2">
                  <label class="rdiobox rdiobox-success mg-t-0-force">
                    <input
                      name="role"
                      type="radio"
                      v-model="modelUpdated.role"
                      :value="'CUSTOMER'"
                      v-validate="'required'"
                    />
                    <span> Cliente (Externo)</span>
                  </label>
                </div>
                <div class="col-lg-2">
                  <label class="rdiobox rdiobox-success mg-t-0-force">
                    <input
                      name="role"
                      type="radio"
                      v-model="modelUpdated.role"
                      :value="'PARTNER'"
                      v-validate="'required'"
                    />
                    <span>Parceiro (Externo)</span>
                  </label>
                </div>
                <div class="col-lg-2">
                  <label class="rdiobox rdiobox-success mg-t-0-force">
                    <input
                      name="role"
                      type="radio"
                      v-model="modelUpdated.role"
                      :value="'CUSTOM_BROKER'"
                      v-validate="'required'"
                    />
                    <span>Despachante (Externo)</span>
                  </label>
                </div>
                <div class="col-lg-2">
                  <label class="rdiobox rdiobox-success mg-t-0-force">
                    <input
                      name="role"
                      type="radio"
                      v-model="modelUpdated.role"
                      :value="'OPERATIONAL'"
                      v-validate="'required'"
                    />
                    <span> Operacional (Interno) </span>
                  </label>
                </div>
                <div class="col-lg-2">
                  <label class="rdiobox rdiobox-success mg-t-0-force">
                    <input
                      name="role"
                      type="radio"
                      v-model="modelUpdated.role"
                      :value="'MANAGER'"
                      v-validate="'required'"
                    />
                    <span>Gerente (Interno)</span>
                  </label>
                </div>
                <div class="col-lg-2">
                  <label class="rdiobox rdiobox-success mg-t-0-force">
                    <input
                      name="role"
                      type="radio"
                      v-model="modelUpdated.role"
                      :value="'ADMINISTRATOR'"
                      v-validate="'required'"
                    />
                    <span>Adminstrador (Interno)</span>
                  </label>
                </div>

                <span
                  v-show="errors.has('enabled')"
                  class="parsley-errors-list filled"
                >Selecione uma opção</span
                >
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label">Usuário</label>
              <input
                type="text"
                class="form-control m-input"
                :value="modelUpdated.email1"
                disabled="disabled"
              />
            </div>
          </div>

          <div class="col-md-3" v-if="!isNewRecord()">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <div class="row pd-l-30 mg-t-20">
                <label class="ckbox ckbox-success">
                  <input
                    type="checkbox"
                    v-model="changePassword"
                    :value="true"
                    name="changePassword"
                  /><span> Alterar Senha </span>
                </label>
              </div>
            </div>
          </div>

          <div class="col-md-3" v-if="isNewRecord()">
            <div class="form-group mg-md-l--1 bd-t-0-force bd-t-0-force">
              <label class="form-control-label"
              >Senha <span class="tx-danger">*</span></label
              >
              <input
                type="password"
                class="form-control"
                v-model="modelUpdated.password"
                name="password"
                v-validate="{ required: true, confirmed: passwordRepeat }"
              />
              <span
                v-show="errors.has('password')"
                class="parsley-errors-list filled"
              >As senhas digitadas não são iguais.</span
              >
            </div>
          </div>

          <div class="col-md-3" v-if="isNewRecord()">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label"
              >Repita a Senha <span class="tx-danger">*</span></label
              >
              <input
                type="password"
                class="form-control"
                v-model="passwordRepeat"
                name="passwordRepeat"
                v-validate="{ required: true, confirmed: passwordRepeat }"
              />
              <span
                v-show="errors.has('password')"
                class="parsley-errors-list filled"
              >As senhas digitadas não são iguais.</span
              >
            </div>
          </div>

          <div class="col-md-3" v-if="!isNewRecord() && showChangePassword">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label">
                Nova Senha <span class="tx-danger">*</span></label
              >
              <input
                type="password"
                class="form-control"
                v-model="newPassword"
                name="newPassword"
                v-validate="{ required: true, confirmed: newPasswordRepeat }"
                v-if="changePassword"
              />
              <input
                type="password"
                class="form-control"
                v-model="newPassword"
                name="newPassword"
                v-else
                disabled
                readonly
              />
              <span
                v-show="errors.has('newPassword')"
                class="parsley-errors-list filled"
              >Senha é um campo obrigatório.</span
              >
            </div>
          </div>

          <div class="col-md-3" v-if="!isNewRecord() && showChangePassword">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label">
                Repita a Nova Senha <span class="tx-danger">*</span></label
              >
              <input
                type="password"
                class="form-control"
                v-model="newPasswordRepeat"
                name="newPasswordRepeat"
                v-validate="{ required: true, confirmed: newPasswordRepeat }"
                v-if="changePassword"
              />
              <input
                type="password"
                class="form-control"
                v-model="newPasswordRepeat"
                name="newPasswordRepeat"
                v-else
                disabled
                readonly
              />
              <span
                v-show="errors.has('newPasswordRepeat')"
                class="parsley-errors-list filled"
              >Repita a senha é um campo obrigatório.</span
              >
            </div>
          </div>
          <div class="col-md-3" v-if="!isNewRecord()">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <div class="row pd-l-30 mg-t-20">
                <label class="ckbox ckbox-success">
                  <input
                    type="checkbox"
                    v-model="modelUpdated.receivesClousing"
                    :value="true"
                    name="receivesPassword"
                  /><span> Recebe Encerramento </span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <label class="section-title mg-t-20">Configuração de Follow UP</label>

        <div class="row no-gutters pd-y-20 pd-x-20 bg-gray-100">
          <div class="col-md-3">
            <div class="form-group mg-md-l--1">
              <label class="form-control-label">
                Recebe Follow UP? <span class="tx-danger">*</span></label
              >

              <div class="row">
                <div class="col-lg-6">
                  <label class="rdiobox rdiobox-success mg-t-0-force">
                    <input
                      id="followUP-true"
                      name="followUP"
                      type="radio"
                      v-model="modelUpdated.followUP"
                      :value="true"
                      v-validate="'required'"
                    />
                    <span> Sim </span>
                  </label>
                  <span
                    v-show="errors.has('followUP')"
                    class="parsley-errors-list filled"
                  >Selecione uma opção.</span
                  >
                </div>
                <div class="col-lg-6">
                  <label class="rdiobox rdiobox-danger mg-t-0-force">
                    <input
                      id="followUP-false"
                      name="followUP"
                      type="radio"
                      v-model="modelUpdated.followUP"
                      :value="false"
                      v-validate="'required'"
                    />
                    <span> Não </span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-md-9"
            v-if="
              showFollowUP && !showFollowUPAnalytical && !showFollowUPSynthetic
            "
          >
            <div
              class="form-group mg-md-l--1 bd-t-0-force bd-r-0-force p-0 m-0"
            >
              <div class="alert alert-solid alert-danger mb-0" role="alert">
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <strong>Recebe Follow UP:</strong> Este contato está habilitado
                para receber Follow UP, mas nenhum tipo de Follow UP foi
                selecionado para que ele possa receber.
              </div>
            </div>
          </div>

          <div class="col-md-9" v-if="showFollowUPAnalytical">
            <div
              class="form-group mg-md-l--1 bd-t-0-force bd-r-0-force p-0 m-0"
            >
              <div class="alert alert-solid alert-info mb-0" role="alert">
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <strong>Follow UP Analítico!</strong> Quando o Follow UP
                Analítico estiver habilitado, uma vez na semana (sexta-feira) os
                contatos irão receber automaticamente um Follow UP de todos os
                processos em andamento.
              </div>
            </div>
          </div>

          <template v-if="modelUpdated.followUP">
            <div class="col-md-3">
              <div class="form-group mg-md-l--1 bd-t-0-force">
                <label class="form-control-label">
                  Recebe Follow UP Analítico?
                  <span class="tx-danger">*</span></label
                >
                <div class="row">
                  <div class="col-lg-6">
                    <label class="rdiobox rdiobox-success mg-t-0-force">
                      <input
                        id="followUPAnalytical-true"
                        name="followUPAnalytical"
                        type="radio"
                        v-model="modelUpdated.followUPAnalytical"
                        :value="true"
                        v-validate="'required'"
                      />
                      <span> Sim </span>
                    </label>
                    <span
                      v-show="errors.has('followUPAnalytical')"
                      class="parsley-errors-list filled"
                    >Selecione uma opção.</span
                    >
                  </div>
                  <div class="col-lg-6">
                    <label class="rdiobox rdiobox-danger mg-t-0-force">
                      <input
                        id="followUPAnalytical-false"
                        name="followUPAnalytical"
                        type="radio"
                        v-model="modelUpdated.followUPAnalytical"
                        :value="false"
                        v-validate="'required'"
                      />
                      <span> Não </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-9" v-if="showFollowUPAnalytical">
              <div class="form-group mg-md-l--1 bd-t-0-force">
                <label class="form-control-label"
                >Follow UP Recebidos (Eventos)</label
                >
                <v-select
                  v-model="modelUpdated.followUPEvents"
                  name="followUPEvents"
                  label="description"
                  :options="listOfEvents"
                  multiple
                >
                  <template slot="option" slot-scope="option">
                    {{ option.description }}
                  </template>
                  <span slot="no-options">Nenhuma Evento foi encontrada!</span>
                </v-select>
                <!-- <v-select v-model="modelUpdated.jsonEvents" name="jsonEvents" label="description" :options="listOfEvents" v-validate="'required'" multiple>
                                <template slot="option" slot-scope="option"> {{ option.description }} </template>
                                <span slot="no-options">Nenhuma Evento foi encontrada!</span>
                            </v-select> -->
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group mg-md-l--1 bd-t-0-force">
                <label class="form-control-label">
                  Recebe Follow UP Sintético?
                  <span class="tx-danger">*</span></label
                >
                <div class="row">
                  <div class="col-lg-6">
                    <label class="rdiobox rdiobox-success mg-t-0-force">
                      <input
                        id="followUPSynthetic-true"
                        name="followUPSynthetic"
                        type="radio"
                        v-model="modelUpdated.followUPSynthetic"
                        :value="true"
                        v-validate="'required'"
                      />
                      <span> Sim </span>
                    </label>
                    <span
                      v-show="errors.has('followUPSynthetic')"
                      class="parsley-errors-list filled"
                    >Selecione uma opção.</span
                    >
                  </div>
                  <div class="col-lg-6">
                    <label class="rdiobox rdiobox-danger mg-t-0-force">
                      <input
                        id="followUPSynthetic-false"
                        name="followUPSynthetic"
                        type="radio"
                        v-model="modelUpdated.followUPSynthetic"
                        :value="false"
                        v-validate="'required'"
                      />
                      <span> Não </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-3" v-if="showFollowUPSynthetic">
              <div class="form-group mg-md-l--1 bd-t-0-force">
                <label class="form-control-label"> Período </label>
                <select
                  class="form-control"
                  v-model="modelUpdated.followUPSyntheticPeriod"
                  name="followUPSyntheticPeriod"
                >
                  <option selected="">Selecione</option>
                  <option
                    v-for="period in timePeriods"
                    :value="period.key"
                    :key="period.key"
                  >
                    {{ period.value }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-3" v-if="showFollowUPSynthetic">
              <div class="form-group mg-md-l--1 bd-t-0-force">
                <label class="form-control-label"> Dia de Recebimento </label>
                <select
                  class="form-control"
                  v-model="modelUpdated.followUPSyntheticWeekday"
                  name="followUPSyntheticWeekday"
                >
                  <option selected="">Selecione</option>
                  <option v-for="day in days" :value="day.key" :key="day.key">
                    {{ day.value }}
                  </option>
                </select>
              </div>
            </div>
          </template>
        </div>

        <label class="section-title mg-t-20">Informações de Endereço</label>

        <div class="row no-gutters">
          <div class="col-md-3">
            <div class="form-group">
              <label class="form-control-label">CEP</label>
              <the-mask
                mask="#####-###"
                placeholder="CEP"
                v-model="modelUpdated.postalCode"
                name="postalCode"
                class="form-control"
                @blur.native="findCEP"
              />
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mg-md-l--1">
              <label class="form-control-label">Logradouro</label>
              <input
                class="form-control"
                type="text"
                name="address"
                placeholder="Logradouro"
                v-model="modelUpdated.address"
                :class="{ 'parsley-error': errors.has('address') }"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mg-md-l--1">
              <label class="form-control-label">Número</label>
              <input
                class="form-control"
                type="text"
                name="addressNumber"
                placeholder="Número"
                v-model="modelUpdated.addressNumber"
                :class="{ 'parsley-error': errors.has('address') }"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mg-md-l--1">
              <label class="form-control-label">Complemento</label>
              <input
                class="form-control"
                type="text"
                name="complement"
                placeholder="Complemento"
                v-model="modelUpdated.complement"
              />
            </div>
          </div>
        </div>

        <div class="row no-gutters">
          <div class="col-md-3">
            <div class="form-group bd-t-0-force">
              <label class="form-control-label">Bairro </label>
              <input
                class="form-control"
                type="text"
                name="district"
                placeholder="Bairro"
                v-model="modelUpdated.district"
                :class="{ 'parsley-error': errors.has('district') }"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label">Cidade </label>
              <input
                class="form-control"
                type="text"
                name="city"
                placeholder="Cidade"
                v-model="modelUpdated.city"
                :class="{ 'parsley-error': errors.has('city') }"
              />
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mg-md-l--1 bd-t-0-force">
              <label class="form-control-label"> UF </label>
              <select
                class="form-control"
                v-model="modelUpdated.federalUnity"
                name="federalUnity"
              >
                <option selected="">Selecione</option>
                <option v-for="uf in ufs" :value="uf.sigla" :key="uf.sigla">
                  {{ uf.nome }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="row mg-t-20-force">
          <div class="col-md-3"></div>
          <div class="col-md-3">
            <button
              class="btn btn-primary btn-block mg-r-10-force"
              type="submit"
            >
              <i class="fa fa-floppy-o mg-r-5"></i>
              SALVAR
            </button>
          </div>
          <div class="col-md-3">
            <button
              class="btn btn-secondary btn-block mg-l-10-force"
              type="button"
              @click="cancel"
            >
              <i class="fa fa-reply mg-r-5"></i>
              VOLTAR
            </button>
          </div>
          <div class="col-md-3"></div>
        </div>
      </div>
    </form>
  </transition-group>
</template>

<script>
import Axios from "axios";
import DatePicker from "vue2-datepicker";
import * as constants from "@/consts.js";
import {EventBus} from "@/events/EventBus.js";
import {TheMask} from "vue-the-mask";
import UtilsService from "@/services/UtilsService.js";
import config from "@/config.js";
import Swal from "sweetalert2";

export default {
  name: "general-contacts-form-modal",
  props: ["modelUpdated", "company", "profileScreen", "contactScreen"],
  components: {
    DatePicker,
    TheMask,
  },
  data() {
    return {
      model: {
        oldID: null,
        enabled: true,
        name: null,
        federalID: null,
        dateOfBirth: null,
        gender: "M",
        sector: null,
        jobRole: null,
        email1: null,
        email2: null,
        telephone1: null,
        telephone2: null,
        postalCode: null,
        address: null,
        addressNumber: null,
        complement: null,
        district: null,
        city: null,
        federalUnity: null,
        commissioned: null,
        followUP: false,
        followUPSynthetic: null,
        followUPAnalytical: null,
        followUPAnalyticalWeekDay: null,
        followUPSyntheticPeriod: null,
        jsonEvents: [],
      },
      modelTitle: "Cadastro de Contato",
      labelEntity: this.profileScreen === true ? "Usuário" : "Contato",
      changePassword: false,
      passwordRepeat: null,
      newPassword: null,
      newPasswordRepeat: null,
      showAddress: this.modelUpdated.postalCode === null && this.isNewRecord(),
      ufs: constants.CADASTROS_UF,
      days: constants.CADASTROS_DIAS_DA_SEMANA,
      timePeriods: constants.PERIODOS_TEMPO,
      sectors: constants.CADASTROS_SETORES,
      temporaryContact: null,
      events: [],
    };
  },
  computed: {
    listOfEvents() {
      return this.events;
    },
    showChangePassword() {
      return this.changePassword;
    },
    showFollowUP() {
      return this.modelUpdated.followUP;
    },
    showFollowUPAnalytical() {
      return this.modelUpdated.followUPAnalytical;
    },
    showFollowUPSynthetic() {
      return this.modelUpdated.followUPSynthetic;
    },
  },
  methods: {
    isNewRecord() {
      if (
        this.modelUpdated.id === null ||
        this.modelUpdated.id === undefined ||
        this.modelUpdated.id === ""
      ) {
        return true;
      }
      return false;
    },
    submit() {
      const self = this;
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (self.isNewRecord()) self.addModel();
          else self.updateModel();
        } else
          swal(
            self.modelTitle,
            "Você não preencheu todos os campos obrigatórios.",
            "error"
          );
      });
    },
    addModel() {
      const self = this;
      const user = JSON.parse(window.localStorage.getItem("user"));

      self.modelUpdated.userRegistry = user;

      self.modelUpdated.account = user.account;

      if (!self.modelUpdated.followUP) {
        self.modelUpdated.followUPAnalytical = false;
        self.modelUpdated.followUPSynthetic = false;
        self.modelUpdated.followUPEvents = null;
        self.modelUpdated.followUPSyntheticPeriod = null;
        self.modelUpdated.followUPSyntheticWeekday = null;
      }

      Axios.post(
        `${config.env.API_DEFAULT.host}/credential/user`,
        self.modelUpdated
      )
        .then((response) => {
          if (response.status == 201) {
            self.$swal(
              "Cadastro de Contatos",
              `A Contato "${response.data.name}" foi adicionada com sucesso.`,
              "success"
            );

            if (self.company !== null) {
              Axios.patch(
                `${config.env.API_DEFAULT.host}/companies/${self.company.id}/users`,
                `${config.env.API_DEFAULT.host}/users/${response.data.id}`,
                {
                  headers: {
                    "Content-Type": "text/uri-list",
                  },
                }
              )
                .then((response) => {
                  self.$emit("close");
                  EventBus.$emit("companies.Form.loadModel");
                })
                .catch((error) => {
                  self.$swal(
                    "Cadastro de Contatos",
                    `Ops, houve algum erro: ${error}`,
                    "error"
                  );
                });
            }
          }
        })
        .catch((error) => {
          self.$swal(
            "Cadastro de Contatos",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
        });
    },
    updateModel() {
      const self = this;

      if (!self.modelUpdated.followUP) {
        self.modelUpdated.followUPAnalytical = false;
        self.modelUpdated.followUPSynthetic = false;
        self.modelUpdated.followUPEvents = null;
        self.modelUpdated.followUPSyntheticPeriod = null;
        self.modelUpdated.followUPSyntheticWeekday = null;
      }

      if (self.changePassword === true && self.newPassword !== null) {
        self.modelUpdated.password = self.newPassword;
      }

      Axios.patch(
        `${config.env.API_DEFAULT.host}/credential/user/${self.modelUpdated.id}`,
        self.modelUpdated
      )
        .then((response) => {
          self.$swal(
            `Cadastro de ${self.labelEntity}s`,
            `As informações do ${self.labelEntity.toLowerCase()} " ${
              response.data.name
            } " foram atualizadas com sucesso no banco dados.`,
            "success"
          );
          if (this.profileScreen === false) self.$emit("close");
          EventBus.$emit("companies.Form.loadModel");
        })
        .catch((error) => {
          self.$swal(
            "Cadastro de Contatos",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
        });
    },
    findCEP() {
      const self = this;
      UtilsService.findCEP(this.modelUpdated.postalCode).then((response) => {
        self.modelUpdated.address = response.logradouro;
        self.modelUpdated.district = response.bairro;
        self.modelUpdated.city = response.localidade;
        self.modelUpdated.federalUnity = response.uf;
      });

      Axios.defaults.headers.common.Authorization = `Bearer ${self.$store.state.credentials.accessToken}`;
    },
    loadEvents() {
      Axios.get(this.modelUpdated._links.followUPEvents.href)
        .then((response) => {
          this.modelUpdated.followUPEvents = response.data._embedded.events;
        })
        .catch((error) => {
        });

      Axios.get(`${config.env.API_DEFAULT.host}/events`)
        .then((response) => {
          this.events = response.data._embedded.events;
        })
        .catch((error) => {
          Swal.fire(
            "Carregar Eventos",
            `Não foi possível carregar os eventos cadastrados: ${error}`,
            "error"
          );
        });
    },
    cancel() {
      EventBus.$emit("companies.Form.loadModel");
      this.$emit("close");
    },
    verifyIfUserExistsEvent(event) {
      this.verifyIfUserExists();
    },
    verifyIfUserExists() {
      const self = this;

      if (self.modelUpdated.federalID.length === 11) {
        const id =
          self.modelUpdated.id !== null && self.modelUpdated.id !== undefined
            ? self.modelUpdated.id
            : "";
        const url = `${"/credential/user/verify?" + "id="}${id}&federalID=${
          self.modelUpdated.federalID
        }`;

        Axios.get(config.env.API_DEFAULT.host + url)
          .then((response) => {
            if (response.data.code === "ERROR") {
              self.temporaryContact = response.data.data;

              self.modelUpdated.federalID = "";

              self
                .$swal({
                  title: "Contatos",
                  text: `${response.data.message} O nome do contato é ${response.data.data.name} e o email é ${response.data.data.email1}.`,
                  icon: "error",
                  buttons: ["Não", "Sim"],
                })
                .then((result) => {
                  if (result.value === true) {
                    if (self.company !== null) {
                      Axios.patch(
                        `${config.env.API_DEFAULT.host}/companies/${self.company.id}/users`,
                        `${config.env.API_DEFAULT.host}/users/${self.temporaryContact.id}`,
                        {
                          headers: {
                            "Content-Type": "text/uri-list",
                          },
                        }
                      )
                        .then((response) => {
                          EventBus.$emit("companies.Form.loadModel");
                          swal(
                            "Cadastro de Empresas",
                            `${self.temporaryContact.name} foi associado a empresa com sucesso.`,
                            "success"
                          );
                          self.$emit("close");
                        })
                        .catch((error) => {
                          swal(
                            "Cadastro de Empresas",
                            `Ops, houve algum erro ao tentar associar o contato: ${error}`,
                            "error"
                          );
                        });
                    }
                  }
                });
            }
          })
          .catch((error) => {
            swal(
              "Cadastro de Contatos",
              `Ops, houve algum erro: ${error}`,
              "error"
            );
          });
      }
    },
  },
  mounted() {
    this.loadEvents();
    this.modelUpdated.employee = false;
  },
};
</script>
