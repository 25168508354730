<template>
  <div class="process-form-content z-index-100 bd-10">
    <div
      class="alert alert-danger mg-b-0 pd-10 mg-20 ht-65"
      role="alert"
      v-if="
        model.id !== null &&
        model.step !== null &&
        model.step !== undefined &&
        model.step.alias === 'Concluído'
      "
    >
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">x</span>
      </button>

      <span class="float-left">
        <span class="tx-black tx-uppercase align-middle">
          <span class="tx-spacing-1 mg-l-10">
            <i class="icon ion-flag"></i> Este processo está marcado como
            CONCLUÍDO
          </span>

          <span class="tx-spacing-1">
            <i class="fa fa-ban mg-l-10"></i> Nenhum tipo de alteração será
            permitido
          </span>
          <br />
          <small class="tx-uppercase tx-12 mg-l-10">
            Usuários com permissões para Habilitar Edição de Processos
            concluídos podem liberar processos para alterações
          </small>
        </span>
      </span>

      <span
        class="float-right"
        v-if="this.hasPrivilege('PROCESSO_IMPORTACAO_EDICAO_CONCLUIDO')"
      >
        <button
          class="btn btn-oblong btn-danger btn-block mg-b-10 mg-r-20 float-right wd-150"
          @click.prevent="enableToEdit()"
        >
          <i class="fa fa-key"></i> LIBERAR
        </button>
      </span>
    </div>

    <div class="slim-pageheader col-md-12 pd-l-20 pd-r-20 pd-t-10 pd-b-10">
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Fechar"
        @click="clearProcess()"
      >
        <i class="fa fa-times tx-primary mg-r-8"></i>
      </button>
      <div
        class="nav nav-pills status-filters flex-column flex-md-row tx-uppercase"
        v-bind:class="{ 'no-click': StateNoClick }"
      >
        <div
          class="nav-item"
          v-for="step in steps"
          :key="step.code"
          @click.prevent="setStep(step)"
        >
          <template
            v-if="
              process !== null &&
              process !== undefined &&
              process.step !== null &&
              process.step !== undefined &&
              process.step.code == step.code
            "
          >
            <div
              class="nav-link active"
              v-if="step.code && step.code != 'todas'"
              :style="
                'border-color: ' +
                step.color +
                ';background-color:' +
                step.color +
                '; color:' +
                step.textColor +
                ';'
              "
            >
              <span>{{ step.alias }}</span>
            </div>
          </template>
          <template v-else>
            <div
              class="nav-link"
              v-if="step.code && step.code != 'todas'"
              :class="
                !canConclude && (step.code === '10' || step.code === '13')
                  ? 'disabled-link'
                  : ''
              "
            >
              <span
                ><i
                  v-if="
                    !canConclude && (step.code === '10' || step.code === '13')
                  "
                  class="fa fa-lock"
                ></i>
                {{ step.alias }}</span
              >
            </div>
          </template>
        </div>
      </div>

      <h6 class="slim-pagetitle">
        {{ getTypeOfProcessReference }}
        <span class="tx-xthin tx-14">(ID {{ process.id }})</span>
        <template v-if="StateNoClick">
          <br />
          <span
            class="tx-12 tx-light tx-danger"
            v-if="
              openedProcesses[process.id].user !== credentials.data.person.name
            "
          >
            <i class="fa fa-flag tx-danger"></i>
            {{ openedProcesses[process.id].user }}
          </span>
          <span class="tx-12 tx-light tx-danger" v-else>
            <i class="fa fa-flag tx-danger"></i>{{ noClickMessage }}
          </span>
        </template>
      </h6>
    </div>

    <div class="formContent">
      <!-- <div class="col-md-1 pd-0 mg-0">
        <label
          class="processListSearch bd-t-0-force bd-b-1 bd-b-danger"
          for="searchProcess"
        >
          <i class="fa fa-search tx-teal mg-r-8"></i>
          <input
            class="form-control tx-bold-force tx-bold tx-16"
            type="text"
            name="searchProcess"
            v-model="searchProcess"
            placeholder="Buscar"
          />
          <i
            class="fa fa-times tx-danger mg-r-8"
            v-if="searchProcess != ''"
            @click.prevent="searchProcess = ''"
          ></i>
        </label>

        <perfect-scrollbar class="processesList">
          <ul class="list-group" v-if="showProcessList">
            <li
              class="list-group-item"
              :class="{
                bold: listItem.identification == process.identification,
              }"
              v-for="listItem in processesToList"
              :key="listItem.id"
              @click.prevent="editProcess(listItem)"
            >
              <i class="fa fa-external-link tx-primary mg-r-8"></i>
              <span>{{ getTypeOfProcessReferenceByProcess(listItem) }}</span>
            </li>
          </ul>
        </perfect-scrollbar>
      </div> -->

      <perfect-scrollbar
        class="col-md-12 processesList gray-bg"
        id="aa"
        name="bb"
      >
        <header class="align-items-center">
          <transition name="fade">
            <div
              v-if="
                model.stepInsuranceValueResponsability == 'PRIME' &&
                model.incoterm == 'CIF'
              "
              class="alert alert-danger mg-b-0 mg-t-10 mg-b-10 pd-5"
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">x</span>
              </button>

              <p class="mg-b-0">
                <b>Processo CIF com seguro Prime!</b> Este processo aparecerá no
                relatório automatizado de seguros.
              </p>
            </div>
          </transition>

          <transition
            name="fade"
            v-if="
              salesDateOfDocumentOperationalValidationDiff === null ||
              salesDateOfDocumentOperationalValidationDiff <= 30
            "
          >
            <div
              class="alert alert-danger mg-b-0 mg-t-10 mg-b-10 pd-5"
              role="alert"
              v-if="salesDateOfDocumentOperationalValidationDiff === null"
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <strong>Procuração do Cliente: </strong>
              <span>
                O cliente deste processo ainda não tem uma data de procuração
                cadastrada.
              </span>
            </div>
            <div
              class="alert alert-warning mg-b-0 mg-t-10 mg-b-10 pd-5"
              role="alert"
              v-else-if="
                salesDateOfDocumentOperationalValidationDiff <= 30 &&
                salesDateOfDocumentOperationalValidationDiff >= 0
              "
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">x</span>
              </button>
              <strong>Procuração do Cliente: </strong>
              <span>
                Faltam {{ salesDateOfDocumentOperationalValidationDiff }} dias
                para vencer a procuração do cliente.
              </span>
            </div>
            <div
              class="alert alert-danger mg-b-0 mg-t-10 mg-b-10 pd-5"
              role="alert"
              v-else-if="salesDateOfDocumentOperationalValidationDiff < 0"
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <strong>Procuração do Cliente: </strong>
              <span>
                O cliente deste processo está com a procuração vencida fazem
                {{ salesDateOfDocumentOperationalValidationDiff }} dias. A
                procuração venceu no dia
                {{
                  this.process.customer.salesDateOfDocumentOperationalValidation
                    | moment("L")
                }}.
              </span>
            </div>
          </transition>

          <transition name="fade" v-if="radarAlert">
            <div
              class="alert alert-danger mg-b-0 mg-t-10 mg-b-10 pd-5"
              role="alert"
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <strong>Verificar Radar: </strong> Este cliente registrou uma DI
              há mais de 9 meses. Por favor, verifique a situação do Radar.
            </div>
          </transition>

          <transition
            name="fade"
            v-if="
              process.customer &&
              radarInfo !== null &&
              !radarInfo.checked &&
              !radarInfo.radarModalityIsNotDefined
            "
          >
            <div
              class="alert alert-danger mg-b-0 mg-t-10 mg-b-10 pd-5"
              role="alert"
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <strong>Radar: </strong> {{ process.customer.name }} tem radar na
              modalidade {{ radarInfo.radarModality.modality }} e possui um
              saldo no valor de USD {{ radarInfo.balance | currency }}.
            </div>
          </transition>

          <transition
            name="fade"
            v-if="invoiceInconsistence"
          >
            <div
              class="alert alert-danger mg-b-0 mg-t-10 mg-b-10 pd-5"
              role="alert"
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <strong>Invoice: </strong>
              O valor da invoice no processo não está de acordo com a soma
              total das parcelas cadastradas
            </div>
          </transition>

          <transition name="fade" v-if="!hasTFADocument">
            <div
              class="alert alert-solid alert-danger mg-b-0 mg-t-10 mg-b-10 pd-5"
              role="alert"
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              Já fazem
              <strong>
                {{
                  this.$moment(new Date()).diff(
                    this.$moment(process.datesArrivalDate),
                    "days"
                  )
                }}
                DIAS
              </strong>
              que a mercadoria chegou e <strong>NENHUM TFA</strong> foi enviado
              para este processo.
            </div>
          </transition>

          <transition name="fade">
            <div
              v-if="
                openedProcesses[model.id] &&
                openedProcesses[model.id].user !==
                  credentials.data.person.name &&
                StateNoClick
              "
              class="flex ai-c jc-sb fgap2"
            >
              <div
                class="alert alert-danger mg-b-0 mg-t-10 mg-b-10 pd-5 flex1"
                role="alert"
              >
                O usuário
                <strong>{{ openedProcesses[model.id].user }}</strong> está
                editando este processo no momento.
              </div>
              <button
                class="btn btn-oblong btn-danger btn-block wd-200"
                @click.prevent="requestProcessAccess"
              >
                <i class="fa fa-key"></i> SOLICITAR ACESSO
              </button>
            </div>
          </transition>

          <transition name="fade">
            <div v-if="availableToEdit" class="flex ai-c jc-sb fgap2">
              <div
                class="alert alert-info mg-b-0 mg-t-10 mg-b-10 pd-5 flex1"
                role="alert"
              >
                O usuário saiu do processo e está disponível para editar
              </div>
              <button
                class="btn btn-oblong btn-info btn-block wd-200"
                @click.prevent="editThisProcess"
              >
                <i class="fa fa-key"></i> EDITAR
              </button>
            </div>
          </transition>

          <ul class="nav nav-activity-profile mg-y-10">
            <!-- <li class="nav-item">
              <a href="#" class="nav-link ht-35-force" @click.prevent="synchronizeProcess()">
                <i class="icon fa fa-refresh tx-16 tx-teal"></i> Sincronizar
              </a>
            </li> -->
            <li class="nav-item">
              <a
                href="#"
                class="nav-link ht-35-force dropdown-toggle"
                data-toggle="dropdown"
              >
                <i class="fa fa-magic tx-teal tx-16 mg-r-5"></i> Ações
              </a>
              <div class="dropdown-menu wd-500 pd-0-force bd-x-0 bd-b-0">
                <ul class="list-group bd-t-1">
                  <li class="list-group-item">
                    <p class="mg-b-0" @click.prevent="notifyCustomBroker">
                      <i class="fa fa-envelope tx-teal mg-r-8"></i>
                      <span class="text-muted">
                        Notificar Abertura do Processo ao Despachante
                      </span>
                    </p>
                  </li>
                  <li class="list-group-item">
                    <p class="mg-b-0" @click.prevent="sendFollowUP">
                      <i class="fa fa-envelope tx-danger mg-r-8"></i>
                      <span class="text-muted">
                        Enviar Follow UP Analítico (Manual)
                      </span>
                    </p>
                  </li>
                  <li class="list-group-item">
                    <p
                      class="mg-b-0"
                      @click.prevent="showFollowUPLogsDrawer = true"
                    >
                      <i class="fa fa-eye tx-primary mg-r-8"></i>
                      <span class="text-muted">
                        Histórico dos Follow UPs Enviados
                      </span>
                    </p>
                  </li>

                  <li class="list-group-item">
                    <p
                      class="mg-b-0"
                      @click.prevent="showStatusLogsDrawer = true"
                    >
                      <i class="fa fa-eye tx-primary mg-r-8"></i>
                      <span class="text-muted">
                        Histórico dos Status do Processo
                      </span>
                    </p>
                  </li>
                  <li class="list-group-item">
                    <p
                      class="mg-b-0"
                      @click.prevent="showChangesLogsDrawer = true"
                    >
                      <i class="fa fa-binoculars tx-primary mg-r-8"></i>
                      <span class="text-muted"> Auditoria do Processo </span>
                    </p>
                  </li>
                  <li class="list-group-item">
                    <p
                      class="mg-b-0"
                      @click.prevent="generateDocumentInstructions"
                    >
                      <i class="fa fa-file tx-primary mg-r-8"></i>
                      <span class="text-muted"> Documento de Instruções </span>
                    </p>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item" @click.prevent="printDemonstrative">
              <a href="#" class="nav-link ht-35-force">
                <i class="icon ion-document tx-primary tx-16"></i> Demonstrativo
              </a>
            </li>
            <li class="nav-item" @click.prevent="printProcedure">
              <a href="#" class="nav-link ht-35-force">
                <i class="icon ion-clipboard tx-warning tx-16"></i> Requisitos
              </a>
            </li>
            <li class="nav-item" @click.prevent="modalContacts = true">
              <a href="#" class="nav-link ht-35-force">
                <i class="icon ion-person-stalker tx-info tx-16"></i> Contatos
              </a>
            </li>
            <li class="nav-item" @click.prevent="showReminderDrawer = true">
              <a href="#" class="nav-link ht-35-force notification-menu">
                <i class="fa fa-flag tx-16 mg-r-8"></i> Lembrete
                <span
                  class="badge mg-l-5"
                  v-if="model.reminder !== null && model.reminder !== ''"
                >
                  1
                </span>
              </a>
            </li>
            <li class="nav-item" @click.prevent="showReminderDrawer = true">
              <a href="#" class="nav-link ht-35-force notification-menu">
                <i class="icon ion-chatbubbles tx-16-force"></i> Observação
                <span
                  class="badge mg-l-5"
                  v-if="model.note !== null && model.note !== ''"
                >
                  1
                </span>
              </a>
            </li>
            <li class="nav-item" @click.prevent="showChecklistDrawer = true">
              <a href="#" class="nav-link ht-35-force">
                <i class="icon ion-checkmark tx-16"></i> Checklist
              </a>
            </li>
          </ul>
        </header>

        <div class="row">
          <div class="col-md-9 pd-r-0">
            <div class="card processes-container">
              <div class="card-header">
                <ul class="nav nav-tabs card-header-tabs">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      @click="tab = 'general'"
                      href="#general"
                      data-toggle="tab"
                    >
                      <i class="fa fa-file-text-o mg-r-2"></i> Geral
                    </a>
                  </li>

                  <li class="nav-item">
                    <a
                      class="nav-link"
                      href="#process-financial"
                      @click="tab = 'process-financial'"
                      data-toggle="tab"
                    >
                      <i class="fas fa-hand-holding-usd mg-r-2"></i>
                      Financeiro
                    </a>
                  </li>

                  <li class="nav-item">
                    <a
                      class="nav-link"
                      @click="tab = 'logistics'"
                      href="#logistics"
                      data-toggle="tab"
                    >
                      <i class="fa fa-truck mg-r-2"></i> Logística
                    </a>
                  </li>

                  <li class="nav-item">
                    <a
                      class="nav-link"
                      @click="tab = 'docs'"
                      href="#docs"
                      data-toggle="tab"
                    >
                      <i class="fa fa-copy mg-r-2"></i> Docs
                    </a>
                  </li>

                  <li class="nav-item">
                    <a
                      class="nav-link"
                      @click="tab = 'revenues'"
                      href="#revenues"
                      data-toggle="tab"
                    >
                      <i class="fa fa-money mg-r-2"></i> Faturamento
                    </a>
                  </li>

                  <li
                    class="nav-item"
                    v-if="model.stepInsuranceValueResponsability === 'PRIME'"
                  >
                    <a
                      class="nav-link"
                      @click="tab = 'insurance'"
                      href="#insurance"
                      data-toggle="tab"
                    >
                      <i class="fas fa-file-invoice-dollar mg-r-2"></i> Seguro
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      @click="tab = 'closure'"
                      href="#closure"
                      data-toggle="tab"
                    >
                      <i class="fa fa-file-alt mg-r-2"></i>
                      Encerramento
                    </a>
                  </li>

                  <li class="nav-item">
                    <a
                      class="nav-link"
                      @click="tab = 'tax'"
                      href="#tax"
                      data-toggle="tab"
                    >
                      <i class="fas fa-bank mg-r-2"></i> Tributação
                    </a>
                  </li>

                  <li class="nav-item">
                    <a
                      class="nav-link"
                      @click="tab = 'inconsistencies'"
                      href="#inconsistencies"
                      data-toggle="tab"
                    >
                      <i class="fa fa-low-vision mg-r-2"></i>
                      <span>Inconsistências</span>
                      <span
                        class="tab-badge bg-danger tx-white mg-l-5 tx-10 pd-4"
                        v-if="this.inconsistencies.length > 0"
                      >
                        {{ this.inconsistencies.length }}
                      </span>
                    </a>
                  </li>

                  <li class="nav-item">
                    <a
                      class="nav-link"
                      @click="tab = 'conquests'"
                      href="#conquests"
                      data-toggle="tab"
                    >
                      <i class="fa fa-medal mg-r-2"></i> Conquistas
                    </a>
                  </li>

                  <li class="nav-item" v-if="thereIsIntegration">
                    <a
                      class="nav-link"
                      @click="tab = 'integration'"
                      href="#integration"
                      data-toggle="tab"
                    >
                      <i class="fa fa-exchange mg-r-2"></i> Integração
                    </a>
                  </li>
                </ul>
              </div>

              <!-- card-header -->
              <div class="card-body">
                <div class="tab-content ht-100p">
                  <div
                    class="tab-pane"
                    :class="{
                      active: tab === 'general',
                      'no-click': StateNoClick,
                    }"
                    id="general"
                  >
                    <imp-form-general
                      v-if="showTab && tab === 'general'"
                      :key="`imp-form-general-${model.id}`"
                    />
                  </div>

                  <div
                    class="tab-pane"
                    id="lis"
                    :class="{ active: tab === 'lis', 'no-click': StateNoClick }"
                  >
                    <imp-form-lis
                      v-if="tab === 'lis'"
                      :key="`imp-form-lis-${model.id}`"
                    />
                  </div>

                  <div
                    class="tab-pane"
                    id="logistics"
                    :class="{
                      active: tab === 'logistics',
                      'no-click': StateNoClick,
                    }"
                  >
                    <imp-form-logistics
                      v-if="tab === 'logistics'"
                      :model="model"
                      :key="`imp-form-logistics-${model.id}`"
                    />
                  </div>

                  <div
                    class="tab-pane"
                    id="cc"
                    :class="{ active: tab === 'cc', 'no-click': StateNoClick }"
                  >
                    <imp-form-cc
                      v-if="tab === 'cc'"
                      :key="`imp-form-cc-${model.id}`"
                    />
                  </div>

                  <div
                    class="tab-pane"
                    :class="{
                      active: tab === 'appointment',
                      'no-click': StateNoClick,
                    }"
                    id="appointment"
                  >
                    <imp-form-appointment
                      v-if="tab === 'appointment'"
                      :key="`imp-form-appointment-${model.id}`"
                    />
                  </div>

                  <div
                    class="tab-pane ht-md-900-force"
                    :class="{
                      active: tab === 'revenues',
                      'no-click': StateNoClick,
                    }"
                    id="revenues"
                  >
                    <imp-form-revenues
                      v-if="tab === 'revenues'"
                      :key="`imp-form-revenues-${model.id}`"
                    />
                  </div>

                  <div
                    class="tab-pane"
                    :class="{ active: tab === 'docs' }"
                    id="docs"
                  >
                    <imp-form-docs
                      v-if="tab === 'docs'"
                      :key="`imp-form-docs-${model.id}`"
                    />
                  </div>

                  <div
                    class="tab-pane ht-md-900-force"
                    :class="{ active: tab === 'inconsistencies' }"
                    id="inconsistencies"
                  >
                    <imp-form-inconsistencies
                      v-if="tab === 'inconsistencies'"
                      :key="`imp-form-inconsistencies-${model.id}`"
                      :inconsistencies="inconsistencies"
                    />
                  </div>

                  <div
                    class="tab-pane ht-100p"
                    :class="{
                      active: tab === 'integration',
                      'no-click': StateNoClick,
                    }"
                    id="integration"
                  >
                    <operational-integration
                      v-if="tab === 'integration'"
                      :key="`operational-integration-${model.id}`"
                    />
                  </div>

                  <div
                    class="tab-pane ht-100p"
                    :class="{
                      active: tab === 'insurance',
                      'no-click': StateNoClick,
                    }"
                    id="insurance"
                  >
                    <operational-insurance
                      v-if="tab === 'insurance'"
                      :key="`operational-insurance-${model.id}`"
                    />
                  </div>

                  <div
                    class="tab-pane ht-100p"
                    :class="{ active: tab === 'closure' }"
                    id="closure"
                  >
                    <operational-closure
                      v-if="tab === 'closure'"
                      :key="`operational-closure-${model.id}`"
                    />
                  </div>

                  <div
                    class="tab-pane ht-100p"
                    :class="{ active: tab === 'tax', 'no-click': StateNoClick }"
                    id="tax"
                  >
                    <operational-tax
                      v-if="tab === 'tax'"
                      :key="`operational-tax-${model.id}`"
                    />
                  </div>

                  <div
                    class="tab-pane ht-100p"
                    :class="{ active: tab === 'conquests' }"
                    id="conquests"
                  >
                    <Conquests v-if="tab === 'conquests'" :process="process" />
                  </div>

                  <div
                    class="tab-pane ht-100p"
                    :class="{ active: tab === 'process-financial' }"
                    id="process-financial"
                  >
                    <FormInvoicesVue
                      v-if="tab === 'process-financial'"
                      :process="process"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card">
              <div class="card-header">
                <ul class="nav nav-tabs card-header-tabs">
                  <li class="nav-item">
                    <a class="nav-link active" href="#datas" data-toggle="tab">
                      <i class="fa fa-calendar mg-r-2"></i> Datas
                    </a>
                  </li>
                  <li
                    class="nav-item"
                    v-if="
                      this.model !== undefined &&
                      this.model !== null &&
                      this.model.id !== null
                    "
                  >
                    <a class="nav-link" href="#historico" data-toggle="tab">
                      <i class="fa fa-history mg-r-2"></i> Histórico
                    </a>
                  </li>
                </ul>
              </div>

              <div class="card-body">
                <div class="tab-content">
                  <div
                    class="tab-pane active"
                    :class="{ 'no-click': StateNoClick }"
                    id="datas"
                  >
                    <imp-sidebar-dates />
                  </div>
                  <div
                    class="tab-pane"
                    id="historico"
                    v-if="
                      this.model !== undefined &&
                      this.model !== null &&
                      this.model.id !== null
                    "
                  >
                    <imp-sidebar-timeline />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>

    <Modal
      v-if="modalContacts"
      modalTitle="Contatos"
      @close="modalContacts = false"
      class="modal-width"
    >
      <imp-modal-contacts :no-click="StateNoClick" />
    </Modal>

    <Drawer v-if="showChecklistDrawer" @close="showChecklistDrawer = false">
      <template slot="header">Checklist</template>

      <template slot="body">
        <TodoList :no-click="StateNoClick" />
      </template>

      <template slot="footer">
        <button
          type="button"
          @click="showChecklistDrawer = false"
          class="btn btn-primary"
        >
          FECHAR
        </button>
      </template>
    </Drawer>

    <Drawer v-if="showReminderDrawer" @close="showReminderDrawer = false">
      <template slot="header">Observações e Lembrete</template>

      <template slot="body">
        <div
          class="card card-quick-post bd-0"
          :class="{ 'no-click': StateNoClick }"
        >
          <h6 class="slim-card-title">Digite suas observações</h6>
          <div class="form-group">
            <textarea
              v-model="model.note"
              class="form-control"
              rows="3"
              placeholder="Quais são as observações que você acha importante serem digitadas?"
            ></textarea>
          </div>

          <div class="card-footer">
            <button class="btn btn-purple" @click="saveNote">Salvar</button>
            <nav>
              <label class="ckbox mg-b-0" for="saveNoteAddHistory">
                <input
                  id="saveNoteAddHistory"
                  type="checkbox"
                  v-model="saveNoteAddHistory"
                  value="true"
                />
                <span class="pd-r-0-force">Adicionar no histórico</span>
              </label>
            </nav>
          </div>
        </div>

        <br />

        <hr />

        <div
          class="card card-quick-post bd-0"
          :class="{ 'no-click': StateNoClick }"
        >
          <h6 class="slim-card-title">Lembrete</h6>
          <div class="form-group">
            <textarea
              class="form-control"
              rows="3"
              placeholder="Digite o que deve ser lembrado."
              v-model="model.reminder"
            ></textarea>
          </div>

          <div class="card-footer">
            <button class="btn btn-teal" @click="saveReminder">Salvar</button>

            <nav>
              <label class="ckbox mg-b-0" for="saveReminderAddHistory">
                <input
                  id="saveReminderAddHistory"
                  type="checkbox"
                  v-model="saveReminderAddHistory"
                  value="true"
                />
                <span class="pd-r-0-force">Adicionar no histórico</span>
              </label>
            </nav>
          </div>
        </div>
      </template>

      <template slot="footer">
        <button
          type="button"
          @click="showReminderDrawer = false"
          class="btn btn-primary"
        >
          Fechar
        </button>
      </template>
    </Drawer>
    <imp-modal-procedure
      v-if="modalProcedure"
      :processUpdate="model"
      :proceduresUpdate="procedures"
      @close="closeModalProcedure"
      @handleStatusProcedure="handleStatusProcedure"
      :no-click="StateNoClick"
    />

    <FollowUPLogs
      v-if="showFollowUPLogsDrawer"
      @closeDrawer="showFollowUPLogsDrawer = false"
    />
    <ChangesLogs
      v-if="showChangesLogsDrawer"
      @closeDrawer="showChangesLogsDrawer = false"
    />
    <StatusLogs
      v-if="showStatusLogsDrawer"
      @closeDrawer="showStatusLogsDrawer = false"
    />

    <vue-html2pdf
      v-if="
        model.customer &&
        model.customer !== null &&
        model.account &&
        model.account !== null &&
        model.id !== undefined &&
        model.id !== null
      "
      :show-layout="false"
      :float-layout="true"
      :preview-modal="true"
      :enable-download="false"
      :paginate-elements-by-height="1400"
      filename="Demonstrativo"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <section id="report-box" slot="pdf-content">
        <div class="report-box">
          <table cellpadding="0" cellspacing="0">
            <tr class="top">
              <td colspan="2">
                <table class="header">
                  <tr>
                    <td class="header-left">
                      <div>
                        <span
                          style="
                            line-height: 30px;
                            font-size: 11px;
                            text-transform: uppercase;
                            background: #343a40;
                            color: #ffffff;
                            padding: 5px;
                          "
                        >
                          Processo de Importação
                        </span>
                        <br />
                        <span
                          style="
                            line-height: 45px;
                            font-size: 40px;
                            font-weight: 400;
                          "
                        >
                          {{ getTypeOfProcessReference }}
                        </span>
                        <br />
                      </div>
                    </td>
                    <td class="header-middle" v-if="model.account !== null">
                      <strong>
                        {{
                          model.account.completeName !== null
                            ? model.account.completeName
                            : ""
                        }}
                      </strong>
                      <br />
                      CNPJ:
                      {{
                        model.account.federalID ? model.account.federalID : ""
                      }}
                      <br />
                      Inscrição Municipal:
                      {{
                        model.account.municipalRegistration
                          ? model.account.municipalRegistration
                          : ""
                      }}
                      / IE:
                      {{
                        model.account.stateRegistration
                          ? model.account.stateRegistration
                          : ""
                      }}
                      <br />
                      Matriz:
                      {{ model.account.city ? model.account.city : "" }} /
                      {{
                        model.account.federalUnity
                          ? model.account.federalUnity
                          : ""
                      }}
                      <br />
                    </td>
                    <td class="header-right" align="right" valign="middle">
                      <img
                        v-if="
                          model.account.id === 3 &&
                          model.account.federalID === '49744122000181'
                        "
                        src="@/assets/img/sow-logo-azul-naval.png"
                        width="190"
                      />
                      <img
                        v-else
                        src="@/assets/img/logo_prime.png"
                        style="width: 175px"
                      />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td colspan="2">
                <br />
                <h1 class="slim-pagetitle">
                  CAPA DO PROCESSO DE IMPORTAÇÃO #{{
                    model.identification ? model.identification : ""
                  }}
                </h1>
              </td>
            </tr>

            <tr class="information">
              <td colspan="2">
                <table>
                  <tr class="info-row">
                    <td class="label">NUM / REF CLIENTE</td>
                    <td class="value">
                      {{ model.customerRef !== null ? model.customerRef : "" }}
                    </td>
                    <td class="label">NUM / REF INOVA</td>
                    <td class="value">
                      {{
                        model.integrationIdentification !== null
                          ? model.integrationIdentification
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr class="info-row" v-if="model.customer !== null">
                    <td class="label">CLIENTE</td>
                    <td class="value" colspan="3">
                      {{
                        model.customer.completeName !== null
                          ? model.customer.completeName
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr class="info-row" v-if="model.exporter !== null">
                    <td class="label">EXPORTADOR</td>
                    <td class="value" colspan="3">
                      {{
                        model.exporter.name !== null ? model.exporter.name : ""
                      }}
                    </td>
                  </tr>
                  <tr class="info-row" v-if="model.wayOfTransport !== null">
                    <td class="label">TIPO DE FRETE</td>
                    <td class="value">
                      {{
                        model.wayOfTransport !== null
                          ? model.wayOfTransport
                          : ""
                      }}
                    </td>
                    <td class="label">CONHECIMENTO</td>
                    <td class="value">
                      {{
                        model.wayOfTransport !== null
                          ? model.wayOfTransport
                          : ""
                      }}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td style="width: 50%">
                <br />
                <h1 class="slim-pagetitle">FATURAMENTO</h1>
              </td>
              <td style="width: 50%">
                <br />
                <h1 class="slim-pagetitle">CHECKLIST</h1>
              </td>
            </tr>

            <tr class="information">
              <td valign="top">
                <table>
                  <tr class="info-row">
                    <td class="label" style="width: 150px">TIPO DE APURAÇÃO</td>
                    <td class="value">
                    <template v-if="model.customer.taxRegime == 'LUCRO_PRESUMIDO'">
                      Lucro Presumido
                    </template>
                    <template v-if="model.customer.taxRegime == 'LUCRO_REAL'">
                      Lucro Real
                    </template>
                    <template v-if="model.customer.taxRegime == 'SIMPLES'">
                      Simples
                    </template>
                    </td>
                  </tr>
                  <tr class="info-row">
                    <td class="label">DESCONTO (%)</td>
                    <td class="value">
                      {{ model.customer.discount| numeralFormat("0,0.00") }}
                    </td>
                  </tr>
                  <tr class="info-row">
                    <td class="label">COMISSÃO (%)</td>
                    <td class="value">
                      {{ model.customer.comissionPercentual | numeralFormat("0,0.00") }}
                    </td>
                  </tr>
                  <tr class="info-row">
                    <td class="label">MULTI COMISSÕES?</td>
                    <td class="value">
                      {{ model.customer.hasMultipleComissions !== null ? 'Sim' : 'Não' }}
                    </td>
                  </tr>
                  <!-- <tr class="info-row">
                    <td class="label">SISCOSERV</td>
                    <td class="value">
                      {{
                        model.customer.siscoserv !== null
                          ? model.customer.siscoserv
                          : ""
                      }}
                    </td>
                  </tr> -->
                  <tr class="info-row">
                    <td class="label">FATURAMENTO</td>
                    <td class="value">
                      {{
                        model.customer.billingObservation !== null
                          ? model.customer.billingObservation
                          : ""
                      }}
                    </td>
                  </tr>
                </table>
              </td>
              <td>
                <table>
                  <tr class="info-row-simple">
                    <td class="check"></td>
                    <td class="value">Nota Fiscal</td>
                  </tr>
                  <tr class="info-row-simple">
                    <td class="check"></td>
                    <td class="value pd-lg-l-5"> Nota Fiscal de Serviço</td>
                  </tr>
                  <tr class="info-row-simple">
                    <td class="check"></td>
                    <td class="value pd-lg-l-5"> RIC / Demurrage</td>
                  </tr>
                  <tr class="info-row-simple">
                    <td class="check"></td>
                    <td class="value pd-lg-l-5"> Boleto / Devolução</td>
                  </tr>
                  <tr class="info-row-simple">
                    <td class="check"></td>
                    <td class="value pd-lg-l-5"> Encerramento Email</td>
                  </tr>
                  <tr class="info-row-simple">
                    <td class="check"></td>
                    <td class="value pd-lg-l-5"> Encerramento Doc. Físicos</td>
                  </tr>
                  <tr class="info-row-simple">
                    <td class="check"></td>
                    <td class="value pd-lg-l-5"> Atualizar Sistemas - Gescomex / Spalla </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td colspan="2">
                <br />
                <h1 class="slim-pagetitle">OBSERVAÇÕES</h1>
              </td>
            </tr>

            <tr>
              <td colspan="2">
                <br />
                <hr />
                <br />
                <hr />
                <br />
                <hr />
                <br />
                <hr />
              </td>
            </tr>
          </table>
          <div class="footer">
            <small> RQ 108 </small>
            <br />
            <small>REV 03 - 08/2018 </small>
            <br />
          </div>
        </div>
      </section>
    </vue-html2pdf>
    <documents-instructions
      v-if="model.customer"
      :customerDetails="model.customer"
      :handler="isGenerateDocumentInstruction"
      @beforeDownload="isGenerateDocumentInstruction = false"
      :language="documentInstructionLanguage"
    ></documents-instructions>
  </div>
</template>

<script>
import Axios from "axios";
import config from "@/config.js";
import VueHtml2pdf from "vue-html2pdf";
import { EventBus } from "@/events/EventBus.js";
import { mapActions, mapState } from "vuex";
import Drawer from "@/components/Drawer.vue";
import Modal from "@/components/Modal.vue";

import FormGeneral from "@/views/operational/imp/Form.General.vue";
import FormLIs from "@/views/operational/imp/Form.LIs.vue";
import FormCC from "@/views/operational/imp/Form.CC.vue";
import FormAppointment from "@/views/operational/imp/Form.Appointment.vue";
import FormRevenues from "@/views/operational/imp/Form.Revenues.vue";
import FormDocs from "@/views/operational/imp/Form.Docs.vue";
import FormInconsistencies from "@/views/operational/imp/Form.Inconsistencies.vue";

import FormLogistics from "@/views/operational/imp/Form/Logistics";

import FormSidebarDates from "@/views/operational/imp/Form.Sidebar.Dates.vue";
import FormSidebarTimeline from "@/views/operational/imp/Form.Sidebar.Timeline.vue";
import OperationalIntegration from "@/views/operational/integration/Operational.Integration.vue";
import FormModalContacts from "@/views/operational/imp/Form.Modal.Contacts.vue";
import FollowUPLogs from "@/views/operational/imp/drawers/FollowUPLogs.vue";
import ChangesLogs from "@/views/operational/imp/drawers/ChangesLogs.vue";
import StatusLogs from "@/views/operational/imp/drawers/StatusLogs.vue";

import ModalProcedure from "@/views/operational/imp/printable/Form/Procedures/Modal";
import Closure from "@/views/operational/imp/closure";
import Tax from "@/views/operational/imp/tax";
import Insurance from "@/views/operational/imp/insurance/InsuranceIndex.vue";
import Conquests from "@/views/operational/imp/conquests/Index.vue";
import TodoList from "@/views/utils/Todo.List.vue";
import Swal from "sweetalert2";
import * as labelsProcesses from "@/labels/labels.processes.js";
import moment from "moment";

import CredentialService from "@/services/CredentialService.js";
import DocumentsInstructions from "@/components/crm/customer-details-assets/DocumentsInstructions.vue";
import ProcessService from "@/services/ProcessService.js";
import FormInvoicesVue from "./Form.Invoices.vue";

export default {
  name: "operational-importation-form",
  components: {
    TodoList,
    Modal,
    Drawer,
    "imp-form-general": FormGeneral,
    "imp-form-lis": FormLIs,
    "imp-form-logistics": FormLogistics,
    "imp-form-cc": FormCC,
    "imp-form-appointment": FormAppointment,
    "imp-form-revenues": FormRevenues,
    "imp-form-docs": FormDocs,
    "imp-form-inconsistencies": FormInconsistencies,
    "imp-sidebar-dates": FormSidebarDates,
    "imp-sidebar-timeline": FormSidebarTimeline,
    "imp-modal-contacts": FormModalContacts,
    "operational-integration": OperationalIntegration,
    "operational-closure": Closure,
    "operational-tax": Tax,
    "operational-insurance": Insurance,
    "imp-modal-procedure": ModalProcedure,
    ChangesLogs,
    FollowUPLogs,
    StatusLogs,
    Conquests,
    VueHtml2pdf,
    DocumentsInstructions,
    FormInvoicesVue,
  },
  data() {
    return {
      documentInstructionLanguage: "pt-BR",
      isGenerateDocumentInstruction: false,
      tab: "general",
      radarAlert: false,
      showTab: false,
      modalContacts: false,
      modalProcedure: false,
      showProcessList: false,
      showReminderDrawer: false,
      showChecklistDrawer: false,
      showFollowUPLogsDrawer: false,
      showChangesLogsDrawer: false,
      showStatusLogsDrawer: false,
      searchProcess: "",
      saveReminderAddHistory: false,
      saveNoteAddHistory: false,
      model: {},
      initialModel: {},
      procedures: [],
      labels: {
        ...labelsProcesses.labels[0],
      },
      noClickMessage: null,
      isEditing: false,
      setInterval,
      inconsistencies: [],
      availableToEdit: null,
    };
  },
  computed: {
    ...mapState("ProcessStore", [
      "hasTFADocument",
      "processes",
      "documents",
      "steps",
      "coins",
      "radarInfo",
      "canConclude",
    ]),
    ...mapState({
      credentials: (state) => state.credentials,
      process: (state) => state.ProcessStore.activeProcess,
      StateNoClick: (state) => state.ProcessStore.noClick,
      openedProcesses: (state) => state.ProcessStore.openedProcesses,
      socket: (state) => state.ProcessStore.socket,
    }),
    getTypeOfProcessReference() {
      return ProcessService.getTypeOfProcessReference(this.process);
    },
    processesToList() {
      if (this.searchProcess != "") {
        return this.processes.filter(
          (item) =>
            item.identification.toString().search(this.searchProcess) > -1
        );
      } else {
        return this.processes;
      }
    },
    thereIsIntegration() {
      if (
        this.model !== undefined &&
        this.model !== null &&
        this.model.customer !== null &&
        this.model.customer !== undefined
      ) {
        if (
          config.env.FEDERALID_INTEGRATIONS.includes(
            this.model.customer.federalID.replace(/[^\w\s]/gi, "")
          )
        ) {
          return true;
        }
      }

      return false;
    },
    salesDateOfDocumentOperationalValidationDiff() {
      if (
        this.process !== null &&
        this.process.id !== null &&
        this.process.customer !== undefined &&
        this.process.customer !== null &&
        this.process.customer.salesDateOfDocumentOperationalValidation !== null
      ) {
        return moment(
          this.process.customer.salesDateOfDocumentOperationalValidation
        ).diff(moment(new Date()), "days");
      } else {
        return null;
      }
    },
    invoiceInconsistence() {
      let installmentsTotal = 0;

      if (this.model.invoices && this.model.invoices.length > 0) {
        this.model.invoices.forEach((invoice) => {
          installmentsTotal += invoice.installments.reduce((cur, acc) => cur += acc.value, 0);
        });
      }

      installmentsTotal = Math.round(parseFloat(installmentsTotal) * 100) / 100;

      if (this.model.invoiceTotal != installmentsTotal) {
        return true;
      }

      return false;
    }
  },
  methods: {
    ...mapActions("ProcessStore", [
      "enableNoClick",
      "getProcessHasTFADocument",
      "setCanConclude",
    ]),
    requestProcessAccess() {
      this.socket.send(
        JSON.stringify({
          action: config.env.WEBSOCKET.actions.REQUEST_PROC,
          data: [this.process.id, this.credentials.data.person.name],
        })
      );

      Swal.fire({
        title: "Solicitação de acesso ao Processo",
        html: "Sua solicitação foi enviada ao usuário",
        type: "info",
      });
    },
    editThisProcess() {
      this.socket.send(
        JSON.stringify({
          action: config.env.WEBSOCKET.actions.OPEN_PROC,
          data: {
            [this.process.id]: this.credentials.data.person.name,
          },
        })
      );

      this.availableToEdit = null;
      this.$store.commit("ProcessStore/SET_NOCLICK", false);
    },
    getTypeOfProcessReferenceByProcess(process) {
      return ProcessService.getTypeOfProcessReference(process);
    },
    generateDocumentInstructions() {
      Swal.fire({
        title: "Documento de Instruções",
        html: "Selecione o idioma em que deseja visualizar o documento",
        type: "info",
        input: "select",
        inputOptions: {
          "pt-BR": "Português",
          en: "Inglês",
        },
        inputPlaceholder: "Selecione",
        inputValidator: (value) => {
          this.documentInstructionLanguage = value;
          this.isGenerateDocumentInstruction = true;
        },
      });
    },
    hasPrivilege(privilege) {
      return CredentialService.hasPrivilege(privilege);
    },
    synchronizeProcess() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Sincronizar o Processo de Importação",
        description:
          "Aguarde enquanto o Processo de Importação está sendo sincronizado ...",
      });

      // var identification = this.process.anotherRef;
      var identification = this.process.identification;

      Axios.get(
        config.env.API_GESCOMEX_INTEGRATOR.host +
          "/api/aberta/dis/" +
          identification
      )
        .then((response1) => {
          Axios.post(
            config.env.API_DEFAULT.host +
              "/process/import/synchronize/aberta/" +
              this.process.id,
            response1.data.data
          )
            .then((response2) => {
              if (response2.data.code === "SUCCESS") {
                this.$store.commit("setLoading", {
                  show: false,
                });
                this.$store.commit(
                  "ProcessStore/SET_ACTIVE",
                  response2.data.data
                );

                Swal.fire(
                  "Sincronizar Processo de Importação",
                  response2.data.message,
                  "success"
                );
              } else {
                this.$store.commit("setLoading", {
                  show: false,
                });
                Swal.fire(
                  "Sincronizar Processo de Importação",
                  response2.data.message,
                  "error"
                );
              }
            })
            .catch((error) => {
              this.$store.commit("setLoading", {
                show: false,
              });
              if (error.response.data !== undefined) {
                Swal.fire(
                  "Sincronizar Processo de Importação",
                  error.response.data.message,
                  "error"
                );
              } else {
                Swal.fire("Sincronizar Processo de Importação", error, "error");
              }
            });
        })
        .catch((error) => {
          this.$store.commit("setLoading", {
            show: false,
          });
          if (error.response.data !== undefined) {
            Swal.fire(
              "Sincronizar Processo de Importação",
              error.response.data.message,
              "error"
            );
          } else {
            Swal.fire("Sincronizar Processo de Importação", error, "error");
          }
        });
    },
    printDemonstrative() {
      this.$refs.html2Pdf.generatePdf();
    },

    enableToEdit() {
      Swal.fire({
        title: "Editar Processo",
        html: `<p>Por favor, digite abaixo o motivo pelo qual você está liberando este Processo de Importação para ser editado após conclusão:</p>`,
        type: "warning",
        input: "textarea",
        showConfirmButton: true,
        confirmButtonText: "Liberar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (
          result.value !== undefined &&
          result.value !== null &&
          result.value !== "" &&
          result.value.trim().length > 5
        ) {
          const response = Axios.post(
            config.env.API_DEFAULT.host +
              "/history/process/" +
              this.model.id +
              "/save",
            {
              processType: "IMPORT",
              isVisibleToClient: false,
              priority: "NORMAL",
              description: "Edição de Processo Concluído: " + result.value,
            }
          );
          if (response) {
            this.enableNoClick(false);
            Swal.fire(
              "Processo Liberado",
              "Este processo está liberado para alterações.",
              "success"
            );
          } else {
            Swal.fire(
              "Editar Processo",
              "Não foi possível liberar este processo para edição. Por favor, tente novamente!",
              "error"
            );
          }
        } else if (
          result.value === undefined ||
          result.value !== null ||
          result.value !== ""
        ) {
          Swal.fire(
            "Motivo Vazio",
            "Você precisa digitar um motivo para habilitar a edição deste processo!",
            "error"
          );
        }
      });
    },

    printProcedure() {
      this.modalProcedure = true;
      this.importProcedure();
    },

    importProcedure() {
      if (this.process !== undefined) {
        this.$store.commit("setLoading", {
          show: true,
          label: `Requisitos`,
          description: "Carregando os procedimentos da empresa.",
        });

        Axios.get(
          config.env.API_DEFAULT.host +
            "/process/import/checkOtherCustomerRequirements/" +
            this.process.id
        ).then((result) => {
          if (result.data.data) {
            Swal.fire({
              type: "warning",
              title: "Requisitos Desatualizados",
              html: "Este processo tem <strong>requisitos de outro cliente</strong>, você deseja removê-los e importar os requisitos do cliente atual deste processo?<br /><br /> <small>Se você clicar em Sim, os requisitos do cliente anterior serão apagados do processo!</small>",
              showCancelButton: true,
              cancelButtonText: "Cancelar",
              confirmButtonText: "Sim",
            }).then((result) => {
              if (result.value) {
                this.$store.commit("setLoading", {
                  show: true,
                  label: `Requisitos`,
                  description: "Carregando os procedimentos da empresa.",
                });
                Axios.get(
                  config.env.API_DEFAULT.host +
                    "/process/import/deleteRequirementsFromAnotherCustomer/" +
                    this.process.id
                ).then((result) => {
                  this.modalProcedure = false;
                  this.$store.commit("setLoading", {
                    show: false,
                  });

                  Swal.fire(
                    "Requisitos Atualizados",
                    "Os requisitos do cliente anterior foram excluídos deste processo!",
                    "success"
                  );
                });
              }
            });
          }

          Axios.get(
            config.env.API_DEFAULT.host +
              "/process/import/create/procedures/" +
              this.process.id
          )
            .then((response) => {
              if (response.data.code === "SUCCESS") {
                this.categorizeProcedures(response.data.data);
              } else if (response.data.code === "ERROR") {
                this.$swal("Ops", response.data.message, "error");
                this.modalProcedure = false;
                this.procedures = [];
              }

              this.$store.commit("setLoading", {
                show: false,
                label: ``,
                description: "",
              });
            })
            .catch((e) => {
              this.$store.commit("setLoading", {
                show: false,
                label: ``,
                description: "",
              });
            });
        });
      } else {
        this.$store.commit("setLoading", {
          show: false,
          label: ``,
          description: "",
        });
      }
    },

    handleStatusProcedure(param) {
      if (this.process != undefined) {
        const index = this.process.proceduresItems.findIndex(
          (item) => item.id === param.id
        );
        if (index > -1) {
          this.$set(this.process.proceduresItems, index, {
            ...this.process.proceduresItems[index],
            ...param,
          });

          this.categorizeProcedures();
        }
      }
    },

    categorizeProcedures(param = false) {
      try {
        if (this.process !== undefined && this.process !== null) {
          let proceduresItems = [];
          if (param) {
            proceduresItems = [...param];
            this.process.proceduresItems = [...param];
          } else {
            proceduresItems = [...this.process.proceduresItems];
          }

          if (Array.isArray(proceduresItems) && proceduresItems.length > 0) {
            let items = {};

            items["todos"] = {
              id: "todos",
              description: "Todos",
              itens: this.process.proceduresItems,
              count: 0,
            };

            proceduresItems.forEach((item) => {
              if (items[item.type.value] === undefined) {
                items[item.type.value] = {
                  id: item.type.value,
                  description: item.type.description,
                  itens: [],
                  count: 0,
                };
              }

              items[item.type.value].itens.push(item);

              if (!item.checked) {
                items[item.type.value].count++;
                items["todos"].count++;
              }
            });

            this.procedures = items;
          }
        }
      } catch (err) {
        Swal.fire(
          "Requisitos do Processo",
          "Não foi possível abrir os requisitos: " + err,
          "error"
        );
      }
    },

    saveReminder() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Lembrete",
        description: "Salvando o lembrete do processo...",
      });

      try {
        Axios.patch(
          config.env.API_DEFAULT.host + "/importProcesses/" + this.process.id,
          {
            reminder: this.model.reminder,
          }
        ).then((result) => {
          if (this.saveReminderAddHistory) {
            Axios.post(
              config.env.API_DEFAULT.host +
                "/history/process/" +
                this.process.id +
                "/save",
              {
                processType: "IMPORT",
                isVisibleToClient: false,
                description: "Lembrete: " + this.model.reminder,
              }
            );

            this.saveReminderAddHistory = false;

            EventBus.$emit("Operational.imp.Form.Sidebar.Timeline.load");
          }
          this.$store.commit("setLoading", {
            show: false,
          });
          Swal.fire("Lembrete", "O lembrete foi salvo com sucesso.", "success");
        });

        this.$store.commit("ProcessStore/SET_ACTIVE", this.model);
      } catch (err) {
        this.$store.commit("setLoading", {
          show: false,
        });
        Swal.fire(
          "Lembrete",
          "Houve algum problema ao tentar salvar o lembrete.",
          "error"
        );
      }
    },

    saveNote() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Observações",
        description: "Salvando as observações do processo...",
      });

      try {
        Axios.patch(
          config.env.API_DEFAULT.host + "/importProcesses/" + this.process.id,
          {
            note: this.model.note,
          }
        ).then((result) => {
          if (this.saveNoteAddHistory) {
            Axios.post(
              config.env.API_DEFAULT.host +
                "/history/process/" +
                this.process.id +
                "/save",
              {
                processType: "IMPORT",
                isVisibleToClient: false,
                description: "Observação: " + this.model.note,
              }
            );

            this.saveNoteAddHistory = false;

            EventBus.$emit("Operational.imp.Form.Sidebar.Timeline.load");
          }
          this.$store.commit("setLoading", {
            show: false,
          });
          Swal.fire(
            "Observações",
            "As observações foram salvas com sucesso.",
            "success"
          );
        });

        this.$store.commit("ProcessStore/SET_ACTIVE", this.model);
      } catch (err) {
        this.$store.commit("setLoading", {
          show: false,
        });
        Swal.fire(
          "Observações",
          "Houve algum problema ao tentar salvar as observações.",
          "error"
        );
      }
    },

    clearProcess() {
      Swal.fire({
        title: "Fechar IMP Nº " + this.process.identification,
        text: "Você realmente deseja fechar o processo de importação?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, fechar!",
        cancelButtonText: "Não",
      }).then((result) => {
        if (result.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: "Fechando IMP Nº " + this.process.identification,
            description: "Salvando as informações do processo...",
          });

          this.$store.commit("ProcessStore/SET_NOCLICK", false);

          if (
            this.openedProcesses[this.process.id] &&
            this.openedProcesses[this.process.id].user ==
              this.credentials.data.person.name
          ) {
            this.socket.send(
              JSON.stringify({
                action: config.env.WEBSOCKET.actions.CLOSE_PROC,
                data: {
                  [this.process.id]: this.credentials.data.person.name,
                },
              })
            );
          }

          setTimeout(() => {
            this.$store.commit("ProcessStore/HIDE_PROCESS");
            this.$store.commit("setLoading", {
              show: false,
            });
          }, 500);
        }
      });
    },

    editProcess(process) {
      this.socket.send(
        JSON.stringify({
          action: config.env.WEBSOCKET.actions.CLOSE_PROC,
          data: {
            [this.process.id]: this.credentials.data.person.name,
          },
        })
      );

      if (!this.openedProcesses[process.id]) {
        this.socket.send(
          JSON.stringify({
            action: config.env.WEBSOCKET.actions.OPEN_PROC,
            data: {
              [this.process.id]: this.credentials.data.person.name,
            },
          })
        );
      }

      this.$store.commit("ProcessStore/HIDE_PROCESS");

      try {
        this.$store
          .dispatch("ProcessStore/findProcessById", process.id)
          .then((response) => {
            if (!response.success)
              Swal.fire(
                "Visualização de Processo",
                "Não foi possível abrir o processo " +
                  process.identification +
                  " por causa do seguinte erro: " +
                  response.error,
                "error"
              );
          });
      } catch (error) {
        Swal.fire(
          "Visualização de Processo",
          "Não foi possível abrir o processo " +
            this.data.identification +
            " por causa do seguinte erro: " +
            error,
          "error"
        );
      }

      EventBus.$emit("Operational.imp.Form.Docs.load");
      EventBus.$emit("Operational.imp.Form.Sidebar.Timeline.load");

      this.showTab = true;

      if (this.tab === null) {
        this.tab = "general";
      }
    },

    async sendFollowUP() {
      let self = this;
      Swal.fire({
        title: "Enviar Follow UP Analítico",
        text: "Você deseja enviar um Follow UP Analítico?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, enviar!",
        cancelButtonText: "Não",
      }).then((result) => {
        if (result.value) {
          self.$store.dispatch("ProcessStore/sendFollowUP", {
            id: self.process.id,
            identification: self.process.identification,
          });
        }
      });
    },

    async notifyCustomBroker() {
      let self = this;

      try {
        if (this.process.customBroker !== null) {
          const result = await Swal.fire({
            title: "Notificar Despachante",
            text:
              "Você deseja enviar o email de notificação de abertura do processo para " +
              self.process.customBroker.name +
              "?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, enviar!",
            cancelButtonText: "Não",
          });

          if (result) {
            if (result.value) {
              const response = await self.$store.dispatch(
                "ProcessStore/notifyCustomBroker",
                {
                  id: self.process.id,
                  identification: self.process.identification,
                }
              );

              if (response) {
                if (response.data.code === "SUCCESS") {
                  EventBus.$emit("Operational.imp.Form.Sidebar.Timeline.load");
                  Swal.fire(
                    "Notificação de Abertura de Novo Processo - " +
                      self.process.identification,
                    response.data.message,
                    "success"
                  );
                } else if (response.data.code === "ERROR") {
                  Swal.fire(
                    "Notificação de Abertura de Novo Processo - " +
                      self.process.identification,
                    response.data.message,
                    "error"
                  );
                } else {
                  Swal.fire(
                    "Notificação de Abertura de Novo Processo - " +
                      self.process.identification,
                    response.data.message,
                    "error"
                  );
                }
              }
            }
          }
        } else {
          Swal.fire(
            "Notificar Despachante",
            "Este processo ainda não tem um Despachante Aduaneiro cadastrado! Por favor, cadastre-o e tente novamente.",
            "error"
          );
        }
      } catch (error) {
        Swal.fire("Notificar Despachante", error, "error");
      }
    },

    async setStep(step) {
      var changeStep = true;

      if (!this.canConclude && (step.code === "10" || step.code === "13")) {
        Swal.fire(
          "Etapa Bloqueada - Processo sem Encerramento enviado",
          "Este processo não possui encerramento com email enviado.",
          "error"
        );

        changeStep = false;

        return;
      }

      if (
        !this.hasTFADocument &&
        (step.code === "9" || step.code === "10" || step.code === "13")
      ) {
        Swal.fire(
          "Etapa Bloqueada - Processo sem TFA",
          "Não será possível alterar este processo para as próximas etapas enquanto NENHUM TFA tiver sido anexado neste processo.",
          "error"
        );

        changeStep = false;
        return;
      }

      const exporterId = this.process.exporter.id;

      if (
        step.code === "8" &&
        step.alias === "Carregamento" &&
        (exporterId === 2033 ||
          exporterId === 449 ||
          exporterId === 768 ||
          exporterId === 2330)
      ) {
        await this.sendOutsourcingAlert();
      }

      if (
        this.process.step !== null &&
        this.process.step !== undefined &&
        step !== null &&
        this.process.step.position > step.position
      ) {
        var diff = this.process.step.position - step.position;

        changeStep = await Swal.fire({
          title: "Etapa Anterior",
          html:
            "Você realmente deseja voltar para uma etapa anterior?<br /><br />Se você alterar a etapa de <strong>" +
            this.process.step.description +
            "</strong> para <strong>" +
            step.description +
            "</strong><br />estará voltando " +
            diff +
            " etapa(s) no processo.",
          type: "error",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, alterar!",
          cancelButtonText: "Não",
        }).then((result) => {
          return result.value;
        });
      } else {
        changeStep = true;
      }
      
      //cancelar processo
      if(step.code === "12") {
        changeStep = await Swal.fire({
            title: "Cancelar Processo",
            html:"Você realmente deseja cancelar este processo?<br /><br /> Todas as <strong>Invoices</strong> cadastradas neste processo serão <strong>removidas</strong>.",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
        }).then((result) => {
          return result.value;
        });
      }

      if (changeStep) {
        const data = {
          diff: {
            step: step._links.self.href,
          },
          newModel: {
            ...this.process,
            step: {
              ...step,
            },
          },
        };

        if (
          step !== null &&
          step !== undefined &&
          step.requiredFields !== null &&
          step.requiredFields !== undefined
        ) {
          var fieldsNames = "<ul class='list-group list-group-striped tx-14'>";
          var requiredFields = JSON.parse(step.requiredFields);
          var totalFields = 0;

          requiredFields.filter((field) => {
            if (
              field === "typeOfBoarding" &&
              this.process.wayOfTransport !== "MARITIMA"
            ) {
              return;
            }

            var attributeValue = this.process[field];
            var attributeValueSolved = this.process[field + "Solved"];

            if (
              attributeValue !== undefined &&
              (attributeValue === null || attributeValue === "") &&
              this.labels[field] !== undefined
            ) {
              var addField = true;

              if (
                attributeValueSolved !== undefined &&
                attributeValueSolved === true
              ) {
                addField = false;
              }

              if (addField) {
                var attributeLabel = this.labels[field].label;
                fieldsNames +=
                  "<li class='list-group-item'>" +
                  "   <p class='mg-b-0'>" +
                  "       <i class='fa fa-check tx-danger mg-r-8'></i> " +
                  "       <span class='tx-inverse tx-normal'>" +
                  attributeLabel +
                  "       </span>";
                "   </p>" + "</li>";
                totalFields += 1;
              }
            }
          });

          fieldsNames += "</ul>";

          if (totalFields > 0) {
            var msg =
              "<p class='mg-b-5'>Para alterar a etapa, preencha os campos abaixo: </p>" +
              fieldsNames;
            msg = "<div class='tx-left'>" + msg + "</div>";

            Swal.fire("Campos Obrigatórios", msg, "error");

            return;
          } else {
            const previousStep = this.process.step;
            const currentStep = step;
            const values = {
              previousStep: previousStep,
              currentStep: currentStep,
            };

            Axios.post(
              config.env.API_DEFAULT.host +
                "/processStepHistory/save/" +
                this.process.id,
              values
            ).catch((err) => {
              Swal.fire(
                "Salvar Histório",
                "Não foi possível salvar o histórico, erro:" + err.message,
                "error"
              );
            });

            this.$store.dispatch("ProcessStore/patchImportProcess", data);

            try {
              const responseChangeState = await Axios.put(
                `${config.env.API_DEFAULT.host}/newImportProcess/${this.process.id}/step/${currentStep.id}`
              );

              // Altera os dados na integração
              await Axios.post(
                `${config.env.API_DEFAULT.host}/forwarders-integrations/save-process-by-gescomex/${responseChangeState.data.data.id}`
              );
            } catch (e) {
              console.log(e);

              Swal.fire(
                "Alteração de Etapa",
                "Houve um erro inesperado ao tentar alterar a etapa do Processo!",
                "error"
              );
            }
          }
        } else {
          Swal.fire(
            "Campos Obrigatórios",
            "A etapa <b>" +
              step.description +
              "</b> ainda não possue campos obrigatórios cadastrados. Por favor, informe ao suporte que os campos obrigtatórios desta etapa precisam ser caastrados.",
            "error"
          );
        }
      }
    },
    async sendOutsourcingAlert() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Alerta de Outsourcing",
        description: "Salvando informações e comunicando o financeiro...",
      });

      try {
        const response = await Axios.post(
          `${config.env.API_DEFAULT.host}/processes/${this.process.id}/send-outsourcing-alert`
        );

        this.$store.commit("setLoading", {
          show: false,
        });
      } catch (e) {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },

    closeModalProcedure() {
      this.modalProcedure = false;
    },

    loadDataInconsistencies() {
      if (this.process.id !== undefined) {
        this.$store.commit("setLoading", {
          show: true,
          label: `Inconsistências`,
          description: "Carregando as inconsistências do processo.",
        });

        try {
          Axios.get(
            config.env.API_DEFAULT.host +
              `/importProcesses/${this.process.id}/inconsistencies`
          ).then((response) => {
            this.inconsistencies =
              response.data._embedded.processInconsistencies;
            this.$store.commit("setLoading", {
              show: false,
              label: ``,
              description: "",
            });
          });
        } catch (err) {
          this.$store.commit("setLoading", {
            show: false,
            label: ``,
            description: "",
          });

          this.$swal("Ops", err, "error");
        }
      }
    },
    async checkIfCanConcludeProcess() {

      if(this.process.id !== null) {
        try {
        const response = await Axios.get(
          config.env.API_DEFAULT.host +
            `/newImportProcess/${this.process.id}/check-if-can-conclude`
        );

        this.setCanConclude(response.data.data);
        
      } catch (e) {
        console.log(e);

        Swal.fire(
          "Verificação de Conclusão",
          "Erro ao tentar verificar se é possível concluir o processo",
          "error"
        );
      }
      }
    },
  },
  watch: {
    openedProcesses: function (newVal) {
      if (!newVal[this.model.id]) {
        this.$store.commit("ProcessStore/SET_NOCLICK", false);
        this.availableToEdit = true;
      }
    },
    process: function (newValue, oldValue) {
      this.model = {
        ...this.process,
      };

      // this.enableNoClick(false);
      this.noClickMessage = null;

      this.oldValue = oldValue; // para websocket

      if (oldValue !== null && newValue.id !== oldValue.id) {
        EventBus.$emit("Operational.imp.Form.Docs.load");
        EventBus.$emit("Operational.imp.Form.Sidebar.Timeline.load");

        if (this.model.customer) {
          EventBus.$emit(
            "Operational.imp.Form.vProcessesImportDeclarationGreaterThan9Monthses",
            this.model.customer.id
          );
        }
      }
    },
  },
  beforeMount() {
    if (
      this.model !== null &&
      this.model.step !== null &&
      this.model.step.alias === "Concluído"
    ) {
      this.enableNoClick(true);
    }
  },
  beforeRouteLeave() {
    if (this.process && this.process.id) {
      this.socket.send(
        JSON.stringify({
          action: config.env.WEBSOCKET.actions.CLOSE_PROC,
          data: {
            [this.process.id]: this.credentials.data.person.name,
          },
        })
      );
    }
    this.$store.commit("ProcessStore/HIDE_PROCESS");
  },
  beforeDestroy() {
    if (this.process && this.process.id) {
      this.socket.send(
        JSON.stringify({
          action: config.env.WEBSOCKET.actions.CLOSE_PROC,
          data: {
            [this.process.id]: this.credentials.data.person.name,
          },
        })
      );
    }
    this.$store.commit("ProcessStore/HIDE_PROCESS");
  },
  created() {
    this.model = {
      ...this.process,
    };
    this.initialModel = {
      ...this.process,
    };

    EventBus.$on("Operational.imp.Form.Inconsistencies.load", () => {
      this.loadDataInconsistencies();
    });
  },
  async mounted() {
    this.$nextTick(() => {
      this.$store.dispatch("getAccounts");
      this.showProcessList = true;
      this.showTab = true;

      this.$store.dispatch("FinancesStore/getMovimentTerms", "CATEGORY");

      if (this.model.customer) {
        EventBus.$emit(
          "Operational.imp.Form.vProcessesImportDeclarationGreaterThan9Monthses",
          this.model.customer.id
        );
      }

      if (this.model !== null && this.model.id !== null) {
        this.loadDataInconsistencies();
        this.getProcessHasTFADocument(this.model.id);
      }
    });

    await this.checkIfCanConcludeProcess();

    // EventBus.$on(
    //   "Operational.imp.Form.vProcessesImportDeclarationGreaterThan9Monthses",
    //   (customerID) => {
    //     Axios.get(
    //       config.env.API_DEFAULT.host +
    //       "/customers/checkCustomerHasRadarAlert/" +
    //       customerID,
    //       {
    //         validateStatus: false,
    //       }
    //     ).then((res) => {
    //       // if (res.data._embedded.vProcessesImportDeclarationGreaterThan9Monthses.length == 0) {
    //       //     this.radarAlert = true;
    //       // } else {
    //       // }
    //       this.radarAlert = res.data.data;
    //     });
    //   }
    // );

    if (this.model.customer) {
      EventBus.$emit(
        "Operational.imp.Form.vProcessesImportDeclarationGreaterThan9Monthses",
        this.model.customer.id
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.process-form-content {
  position: fixed;
  top: 4.7rem ;
  left: 0;
  background: #fff;
  width: 100vw;
  height: calc(100vh - 54px);
  overflow: auto;
  border-top-color: #dee2e6;
  
  &.showHeader {
    bottom: 0;
    height: calc(100vh - 210px);
  }
}

.disabled-link {
  pointer-events: none;
  cursor: default;
  color: #777; /* Optional: Change the color to indicate it's disabled */
}

.formContent {
  background: #fff;
  height: calc(100% - 57px);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  overflow: auto;
  display: flex;

  ul.list-group {
    li.list-group-item:first-child {
      border-top: none;
    }
  }
}

.processesList {
  height: 100%;
  overflow: auto;

  &.col-md-1 {
    padding: 0;
  }

  &.gray-bg {
    background: #f0f2f7;
  }

  .list-group-item {
    cursor: pointer;
  }
}

.processListSearch {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin: 0;

  input {
    border: none;
    padding: 0;
    width: 100%;
  }
}

.bold {
  font-weight: bold;
  color: #000;
  background-color: #e9ecef;
}

.nav-pills {
  .nav-item {
    cursor: pointer;
    margin: 0;
    padding: 0;

    input[type="checkbox"] {
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 1px;
      opacity: 0;
    }

    .nav-link {
      border-radius: 0;
      background: #fff;
      border: 1px solid #ddd;
      border-right: none;
      padding: 6px 12px;

      &:hover {
        color: #343a40;
        background-color: transparent;
      }

      &.active {
        background-color: #1b84e7;
        border: 1px solid #106dc4;
        border-right: none;
        color: #fff;
      }
    }

    &:last-child {
      .nav-link {
        border-right: 1px solid #ddd;

        &.active {
          border-right: 1px solid #106dc4;
        }
      }
    }

    &.active {
      .nav-link {
        background-color: #e9ecef;
      }
    }
  }
}

.toggle {
  display: inline-block;
  margin-left: 30px;

  span {
    padding: 6px 0;
  }

  label {
    margin: -1px 0 0 5px;
  }
}

.process-form-content .modal-width .modal-dialog {
  width: 700px !important;
  max-width: initial;
}

.no-click {
  pointer-events: none;
}

.tab-badge {
  display: inline-block;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  border-radius: 20%;
}

.report-box {
  max-width: 800px;
  padding: 5px;
  margin: 20px;
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #343a40;
}

.report-box table {
  width: 100%;
}

#report-box .slim-pagetitle {
  margin-top: 15px;
  margin-bottom: 10px;
  color: #343a40;
  padding-left: 10px;
  border-left: 4px solid #4662d4;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.5px;
  border-bottom: 1px solid #343a40;
  padding: 5px;
  margin-left: 10px;
}

#report-box .box {
  max-width: 800px;
  padding: 5px;
  margin: 20px;
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #343a40;
}

#report-box .box table {
  width: 100%;
}

#report-box hr {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
}

#report-box .header {
  font-size: 12px;
}

#report-box .header-left {
  text-align: center;
  border-left: 10px solid #4662d4;
}

#report-box .header-middle {
  padding-left: 20px;
  text-align: left !important;
  line-height: 20px;
}

#report-box .header-right {
  vertical-align: middle;
  line-height: 10px;
}

#report-box .header-right small {
  font-size: 10px;
}

#report-box .info-row,
#report-box .info-row-simple {
  font-size: 14px;
}

#report-box .information table {
  margin: 10px;
}

#report-box .info-row td {
  border-bottom: 1px dashed #dee2e6;
}

#report-box .info-row td.label {
  text-align: left;
  font-weight: bold;
}

#report-box .info-row td.value {
  text-align: left;
  /* color: #868ba1; */
}

#report-box .info-row-simple td.check {
  border: 1px solid;
  /* color: #868ba1; */
  width: 18px;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 10px;
  width: 100%;
  text-align: center;
  line-height: 8px;
}

.footer small {
  font-size: 9px;
}
</style>