<template>
  <form class="form-layout form-layout-2 form-dates-sidebar" id="formTax">
    <div class="report-summary-header mg-b-5 mg-t-0">
      <div>
        <h4 class="tx-inverse mg-b-3 d-block">Tributação</h4>
        <p class="mg-b-0">
          <i class="icon ion-information-circled mg-r-3"></i> Informações de
          tributos e impostos do Processo de Importação.
        </p>
      </div>
      <div></div>
    </div>
    <div>
      <div class="row no-gutters">
        <div class="form-group col-3">
          <label class="form-control-label"> Recolhimento ICMS </label>
          <div class="input-group-solved">
            <div class="input-group-solved-before">%</div>
            <div class="input-group-solved-center">
              <input
                name="retreatIcms"
                type="number"
                v-model="model.retreatIcms"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <!-- <div class="form-group col-3 bd-l-0-force">
          <label class="form-control-label"> Recolhimento ICMS </label>
          <div class="input-group-solved">
            <div class="input-group-solved-before">
              %
            </div>
            <div class="input-group-solved-center">
              <input name="icms" type="number" class="form-control" value="2.5">
            </div>
          </div>
        </div>
        <div class="form-group col-3 bd-l-0-force">
          <label class="form-control-label"> Recolhimento ICMS </label>
          <div class="input-group-solved">
            <div class="input-group-solved-before">
              %
            </div>
            <div class="input-group-solved-center">
              <input name="icms" type="number" class="form-control" value="2.5">
            </div>
          </div>
        </div>
        <div class="form-group col-3 bd-l-0-force">
          <label class="form-control-label"> Recolhimento ICMS </label>
          <div class="input-group-solved">
            <div class="input-group-solved-before">
              %
            </div>
            <div class="input-group-solved-center">
              <input name="icms" type="number" class="form-control" value="2.5">
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </form>
</template>
<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "OperationalTax",
  data() {
    return {
      model: {},
    };
  },
  computed: {
    ...mapState({
      process: (state) => state.ProcessStore.activeProcess,
    }),
    options() {
      return [
        "blur",
        (e) => {
          setTimeout(() => {
            this.diffObjects();
          }, 200);
        },
        true,
      ];
    },
  },
  watch: {
    process() {
      this.model = { ...this.process };
    },
  },
  methods: {
    async diffObjects() {
      const response = await this.$store.dispatch("ProcessStore/diffObjects", {
        initialModel: this.initialModel,
        model: this.model,
      });

      // Avisar o financeiro caso o recolhimento for de 2.5
      if (
        this.model.retreatIcms !== this.initialModel.retreatIcms &&
        this.model.retreatIcms == 2.5
      ) {
        try {
          const alertRetreatIcmsResponse = await axios.post("");
        } catch (e) {}
      }

      if (response) this.initialModel = { ...this.model };
    },
  },
  mounted() {
    if (window.addEventListener) {
      setTimeout(() => {
        const form = document.getElementById("formTax");
        form.addEventListener(...this.options);
      }, 200);
    }
  },
  created() {
    this.model = { ...this.process };
    this.initialModel = { ...this.process };
  },
};
</script>
