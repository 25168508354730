<template>
  <div>
    <div class="light-bg p-3 bd-b">
      <div>
        <div :class="['flex ai-c jc-sb fw-w fgap2']">
          <div>
            <h4 class="mb-0">EXPORTAR CONQUISTAS DE CLIENTES</h4>
            <p class="mb-0">
              Exportação de conquistas em geral em Tela e Excel
            </p>
          </div>
          <div class="flex ai-c fgap1">
            <download-excel
              :data="conquests"
              :fields="excelFields"
              class="plus-button bg-success"
              v-if="conquests.length > 0"
              :name="`relatorio-geral-de-conquistas.xls`"
            >
              Exportar para Excel
            </download-excel>
            <button
              class="plus-button"
              @click="addDialog = !addDialog"
              type="button"
            >
              Filtros aplicados
            </button>
          </div>
        </div>
        <transition name="activity" mode="out-in">
          <div v-if="addDialog">
            <div class="four-grid mt-4">
              <div class="four-grid">
                <div class="fg-4">
                  <select-wrapper
                    name="customersIds"
                    label="Clientes"
                    :options="customers"
                    :multiple="true"
                    v-model="formFilter.customersIds"
                  ></select-wrapper>
                </div>
              </div>
              <div class="four-grid">
                <div class="fg-4">
                  <select-wrapper
                      name="teamId"
                      label="Time Operacional"
                      :options="listOfTeams"
                      v-model="formFilter.teamId"
                  ></select-wrapper>
                </div>
              </div>
              <div class="four-grid">
                <div class="fg-4">
                  <v-input
                    name="startDate"
                    label="Data inicial (Obrigatório)"
                    v-model="formFilter.startDate"
                    type="date"
                  ></v-input>
                </div>
              </div>
              <div class="four-grid">
                <div class="fg-4">
                  <v-input
                    name="endDate"
                    label="Data final (Obrigatório)"
                    v-model="formFilter.endDate"
                    type="date"
                  ></v-input>
                </div>
              </div>
            </div>
            <div class="flex ai-c jc-c fgap2 mt-4">
              <button class="report-button" @click.prevent="generateInTable">
                GERAR EM TELA
              </button>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="ph-2 sp-3 mt-4">
      <div class="table-container">
        <div class="table-overflow">
          <table>
            <thead>
              <tr>
                <th
                  v-if="
                    (formFilter.customersIds &&
                      formFilter.customersIds.length > 1) ||
                    formFilter.customersIds === null ||
                    formFilter.customersIds
                  "
                >
                  Cliente
                </th>
                <th>Processo</th>
                <th>Descrição</th>
                <th>Data</th>
                <th>Origem</th>
                <th>Tipo</th>
                <th>Valor</th>
                <th>Criada por</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="conquests.length === 0">
                <td colspan="7" class="text-center">
                  Nenhuma conquista encontrada
                </td>
              </tr>
              <tr v-for="conquest in conquests" :key="conquest.id">
                <td
                  v-if="
                    (formFilter.customersIds &&
                      formFilter.customersIds.length > 1) ||
                    formFilter.customersIds === null ||
                    formFilter.customersIds
                  "
                >
                  {{
                    conquest.customer.name
                      ? conquest.customer.name
                      : conquest.customer.completeName
                  }}
                </td>
                <td>
                  <strong>{{
                    conquest.process
                      ? getTypeOfProcessReferenceByProcess(conquest.process)
                      : "-"
                  }}</strong>
                </td>
                <td v-html="conquest.description"></td>
                <td>{{ formatDate(conquest.date) }}</td>
                <td>{{ conquest.source }}</td>
                <td>{{ conquest.type }}</td>
                <td>R$ {{ conquest.value }}</td>
                <td>{{ conquest.createdBy }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config.js';

import VInput from '@/components/VInput';
import SelectWrapper from '@/components/SelectWrapper';
import Swal from 'sweetalert2';

import Axios from 'axios';
import moment from 'moment';
import JsonExcel from 'vue-json-excel';
import ProcessService from '@/services/ProcessService.js';

export default {
  data: () => ({
    addDialog: true,
    formFilter: {
      startDate: null,
      endDate: null,
      customersIds: null,
      teamId: null,
    },
    listOfTeams: [],
    excelFields: {
      Cliente: 'customer.name',
      Processo: 'process.identification',
      Descrição: 'description',
      Data: {
        field: 'date',
        callback: (value) => moment(value).format('DD/MM/YYYY'),
      },
      Origem: 'source',
      Tipo: 'type',
      Valor: 'value',
      'Criada por': 'createdBy',
    },
    customers: [],
    conquests: [],
  }),
  components: {
    VInput,
    SelectWrapper,
    'download-excel': JsonExcel,
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    getTypeOfProcessReferenceByProcess(process) {
      return ProcessService.getTypeOfProcessReference(process);
    },
    async generateInTable() {
      this.$store.commit('setLoading', {
        show: true,
        label: 'Relatório de conquistas',
        description: 'Aguarde, o relatório esta sendo gerado',
      });

      try {
        const data = {};

        if (this.validateForm()) {
          if (
            this.formFilter.customersIds
            && this.formFilter.customersIds.length > 0
          ) {
            data.customersIds = this.formFilter.customersIds.join();
          }

          if (
            this.formFilter.startDate !== null
            && this.formFilter.startDate !== ''
          ) {
            data.startDate = this.formFilter.startDate;
          }

          if (
            this.formFilter.endDate !== null
            && this.formFilter.endDate !== ''
          ) {
            data.endDate = this.formFilter.endDate;
          }

          if (this.formFilter.teamId !== null && this.formFilter.teamId !== '') {
            data.teamId = this.formFilter.teamId;
          }

          const response = await Axios.get(
            `${config.env.API_DEFAULT.host}/conquests/generalReport`,
            { params: data },
          );
          this.conquests = response.data.data;

          this.$store.commit('setLoading', {
            show: false,
          });
        }
      } catch (e) {
        console.error(e);

        this.$store.commit('setLoading', {
          show: false,
        });
      }
    },
    validateForm() {
      const errors = [];

      // if (this.formFilter.customersIds === null || this.formFilter.customersIds.length == 0) {
      //     errors.push('Cliente');
      // }

      if (
        this.formFilter.startDate === null
        || this.formFilter.startDate === ''
      ) {
        errors.push('Data Inicial');
      }

      if (
        this.formFilter.startDate === null
        || this.formFilter.startDate === ''
      ) {
        errors.push('Data Final');
      }

      if (errors.length > 0) {
        Swal.fire(
          'Relatório de inconsitências',
          `Você precisa informar os seguintes campos: <strong>${errors.join(
            ', ',
          )}</strong>`,
          'error',
        );

        this.$store.commit('setLoading', {
          show: false,
        });

        return false;
      }

      return true;
    },
    async getCustomers() {
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/customers/allEnabledCustomers`,
        );

        response.data.data.forEach((item) => {
          this.customers.push({
            value: item[0],
            label: item[1]
              ? `${item[1]} - ${item[3]}`
              : `${item[2]} - ${item[3]}`,
          });
        });
      } catch (e) {
        Swal.fire(
          'Relatório de inconsitências',
          `Houve um erro ao buscar os clientes ativos! ${e.response.data.data}`,
          'error',
        );
      }
    },
  },
  mounted() {
    this.getCustomers();

    Axios.get(`${config.env.API_DEFAULT.host}/teams`).then((response) => {
      response.data._embedded.teams.forEach((team) => {
        this.listOfTeams.push({ label: team.name, value: team.id });
      });
    });
  },
};
</script>

<style scoped>
.plus-button {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #80b7e2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  cursor: pointer;
  border-radius: 2px;
  color: white;
  font-weight: bold;
  font-size: 0.9rem;
}

.plus-button:hover {
  background-color: #1768a7;
}

.plus-button.success {
  background-color: #80b7e2;
}

.four-grid {
  display: grid;
  align-items: start;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.fg-2 {
  grid-column: span 2;
}

.fg-3 {
  grid-column: span 3;
}

.fg-4 {
  grid-column: span 4;
}

fieldset {
  display: block;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  padding: 0 10px 10px 10px;
  margin-top: -10px;
}

fieldset legend {
  background-color: transparent;
  font-size: 14px;
  width: auto;
  padding: 0 5px;
}

.columns-list {
  list-style: none;
  padding: 0;
  max-height: 270px;
  overflow: auto;
  scrollbar-color: #868ba1 transparent;
  scrollbar-width: thin;
}

.columns-list::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.columns-list::-webkit-scrollbar-thumb {
  background-color: #868ba1;
  border-radius: 5px;
}

.columns-list li a {
  display: block;
  padding: 10px;
  background-color: #f8f9fa;
  border: 1px solid #e2e2e2;
  color: inherit;
}

.columns-list li a:hover {
  background-color: #dbdbdb;
}

.columns-list li:not(:last-of-type) {
  margin-bottom: 10px;
}

.columns-list.selecteds li a {
  color: black;
}

.report-button {
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #104375;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
  color: white;
  padding: 10px 35px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out 0s;
  font-size: 1rem;
}

.report-button:hover {
  background-color: #1768a7;
}

table {
  width: 100%;
}

table tr:not(:last-of-type) td {
  border-bottom: 1px solid #e2e2e2;
}

table tr td,
table tr th {
  padding: 10px 5px;
  color: #343a40;
  text-align: center;
  font-size: 0.8rem;
}

table tr th {
  background-color: #8392a51a;
  min-width: 150px;
}

.table-container {
  width: 100%;
  overflow: auto;
  height: 500px;
  scrollbar-color: #868ba1 transparent;
  scrollbar-width: thin;
}

.table-container::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #868ba1;
  border-radius: 5px;
}

.activity-enter-active,
.activity-leave-active {
  transition: all 0.5s ease-in-out 0s;
  overflow: hidden;
}

.activity-enter,
.activity-leave-to {
  max-height: 0px;
  overflow: hidden;
}

.activity-enter-to,
.activity-leave {
  transition: all 0.5s ease-in-out 0s;
  max-height: 1000px;
  overflow: hidden;
}
</style>
