var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.grouped)?_c('div',[_c('div',{staticClass:"calendar-day calendar-round",class:{
      past: _vm.past,
      future: _vm.future,
      active: _vm.active,
    }},[_vm._l((_vm.data),function(item,index){return [(_vm.data.length == 4 ? index < 4 : index < 3)?_c('div',{key:index,staticClass:"calendar-day calendar-round",on:{"click":function($event){return _vm.$emit('innerAction', item)}}},[_c('div',{staticClass:"calendar-day-tooltip"},[(item.identification)?_c('p',[_vm._v("Ref. Prime:")]):_vm._e(),(item.identification)?_c('p',{staticClass:"mb-1"},[_c('span',{staticClass:"text-bold"},[_vm._v(" "+_vm._s(_vm._f("typeOfProcess")(item.typeOfProcess))+_vm._s(String(item.identification).padStart(5, "0"))+" ")])]):_vm._e(),(item.customerRef)?_c('p',[_vm._v("Ref. Cliente:")]):_vm._e(),(item.customerRef)?_c('p',{class:{ 'mb-1': item.itemType === 'invoice' }},[_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(item.customerRef))])]):_vm._e(),(item.itemType === 'invoice')?_c('p',[_vm._v("Total na Moeda:")]):_vm._e(),(item.itemType === 'invoice')?_c('p',[_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(_vm.getInvoiceCurrency(item, item.itemInvoice))+" "+_vm._s(_vm._f("fractionFilter")(_vm.itemTotal(item, _vm.date).totalOtherCoin)))])]):_vm._e()]),_c('div',{staticClass:"calendar-day-content",class:{
            numerario: item.itemType === 'datesETA',
            'numerario-previsto':
              item.itemType === 'estimatedStepCashRequest',
            invoice: item.itemType === 'invoice',
          }},[_c('div',{staticClass:"flex1"},[(item.customerRef)?_c('p',[_vm._v(" Ref: "+_vm._s(item.customerRef)+" | "+_vm._s(_vm.getCalendarType(item))+" ")]):_c('p',[_vm._v(" PRI "+_vm._s(item.identification)+" | "+_vm._s(_vm.getCalendarType(item))+" ")]),_c('p',[_vm._v(_vm._s(_vm._f("moneyFilter")(_vm.itemTotal(item, _vm.date).total)))])]),(item.cashRequestDone && item.itemType === 'datesETA')?_c('div',{staticClass:"ribbon"},[_vm._v(" Pago ")]):_vm._e()])]):_vm._e()]}),(_vm.data.length > 4)?_c('div',{staticClass:"calendar-day-footer"},[_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.$emit('action', _vm.data)}}},[_c('span',[_vm._v("VER MAIS ITENS")]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.data.length - 3)+" itens ocultos")])])]):_vm._e()],2)]):_c('div',{staticClass:"calendar-list-day"},[_vm._l((_vm.data),function(item,index){return [_c('div',{key:index,staticClass:"calendar-day calendar-round",on:{"click":function($event){return _vm.$emit('innerAction', item)}}},[_c('div',{staticClass:"calendar-day-tooltip"},[(item.identification)?_c('p',{staticClass:"mb-1"},[_vm._v(" Ref. Prime: "),_c('span',{staticClass:"text-bold"},[_vm._v("PRI"+_vm._s(item.identification))])]):_vm._e(),(item.customerRef)?_c('p',[_vm._v(" Ref. Cliente: "),_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(item.customerRef))])]):_vm._e()]),_c('div',{staticClass:"calendar-day-content",class:{
          numerario: item.itemType === 'datesETA',
          'numerario-previsto': item.itemType === 'estimatedStepCashRequest',
          invoice: item.itemType === 'invoice',
        }},[_c('div',{staticClass:"flex1"},[(item.customerRef)?_c('p',[_vm._v(" Ref: "+_vm._s(item.customerRef)+" | "+_vm._s(_vm.getCalendarType(item))+" ")]):_c('p',[_vm._v(" PRI "+_vm._s(item.identification)+" | "+_vm._s(_vm.getCalendarType(item))+" ")]),_c('p',[_vm._v(_vm._s(_vm._f("moneyFilter")(_vm.itemTotal(item, _vm.date).total)))])]),(item.cashRequestDone && item.itemType === 'datesETA')?_c('div',{staticClass:"ribbon"},[_vm._v(" Pago ")]):_vm._e()])])]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }