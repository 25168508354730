<template>
  <div>
    <div class="config-title">
      <h2 class="text-dark text-thin">Listagem de Usuários de Acesso</h2>

      <div>
        <button @click.prevent="openUserModal" class="crm-button">
          <div class="flex ai-c fgap1">
            <plus-icon :size="20" color="currentColor" />
            Adicionar
          </div>
        </button>
      </div>
    </div>

    <hr class="mb-4" />

    <skeleton v-if="pageLoad" />

    <config-table
      v-else
      :table-config="tableConfig"
      :tableData="users"
      :columns="tableColumns"
    >
      <!-- Column Enabled -->
      <template #enabled="{ item }">
        <span
          @click.prevent="toggleStatus(item, !item.enabled, 'enabled')"
          class="config-table-tag cursor-pointer"
          :class="{ success: item.enabled, danger: !item.enabled }"
        >
          {{ item.enabled ? "Sim" : "Não" }}
        </span>
      </template>
      <!-- Column Enabled -->

      <!-- Column Employee -->
      <template #employee="{ item }">
        <span
          @click.prevent="toggleStatus(item, !item.employee, 'employee')"
          class="config-table-tag cursor-pointer"
          :class="{ success: item.employee, danger: !item.employee }"
        >
          {{ item.employee ? "Sim" : "Não" }}
        </span>
      </template>
      <!-- Column Employee -->

      <!-- Column FollowUP -->
      <template #followUP="{ item }">
        <span
          class="config-table-tag success mr-1"
          v-if="item.followUPAnalytical"
        >
          Analítico
        </span>
        <span class="config-table-tag primary" v-if="item.followUPSynthetic">
          Sintético
        </span>
        <span class="config-table-tag danger" v-if="!item.followUP"> Não </span>
      </template>
      <!-- Column FollowUP -->

      <!-- Column Employee -->
      <template #role="{ item }">
        <span
          v-if="item.role === 'ADMINISTRATOR'"
          class="config-table-tag primary"
        >
          ADMINISTRADOR
        </span>
        <span
          v-else-if="item.role === 'CUSTOMER'"
          class="config-table-tag warning"
        >
          CLIENTE
        </span>
        <span v-else-if="item.role === 'MANAGER'" class="config-table-tag info">
          GERENTE
        </span>
        <span
          v-else-if="item.role === 'OPERATIONAL'"
          class="config-table-tag info"
        >
          OPERACIONAL
        </span>
        <span
          v-else-if="item.role === 'PARTNER'"
          class="config-table-tag info2"
        >
          PARCEIRO
        </span>
        <span
          v-else-if="item.role === 'CUSTOM_BROKER'"
          class="config-table-tag warning2"
        >
          DESPACHANTE
        </span>
      </template>
      <!-- Column Employee -->

      <!-- Column Actions -->
      <template #actions="{ item }">
        <div class="actions-table">
          <a class="edit-button" href="#" @click.prevent="getUser(item.id)">
            <config-edit-icon :size="16" />
          </a>
          <!--          <a class="remove-button" href="#" @click.prevent="destroyUser(item.id)">-->
          <!--            <config-trash-icon :size="16" />-->
          <!--          </a>-->
        </div>
      </template>
      <!-- Column Actions -->
    </config-table>

    <v-modal
      :handler="userAddModal"
      @requestClose="() => closeUserModal()"
      :title="userData.id ? 'Atualizar Usuário' : 'Adicionar usuário'"
      description=""
      :width="1600"
      :height="80"
    >
      <template #content>
        <form @submit.prevent class="config-user-form">
          <div class="span-12">
            <h4 class="text-dark">Foto de Perfil</h4>
          </div>

          <div class="span-6" v-if="userData.id">
            <div class="flex ai-c jc-sb gap2">
              <div class="mb-2 relative" v-if="userData.photo">
                <p
                  style="
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    background: #0e6d06;
                    color: white;
                    border-radius: 10px;
                    padding: 2px 5px;
                  "
                >
                  Foto atual
                </p>
                <img
                  style="
                    width: 150px;
                    height: 150px;
                    object-fit: cover;
                    overflow: hidden;
                    border-radius: 10px;
                  "
                  :src="userData.photo"
                  :alt="userData.name"
                />
                <button
                  @click.prevent="destroyProfilePhoto"
                  class="crm-button danger small"
                  style="position: absolute; left: 5px; bottom: 5px"
                >
                  Remover
                </button>
              </div>

              <v-drag-and-drop-files
                min-height="150px"
                title="Imagem de Perfil"
                @input="handleInputFile"
              />

              <div class="mb-2 relative" v-if="preview">
                <p
                  style="
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    background: #ffffff;
                    border-radius: 10px;
                    padding: 2px 5px;
                  "
                >
                  Nova foto
                </p>

                <img
                  style="
                    width: 150px;
                    height: 150px;
                    object-fit: cover;
                    overflow: hidden;
                    border-radius: 10px;
                  "
                  :src="preview"
                  :alt="userData.name"
                />

                <div style="position: absolute; bottom: 5px; right: 5px">
                  <button
                    type="button"
                    class="crm-button red small"
                    @click.prevent="
                      () => {
                        newPhoto = null;
                        preview = null;
                      }
                    "
                  >
                    X
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="span-12">
            <h4 class="text-dark">Dados Gerais</h4>
          </div>

          <div class="span-4">
            <v-input
              type="text"
              name="federalID"
              label="CPF"
              v-model="userData.federalID"
              :mask="['###.###.###-##']"
            ></v-input>
            <div class="field-error" v-if="getError('federalID')">
              {{ getError("federalID") }}
            </div>
          </div>

          <div class="span-4">
            <v-input
              type="text"
              name="name"
              label="Nome Completo"
              v-model="userData.name"
            ></v-input>
            <div class="field-error" v-if="getError('name')">
              {{ getError("name") }}
            </div>
          </div>

          <div class="span-4">
            <v-input
              type="date"
              name="dateOfBirth"
              label="Data de Nascimento"
              v-model="userData.dateOfBirth"
            ></v-input>
            <div class="field-error" v-if="getError('dateOfBirth')">
              {{ getError("dateOfBirth") }}
            </div>
          </div>

          <div class="span-4">
            <select-wrapper
              v-model="userData.gender"
              :options="[
                {
                  label: 'Masculino',
                  value: 'm',
                },
                {
                  label: 'Feminino',
                  value: 'f',
                },
              ]"
              label="Gênero"
            ></select-wrapper>
            <div class="field-error" v-if="getError('gender')">
              {{ getError("gender") }}
            </div>
          </div>

          <div class="span-4">
            <v-input
              type="text"
              name="jobRole"
              label="Cargo"
              v-model="userData.jobRole"
            ></v-input>
            <div class="field-error" v-if="getError('jobRole')">
              {{ getError("jobRole") }}
            </div>
          </div>

          <div class="span-4">
            <select-wrapper
              v-model="userData.sector"
              :options="sectors"
              label="Setor"
            ></select-wrapper>
            <div class="field-error" v-if="getError('sector')">
              {{ getError("sector") }}
            </div>
          </div>

          <div class="span-4">
            <select-wrapper
              v-model="userData.teamId"
              :options="[
                {
                  label: 'Sem time',
                  value: '',
                },
                ...teams,
              ]"
              label="Time"
            ></select-wrapper>
          </div>

          <div class="span-4">
            <label>Status de Usuário</label>
            <v-switch
              name="enabled"
              :labels="{
                checked: `Habilitado`,
                unchecked: `Desabilitado`,
              }"
              v-model="userData.enabled"
            />
            <div class="field-error" v-if="getError('enabled')">
              {{ getError("enabled") }}
            </div>
          </div>

          <div class="span-4">
            <label>Colaborador?</label>
            <v-switch
              name="employee"
              :labels="{
                checked: `Sim`,
                unchecked: `Não`,
              }"
              v-model="userData.employee"
            />
            <div class="field-error" v-if="getError('employee')">
              {{ getError("employee") }}
            </div>
          </div>

          <div class="span-12">
            <h4 class="text-dark">Informações de Usuário</h4>
          </div>

          <div class="span-4">
            <select-wrapper
              v-model="userData.role"
              :options="profiles"
              label="Perfil / Papel"
            ></select-wrapper>
            <div class="field-error" v-if="getError('role')">
              {{ getError("role") }}
            </div>
          </div>

          <div class="span-4">
            <select-wrapper
              v-model="userData.groupId"
              :options="groups"
              label="Grupo de Acesso"
            ></select-wrapper>
            <div class="field-error" v-if="getError('groupId')">
              {{ getError("groupId") }}
            </div>
          </div>

          <div class="span-4"></div>

          <div class="span-4">
            <v-input
              type="email"
              name="email1"
              label="E-mail"
              v-model="userData.email1"
            ></v-input>
            <div class="field-error" v-if="getError('email1')">
              {{ getError("email1") }}
            </div>
          </div>

          <div class="span-4">
            <v-input
              :disabled="!!userData.generatePassword"
              type="password"
              name="password"
              label="Senha"
              v-model="userData.password"
            ></v-input>
            <div class="field-error" v-if="getError('password')">
              {{ getError("password") }}
            </div>
          </div>

          <div class="span-4">
            <v-input
              :disabled="!!userData.generatePassword"
              type="password"
              name="confPassword"
              label="Confirmar Senha"
              v-model="userData.confPassword"
            ></v-input>
            <div class="field-error" v-if="getError('confPassword')">
              {{ getError("confPassword") }}
            </div>
          </div>

          <div class="span-4" v-if="!userData.id">
            <v-checkbox
              label="Gerar senha e notificar usuário"
              name="generatePassword"
              v-model="userData.generatePassword"
            />
          </div>

          <div class="span-12">
            <h4 class="text-dark">Informações de Follow UP</h4>
          </div>

          <div class="span-12">
            <label>Recebe FollowUP?</label>
            <v-switch
              name="followUP"
              :labels="{
                checked: `Sim`,
                unchecked: `Não`,
              }"
              v-model="userData.followUP"
            />
          </div>

          <div class="span-12 config-user-form" v-if="userData.followUP">
            <div class="span-4">
              <v-checkbox
                label="FollowUP Sintético"
                name="followUPSynthetic"
                v-model="userData.followUPSynthetic"
              />
            </div>

            <div class="span-4" v-if="userData.followUPSynthetic">
              <select-wrapper
                v-model="userData.followUPSyntheticPeriod"
                :options="syntheticFollowUpPeriods"
                label="Período"
              />
            </div>

            <div class="span-4" v-if="userData.followUPSynthetic">
              <select-wrapper
                v-model="userData.followUPSyntheticWeekday"
                :options="syntheticFollowUpDays"
                label="Dia de Recebimento"
              ></select-wrapper>
            </div>
          </div>

          <div class="span-12 config-user-form" v-if="userData.followUP">
            <div class="span-4">
              <v-checkbox
                label="FollowUP Analítico"
                name="followUPAnalytic"
                v-model="userData.followUPAnalytical"
              />
            </div>

            <div class="span-8" v-if="userData.followUPAnalytical">
              <select-wrapper
                class="sp-2"
                v-model="userData.followUPAnalyticalTypes"
                :options="followUpEvents"
                :multiple="true"
                label="Tipos"
              ></select-wrapper>
            </div>
          </div>

          <div class="span-12">
            <h4 class="text-dark">Informações de Contato</h4>
          </div>

          <div class="span-4">
            <v-input
              type="text"
              name="telephone1"
              label="Telefone"
              v-model="userData.telephone1"
              :mask="['(##) ####-####']"
            ></v-input>
          </div>

          <div class="span-4">
            <v-input
              type="text"
              name="telephone2"
              label="Celular"
              v-model="userData.telephone2"
              :mask="['(##) #####-####']"
            ></v-input>
          </div>

          <div class="span-4">
            <v-input
              type="email"
              name="email2"
              label="E-mail Secundário"
              v-model="userData.email2"
            ></v-input>
          </div>

          <div class="span-12">
            <h4 class="text-dark">Informações de Endereço</h4>
          </div>

          <div class="span-4">
            <v-input
              type="text"
              name="postalCode"
              label="CEP"
              v-model="userData.postalCode"
              :mask="['#####-###']"
            ></v-input>
          </div>

          <div class="span-4">
            <v-input
              type="text"
              name="address"
              label="Logradouro"
              v-model="userData.address"
            ></v-input>
          </div>

          <div class="span-4">
            <v-input
              type="number"
              name="addressNumber"
              label="Número"
              v-model="userData.addressNumber"
            ></v-input>
          </div>

          <div class="span-4">
            <v-input
              type="text"
              name="complement"
              label="Complemento"
              v-model="userData.complement"
            ></v-input>
          </div>

          <div class="span-4">
            <v-input
              type="text"
              name="district"
              label="Bairro"
              v-model="userData.district"
            ></v-input>
          </div>

          <div class="span-4">
            <v-input
              type="text"
              name="city"
              label="Cidade"
              v-model="userData.city"
            ></v-input>
          </div>

          <div class="span-4">
            <select-wrapper
              v-model="userData.federalUnity"
              :options="ufs"
              label="UF"
            ></select-wrapper>
          </div>
        </form>
      </template>

      <template #footer>
        <div class="flex ai-c jc-c fgap2">
          <button
            class="crm-button red"
            @click.prevent="() => closeUserModal()"
          >
            Cancelar
          </button>
          <button
            class="crm-button"
            v-if="userData.id"
            @click.prevent="updateUser"
          >
            Salvar
          </button>
          <button class="crm-button" v-else @click.prevent="storeUser">
            Adicionar
          </button>
        </div>
      </template>
    </v-modal>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import Axios from "axios";
import config from "@/config";
import ConfigTable from "@/components/Configuration/ConfigTable.vue";
import ConfigEditIcon from "@/components/Configuration/icons/ConfigEditIcon.vue";
import PlusIcon from "@/components/crm/Icons/Plus.vue";
import VModal from "@/components/VModal.vue";
import VInput from "@/components/VInput.vue";
import SelectWrapper from "@/components/SelectWrapper.vue";
import * as constants from "@/consts";
import VSwitch from "@/components/VSwitch.vue";
import Swal from "sweetalert2";
import VCheckbox from "@/components/VCheckbox.vue";
import {
  storeSchema,
  updateSchema,
} from "@/utils/configuration/validations/userAccessValidation";
import VDragAndDropFiles from "@/components/VDragAndDropFiles.vue";
import Skeleton from "@/components/Skeleton.vue";

export default {
  name: "ConfigAccessUsersTab",
  components: {
    VDragAndDropFiles,
    VCheckbox,
    VSwitch,
    SelectWrapper,
    VInput,
    VModal,
    PlusIcon,
    ConfigEditIcon,
    ConfigTable,
    Skeleton,
  },
  directives: { mask },
  data() {
    return {
      pageLoad: false,
      userAddModal: false,
      users: [],
      newPhoto: null,
      preview: null,
      refreshModal: false,
      userData: {
        name: "",
        federalID: "",
        dateOfBirth: "",
        gender: "",
        photo: "",
        sector: "",
        jobRole: "",
        email1: "",
        email2: "",
        telephone1: "",
        telephone2: "",
        postalCode: "",
        address: "",
        addressNumber: "",
        complement: "",
        district: "",
        city: "",
        federalUnity: "",
        enabled: false,
        employee: false,
        generatePassword: false,
        password: "",
        confPassword: "",
        followUP: false,
        followUPAnalytical: false,
        followUPSynthetic: false,
        followUPSyntheticWeekday: "",
        followUPSyntheticPeriod: "",
        followUPAnalyticalTypes: [],
      },
      formErrors: [],
      groups: [],
      teams: [],
      followUpEvents: [],
      tableConfig: {
        perPage: 10,
        useSearch: true,
        keysToSearch: ["name", "email1"],
        centeredCell: false,
        showRecords: true,
        showPerPage: true,
      },
      tableColumns: [
        {
          label: "Habilitado",
          field: "enabled",
        },
        {
          label: "Colaborador",
          field: "employee",
        },
        {
          label: "Nome",
          field: "name",
        },
        {
          label: "E-mail",
          field: "email1",
        },
        {
          label: "Follow UP",
          field: "followUP",
        },
        {
          label: "Papel",
          field: "role",
        },
        {
          label: "Ações",
          field: "actions",
        },
      ],
    };
  },
  methods: {
    getError(field) {
      if (this.formErrors.length > 0) {
        const item = this.formErrors.find((e) => e.path === field);

        if (item && item.message) {
          return this.formErrors.find((e) => e.path === field).message;
        }
      } else {
        return null;
      }
    },
    handleInputFile(files) {
      this.newPhoto = files[0];
      this.preview = URL.createObjectURL(files[0]);
    },
    openUserModal() {
      this.userAddModal = true;
    },
    async closeUserModal() {
      this.userAddModal = false;
      this.newPhoto = null;
      this.preview = null;

      this.userData = {
        name: "",
        federalID: "",
        dateOfBirth: "",
        gender: "",
        photo: "",
        sector: "",
        jobRole: "",
        email1: "",
        email2: "",
        telephone1: "",
        telephone2: "",
        postalCode: "",
        address: "",
        addressNumber: "",
        complement: "",
        district: "",
        city: "",
        federalUnity: "",
        enabled: false,
        employee: false,
        generatePassword: false,
        password: "",
        confPassword: "",
        followUP: false,
        followUPAnalytical: false,
        followUPSynthetic: false,
        followUPSyntheticWeekday: "",
        followUPSyntheticPeriod: "",
        followUPAnalyticalTypes: [],
      };
      this.formErrors = [];

      if (this.refreshModal) {
        this.refreshModal = false;
        await this.getUsers();
      }
    },
    async getUser(id) {
      this.$store.commit("setLoading", {
        show: true,
        label: "Usuário de Acesso",
        description: "Carregando Usuário...",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/systemUsers/${id}`
        );

        this.userData = {
          ...response.data.data,
          confPassword: "",
        };
        this.userAddModal = true;
      } catch (e) {
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    async getUsers() {
      this.pageLoad = true;
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/users/list`
        );

        this.users = response.data.data;
        this.tableConfig.totalRecords = response.data.data.length;
        this.tableConfig.lastPage = Math.ceil(
          response.data.data.length / this.tableConfig.perPage
        );
      } catch (e) {
        Swal.fire({
          title: "Usuários de Acesso",
          text: "Erro ao listar usuários de acesso!",
          type: "error",
        });
      } finally {
        this.pageLoad = false;
      }
    },
    async storeUser() {
      this.formErrors = [];

      this.$store.commit("setLoading", {
        show: true,
        label: "Usuário de Acesso",
        description: "Adicionando Usuário...",
      });

      try {
        await storeSchema.validate(this.userData, {
          abortEarly: false,
        });

        await Axios.post(`${config.env.API_DEFAULT.host}/systemUsers/create/`, {
          ...this.userData,
          federalID: this.userData.federalID
            ? this.userData.federalID.replaceAll("-", "").replaceAll(".", "")
            : null,
        });

        Swal.fire({
          title: "Usuário de Acesso!",
          text: "Usuário cadastrado com sucesso!",
          type: "success",
        });

        this.refreshModal = true;

        await this.closeUserModal();
      } catch (e) {
        if (e.name && e.name === "ValidationError") {
          this.formErrors = e.inner;
        } else {
          Swal.fire({
            title: "Adicionar Usuário!",
            text: e.response.data.message,
            type: "error",
          });
        }
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    async updateUser() {
      this.formErrors = [];

      this.$store.commit("setLoading", {
        show: true,
        label: "Usuário de Acesso",
        description: "Atualizando Usuário...",
      });

      try {
        await updateSchema.validate(this.userData, {
          abortEarly: false,
        });

        const response = await Axios.put(
          `${config.env.API_DEFAULT.host}/systemUsers/update/${this.userData.id}`,
          {
            ...this.userData,
            federalID: this.userData.federalID
              ? this.userData.federalID.replace("-", "").replace(".", "")
              : null,
          }
        );
        this.userData = response.data.data;

        if (this.newPhoto !== null) {
          const formData = new FormData();
          formData.append("photo", this.newPhoto);

          const response = await Axios.post(
            `${config.env.API_DEFAULT.host}/systemUsers/upload-photo/${this.userData.id}`,
            formData
          );

          this.userData = response.data.data;

          this.newPhoto = null;
          this.preview = null;
        }

        this.refreshModal = true;

        Swal.fire({
          title: "Usuário de Acesso!",
          text: "Usuário atualizado com sucesso!",
          type: "success",
        });
      } catch (e) {
        if (e.name && e.name === "ValidationError") {
          console.log(e);
          this.formErrors = e.inner;
        } else {
          console.log(e);
          Swal.fire({
            title: "Atualizar Usuário!",
            text: "Erro ao atualizar usuário!",
            type: "error",
          });
        }
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    async destroyProfilePhoto() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Usuários de Acesso",
        description: "Removendo foto do usuário...",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/systemUsers/destroy-photo/${this.userData.id}`
        );

        this.userData = response.data.data;
      } catch (e) {
        Swal.fire({
          title: "Usuários de Acesso!",
          text: "Erro ao remover foto do usuário!",
          type: "error",
        });
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    // async destroyUser(id) {
    //   this.$store.commit("setLoading", {
    //     show: true,
    //     label: "Usuários de Acesso",
    //     description: "Removendo usuário...",
    //   });
    //
    //   try {
    //     const user = this.users.find((u) => u.id === id)
    //     const confirm = await this.$swal({
    //       title: "Você tem certeza?",
    //       text: "Se você excluir o usuário " + user.name + ", não será possível recuperá-lo para uso posterior.",
    //       icon: "warning",
    //       buttons: ["Não", "Sim"],
    //       dangerMode: true
    //     });
    //
    //     if (confirm) {
    //       await Axios.delete(config.env.API_DEFAULT.host + "/users/" + id);
    //       this.users = this.users.filter((user) => user.id !== id);
    //
    //       Swal.fire({
    //         title: "Remover Usuário!",
    //         text: "Usuário removido com sucesso!",
    //         type: "success",
    //       });
    //     }
    //   } catch (e) {
    //     Swal.fire({
    //       title: "Remover Usuário!",
    //       text: "Erro ao remover usuário!",
    //       type: "error",
    //     });
    //   } finally {
    //     this.$store.commit("setLoading", {
    //       show: false,
    //     });
    //   }
    // },
    async toggleStatus(user, status, type) {
      const types = {
        enabled: "Habilitado",
        employee: "Colaborador",
      };

      try {
        const confirm = await this.$swal({
          title: `Você tem certeza que deseja alterar o status ${types[type]}?`,
          icon: "warning",
          buttons: ["Não", "Sim"],
          dangerMode: true,
        });

        if (confirm) {
          const response = await Axios.put(
            `${config.env.API_DEFAULT.host}/systemUsers/update/${user.id}`,
            {
              ...user,
              [type]: status,
            }
          );

          this.users = this.users.map((user) => {
            if (user.id === response.data.data.id) {
              return response.data.data;
            }
            return user;
          });
        }
      } catch (e) {
        Swal.fire({
          title: "Usuário de Acesso!",
          text: "Erro ao atualizar status do usuário!",
          type: "error",
        });
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    async getTeams() {
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/teams`
        );

        this.teams = response.data._embedded.teams.map((item) => ({
          label: `${item.name} (${item.email})`,
          value: item.id,
        }));
      } catch (e) {
        Swal.fire({
          title: "Recuperar Times Operacionais!",
          text: "Erro ao recuperar times!",
          type: "error",
        });
      }
    },
    async getFollowUpEvents() {
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/contactData/getFollowUpEvents/`
        );

        if (response.data.data.length > 0) {
          this.followUpEvents = response.data.data.map((event) => ({
            label: event.description,
            value: event.id,
          }));
        }
      } catch (e) {
        Swal.fire({
          title: "Eventos de Processos",
          text: "Não foi possível carregar o eventos disponíveis",
          type: "error",
        });
      }
    },
    async getGroups() {
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/groups`
        );
        this.groups = response.data.map((group) => ({
          label: group.name,
          value: group.id,
        }));
      } catch (error) {
        this.$swal(
          "Grupos de Acessso",
          "Não foi possível carregar listagem.",
          "error"
        );
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
  },
  computed: {
    ufs() {
      return constants.CADASTROS_UF.map((item) => ({
        label: item.nome,
        value: item.sigla,
      }));
    },
    sectors() {
      return constants.CADASTROS_SETORES.map((sec) => ({
        label: sec.value,
        value: sec.key,
      }));
    },
    profiles() {
      return [
        {
          label: "Cliente (ext)",
          value: "CUSTOMER",
        },
        {
          label: "Parceiro (ext)",
          value: "PARTNER",
        },
        {
          label: "Despachante (ext)",
          value: "CUSTOM_BROKER",
        },
        {
          label: "Operacional (int)",
          value: "OPERATIONAL",
        },
        {
          label: "Gerente (int)",
          value: "MANAGER",
        },
        {
          label: "Administrador (int)",
          value: "ADMINISTRATOR",
        },
      ];
    },
    syntheticFollowUpPeriods() {
      return [
        {
          label: "Semanal",
          value: "SEMANAL",
        },
        {
          label: "Quinzenal",
          value: "QUINZENAL",
        },
        {
          label: "Mensal",
          value: "MENSAL",
        },
        {
          label: "Bimestral",
          value: "BIMESTRAL",
        },
        {
          label: "Semestral",
          value: "SEMESTRAL",
        },
      ];
    },
    syntheticFollowUpDays() {
      return [
        {
          label: "Segunda",
          value: "MONDAY",
        },
        {
          label: "Terça",
          value: "TUESDAY",
        },
        {
          label: "Quarta",
          value: "WEDNESDAY",
        },
        {
          label: "Quinta",
          value: "THURSDAY",
        },
        {
          label: "Sexta",
          value: "FRIDAY",
        },
        {
          label: "Sábado",
          value: "SATURDAY",
        },
        {
          label: "Domingo",
          value: "SUNDAY",
        },
      ];
    },
  },
  async mounted() {
    await this.getUsers();
    await this.getTeams();
    await this.getFollowUpEvents();
    await this.getGroups();
  },
};
</script>

<style scoped>
.config-title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.config-user-form {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
  align-content: start;
}

.config-user-form .span-4 {
  grid-column: span 4;
}

.config-user-form .span-8 {
  grid-column: span 8;
}

.config-user-form .span-12 {
  grid-column: span 12;
}

.config-user-form .field-error {
  border: 2px solid var(--danger);
  padding: 0.25rem 0.5rem;
  margin-top: 0.25rem;
  border-radius: 10px;
  color: var(--danger);
}
</style>
