<template>
  <div class="slim-mainpanel">
    <div class="container">
      <div class="slim-pageheader">
        <ol class="breadcrumb slim-breadcrumb">
          <li class="breadcrumb-item"><a href="#">Operacional</a></li>
          <li class="breadcrumb-item"><a href="#">Importação</a></li>
          <li class="breadcrumb-item"><a href="#">Relatórios</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Relatório Geral de Importações
          </li>
        </ol>
        <h6 class="slim-pagetitle">Relatório Geral de Importações</h6>
      </div>

      <div class="bd bd-1 bd-b-0">
        <div
          class="bd-t bd-5 bd-gray-800 pd-x-10 bg-white d-flex justify-content-between align-items-center pd-b-5 pd-t-5"
        >
          <ul class="nav">
            <li class="mg-t-5">
              <div class="dropdown dropdown-demo">
                <a href="#" class="dd-link" data-toggle="dropdown">
                  <div
                    v-if="
                      filter.date.start !== '' &&
                      filter.date.end !== '' &&
                      filter.date.field !== ''
                    "
                  >
                    <span class="mg-l-0 pd-l-0">
                      <i class="fa fa-calendar tx-info"></i> Período:
                      <span
                        class="pd-x-5 badge badge-info mg-b-0 mg-r-2-force tx-thin-force"
                      >
                        {{ filter.date.field.label }} de
                        {{ filter.date.start | moment("DD/MM/YYYY") }} até
                        {{ filter.date.end | moment("DD/MM/YYYY") }}
                      </span>
                      <i class="fa fa-angle-down mg-l-5"></i>
                    </span>
                  </div>

                  <div v-else>
                    <span> <i class="fa fa-calendar mg-r-5"></i> Período</span>
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>
                <div class="dropdown-menu pd-15 wd-500">
                  <h6 class="tx-gray-800 tx-uppercase tx-bold mg-b-0 mg-t-10">
                    Data do Período dos Processos
                  </h6>
                  <p class="tx-gray-600 mg-b-10">
                    Selecione um campo e uma data inicial e final
                  </p>

                  <div class="form-layout form-layout-2">
                    <div class="row no-gutters">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-control-label">
                            Data Inicial <span class="tx-danger">*</span></label
                          >
                          <date-picker lang="pt-br"
                                       v-model="filter.date.start"
                                       name="toPickedDate"
                                       format="DD/MM/YYYY"
                                       placeholder="Data Inicial"
                          ></date-picker>
                        </div>
                      </div>
                      <div class="col-md-6 mg-t--1 mg-md-t-0">
                        <div class="form-group mg-md-l--1">
                          <label class="form-control-label">
                            Data Final <span class="tx-danger">*</span></label
                          >
                          <date-picker lang="pt-br"
                                       v-model="filter.date.end"
                                       name="toPickedDate"
                                       format="DD/MM/YYYY"
                                       placeholder="Data Final"
                          ></date-picker>
                        </div>
                      </div>
                    </div>
                    <div class="row no-gutters">
                      <div class="col-md-12">
                        <div class="form-group bd-t-0-force">
                          <label class="form-control-label">
                            Campo do Filtro
                          </label>
                          <v-select
                            v-model="filter.date.field"
                            :options="listOfDatesFields"
                            name="label"
                          >
                            <template slot="option" slot-scope="option">
                              {{ option.label }}
                            </template>
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="mg-t-5">
              <div class="dropdown dropdown-demo mg-l-10" data-pesistent>
                <a href="#" class="dd-link tx-light" data-toggle="dropdown">
                  <div v-if="filter.customers.length > 0">
                    <span class="mg-l-0 pd-l-0">
                      <i class="fa fa-building tx-danger mg-r-5"></i>Clientes:
                      <span
                        class="pd-x-5 badge badge-danger mg-b-0 mg-r-2-force tx-thin-force"
                        v-for="c in filter.customers"
                        :key="c.id"
                      >
                        {{ c.name }}</span
                      >
                      <i class="fa fa-angle-down mg-l-5"></i>
                    </span>
                  </div>

                  <div v-else>
                    <span
                    ><i class="fa fa-building tx-medium mg-r-5"></i> Clientes
                    </span>
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>
                <div class="dropdown-menu pd-15 wd-500">
                  <h6 class="tx-gray-800 tx-uppercase tx-bold mg-b-0 mg-t-10">
                    Clientes
                  </h6>
                  <p class="tx-gray-600 mg-b-10">
                    Selecione um ou mais clientes ({{
                    this.listOfCustomers.length
                    }}
                    encontrados)
                  </p>

                  <div class="form-layout form-layout-2">
                    <div class="row no-gutters">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label"> Clientes </label>
                          <v-select
                            v-model="filter.customers"
                            label="name"
                            :options="listOfCustomers"
                            name="customers"
                            multiple
                          >
                            <template slot="option" slot-scope="option">
                              {{ option.name }}
                            </template>
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="mg-t-5">
              <div class="dropdown dropdown-demo mg-l-10">
                <a href="#" class="dd-link" data-toggle="dropdown">
                  <div v-if="filter.steps.length > 0">
                    <span class="mg-l-0 pd-l-0">
                      <i class="fa fa-flag tx-info mg-r-5"></i>Etapas:
                      <span
                        class="pd-x-5 badge mg-b-0 mg-r-2-force tx-thin-force"
                        :style="
                          'background: ' +
                          c.color +
                          '; color: ' +
                          c.textColor +
                          ' !important;'
                        "
                        v-for="c in filter.steps"
                        :key="c.id"
                      >
                        {{ c.description }}
                      </span>
                      <i class="fa fa-angle-down mg-l-5"></i>
                    </span>
                  </div>

                  <div v-else>
                    <span
                    ><i class="fa fa-flag tx-medium mg-r-5"></i> Etapas
                    </span>
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>
                <div class="dropdown-menu pd-15 wd-500">
                  <h6 class="tx-gray-800 tx-uppercase tx-bold mg-b-0 mg-t-10">
                    Etapas
                  </h6>
                  <p class="tx-gray-600 mg-b-10">Selecione um ou mais etapas</p>

                  <div class="form-layout form-layout-2">
                    <div class="row no-gutters">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label"> Etapas </label>
                          <v-select
                            v-model="filter.steps"
                            label="name"
                            :options="steps"
                            name="steps"
                            multiple
                          >
                            <template slot="option" slot-scope="option">
                              {{ option.description }}
                            </template>
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="mg-t-5">
              <div class="dropdown dropdown-demo mg-l-10" data-pesistent>
                <a href="#" class="dd-link" data-toggle="dropdown">
                  <div v-if="filter.customsBrokers.length > 0">
                    <span class="mg-l-0 pd-l-0">
                      <i class="icon ion-person-stalker tx-teal mg-r-5"></i
                      >Despachantes:
                      <span
                        class="pd-x-5 badge badge-teal mg-b-0 mg-r-2-force tx-thin-force"
                        v-for="cb in filter.customsBrokers"
                        :key="cb.id"
                      >
                        {{ cb.name }}
                      </span>
                      <i class="fa fa-angle-down mg-l-5"></i>
                    </span>
                  </div>
                  <div v-else>
                    <span
                    ><i class="icon ion-person-stalker tx-medium mg-r-5"></i>
                      Despachantes
                    </span>
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>
                <div class="dropdown-menu pd-15 wd-500">
                  <h6 class="tx-gray-800 tx-uppercase tx-bold mg-b-0 mg-t-10">
                    Despachante Aduaneiro
                  </h6>
                  <p class="tx-gray-600 mg-b-10">
                    Selecione um ou mais despachantes aduaneiros ({{
                    this.listOfCustomsBrokers.length
                    }}
                    encontrados)
                  </p>

                  <div class="form-layout form-layout-2">
                    <div class="row no-gutters">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label">
                            Despachante Aduaneiro
                          </label>
                          <v-select
                            v-model="filter.customsBrokers"
                            label="name"
                            :options="listOfCustomsBrokers"
                            name="customsBrokers"
                            multiple
                          >
                            <template slot="option" slot-scope="option">
                              {{ option.name }}
                            </template>
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="mg-t-5">
              <div class="dropdown dropdown-demo mg-l-10" data-pesistent>
                <a href="#" class="dd-link" data-toggle="dropdown">
                  <div v-if="filter.exporters.length > 0">
                    <span class="mg-l-0 pd-l-0">
                      <i class="icon ion-person-stalker tx-indigo mg-r-5"></i
                      >Exportadores:
                      <span
                        class="pd-x-5 badge badge-indigo mg-b-0 mg-r-2-force tx-thin-force"
                        v-for="cb in filter.exporters"
                        :key="cb.id"
                      >
                        {{ cb.name }}
                      </span>
                      <i class="fa fa-angle-down mg-l-5"></i>
                    </span>
                  </div>
                  <div v-else>
                    <span
                    ><i class="icon ion-person-stalker tx-medium mg-r-5"></i>
                      Exportadores
                    </span>
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>
                <div class="dropdown-menu pd-15 wd-500">
                  <h6 class="tx-gray-800 tx-uppercase tx-bold mg-b-0 mg-t-0">
                    Exportadores
                  </h6>
                  <p class="tx-gray-600 mg-b-10">
                    Selecione um ou mais Exportadores ({{
                    this.listOfExporters.length
                    }}
                    encontrados)
                  </p>

                  <div class="form-layout form-layout-2">
                    <div class="row no-gutters">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label">
                            Exportadores
                          </label>
                          <v-select
                            v-model="filter.exporters"
                            label="name"
                            :options="listOfExporters"
                            name="exporters"
                            multiple
                          >
                            <template slot="option" slot-scope="option">
                              {{ option.name }}
                            </template>
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li class="mg-t-5">
              <div class="dropdown dropdown-demo mg-l-10" data-pesistent>
                <a href="#" class="dd-link" data-toggle="dropdown">
                  <div v-if="filter.teams.length > 0">
                    <span class="mg-l-0 pd-l-0">
                      <i class="icon ion-person-stalker tx-pink mg-r-5"></i
                      >Equipes:
                      <span
                        class="pd-x-5 badge badge-pink mg-b-0 mg-r-2-force tx-thin-force"
                        v-for="t in filter.teams"
                        :key="t.id"
                      >
                        {{ t.name }}
                      </span>
                      <i class="fa fa-angle-down mg-l-5"></i>
                    </span>
                  </div>
                  <div v-else>
                    <span
                    ><i class="icon ion-person-stalker tx-medium mg-r-5"></i>
                      Equipes
                    </span>
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>

                <div class="dropdown-menu pd-15 wd-500">
                  <h6 class="tx-gray-800 tx-uppercase tx-bold mg-b-0 mg-t-0">
                    Equipe
                  </h6>
                  <p class="tx-gray-600 mg-b-10">
                    Selecione um ou mais Equipes ({{ this.listOfTeams.length }}
                    encontrados)
                  </p>

                  <div class="form-layout form-layout-2">
                    <div class="row no-gutters">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label"> Equipes </label>
                          <v-select
                            v-model="filter.teams"
                            label="name"
                            :options="listOfTeams"
                            name="teams"
                            multiple
                          >
                            <template slot="option" slot-scope="option">
                              {{ option.name }}
                            </template>
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="mg-t-5">
              <div class="dropdown dropdown-demo mg-l-10" data-pesistent>
                <a href="#" class="dd-link" data-toggle="dropdown">
                  <div v-if="filter.retreatIcms.length > 0">
                    <span class="mg-l-0 pd-l-0">
                      <i class="fas fa-bank mg-r-5"></i>Recolhimento ICMS:
                      <span
                        class="pd-x-5 badge badge-pink mg-b-0 mg-r-2-force tx-thin-force"
                        v-for="t in filter.retreatIcms"
                        :key="t.value"
                      >
                        {{ t.value }}
                      </span>
                      <i class="fa fa-angle-down mg-l-5"></i>
                    </span>
                  </div>
                  <div v-else>
                    <i class="fas fa-bank mg-r-5"></i>Recolhimento ICMS
                    <i class="fa fa-angle-down mg-l-5"></i>
                  </div>
                </a>

                <div class="dropdown-menu pd-15 wd-500">
                  <h6 class="tx-gray-800 tx-uppercase tx-bold mg-b-0 mg-t-0">
                    Opções de recolhimento
                  </h6>
                  <p class="tx-gray-600 mg-b-10">
                    Selecione um opção ({{ retreatIcmsOptions.length }}
                    encontrados)
                  </p>

                  <div class="form-layout form-layout-2">
                    <div class="row no-gutters">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label">
                            Alíquotas de ICMS
                          </label>
                          <v-select
                            v-model="filter.retreatIcms"
                            label="label"
                            :options="retreatIcmsOptions"
                            name="retreatIcms"
                            multiple
                          >
                            <template slot="option" slot-scope="option">
                              {{ option.label }}
                            </template>
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <ul class="nav nav-white-800 flex-column flex-md-row tx-white tx-15">
            <li class="mg-l-10">
              <div class="btn-group" role="group" aria-label="Ações">
                <button
                  type="button"
                  class="btn btn-primary pd-x-20 mg-r-10"
                  @click.prevent="generate('HTML')"
                >
                  <i class="fa fa-play mg-r-5"></i> GERAR
                </button>
                <button
                  type="button"
                  class="btn btn-secondary pd-x-20 mg-r-10"
                  v-if="
                    listOfResults.length > 0 &&
                    filter.date.field.attribute === 'diRegistryDate'
                  "
                  @click.prevent="getXmlDis"
                >
                  <i class="fa fa-file-code-o mg-r-5"></i> Gerar ZIP com XML das
                  DIs
                </button>
                <button
                  type="button"
                  class="btn btn-danger pd-x-20 mg-r-10"
                  v-if="listOfResults.length > 0"
                  @click.prevent="generate('PDF')"
                >
                  <i class="fa fa-file-pdf-o mg-r-5"></i>
                  PDF
                </button>
                <download-excel
                  :data="listOfResults"
                  :fields="excelColumns"
                  class="btn btn-success pd-x-20"
                  v-if="listOfResults.length > 0"
                  :name="`relatorio-geral-de-importacoes.xls`"
                >
                  <i class="far fa-file-excel mg-r-5"></i>
                  EXCEL
                </download-excel>
              </div>
            </li>
          </ul>
        </div>

        <div class="section-wrapper mg-t-0 pd-0 bd-1 bd-l-0 bd-r-0 bd-b-1">
          <div class="pd-10 mg-10">
            <div class="flex ai-c jc-sb fgap2">
              <div>
                <label class="section-title mg-t-10 tx-16">
                  Resultado da Consulta</label
                >
                <p class="mg-b-0">
                  Foram encontrados {{ listOfResults.length }} Processos de
                  Importação de acordo com o filtro acima.
                  <router-link
                    :to="{
                      path: `/operational/imp/desk?stepsIds=8&processesIds=${processesIdsToList}&columns=identification,account,step,customer,exporter,invoice,customerRef,customsEnclosure,billOfLading,datesETA,datesETD,datesDepartureDate,datesArrivalDate,diRegistryDate,diNumber,diResourcefulnessDate,diChannel&showProcess=true&indicatorProcess=true`,
                    }"
                    target="_blank"
                    class="statement-link"
                  >Visualizar os processos no Telão de Importação
                    <i class="fa fa-angle-right mg-l-5"></i
                    ></router-link>
                </p>
              </div>

              <button
                type="button"
                class="btn btn-primary pd-x-20 mg-r-10"
                @click.prevent="openDrawer"
              >
                ORGANIZAR COLUNAS
              </button>
            </div>

            <hr/>

            <div class="table-responsive">
              <table class="table table-striped mg-b-0 tx-gray-800 tx-12">
                <thead>
                <tr>
                  <th
                    v-for="col in enabledTableColumns"
                    :key="col.field"
                    class="tx-center"
                  >
                    {{ col.label }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="result in listOfResults" :key="result.id">
                  <td
                    v-for="col in enabledTableColumns"
                    :key="col.field"
                    class="tx-center"
                  >
                    <template v-if="result[col.field.split('.')[0]]">
                        <span v-if="col.type === 'default'">
                          {{ traverse(result, col.field) }}
                        </span>
                      <a
                        href="#"
                        @click.prevent="
                            findProcessByIdentificationSearch(result.id)
                          "
                        v-if="col.type === 'identification'"
                      >
                        {{ traverse(result, col.field) }}
                      </a>
                      <span v-else-if="col.type === 'money'">
                          {{
                            String(traverse(result, col.field)).toLocaleString(
                              "pt-BR",
                              { format: "currency", currency: "BRL" }
                            )
                          }}
                        </span>
                      <span v-else-if="col.type === 'boolean'">
                          {{ traverse(result, col.field) ? "Sim" : "Não" }}
                        </span>
                      <span v-else-if="col.type === 'date'">
                          {{
                            traverse(result, col.field) | moment("DD/MM/YYYY")
                          }}
                        </span>
                    </template>
                    <span v-else> N/I </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :preview-modal="true"
      :enable-download="false"
      :filename="'Relatório_De_Atividades'"
      pdf-orientation="landscape"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <div class="report-box">
          <table cellpadding="0" cellspacing="0">
            <tr class="report-top">
              <td colspan="2">
                <table class="report-header">
                  <tr>
                    <td class="report-header-left">
                      <strong>
                        Prime Internacional Comércio Exterior LTDA
                      </strong>
                      <br/>
                      CNPJ: 08214494000119
                      <br/>
                      Inscrição Municipal: 285459-0 / IE: 255.232.322
                      <br/>
                      Matriz: Itajaí / SC
                      <br/>
                    </td>
                    <td
                      class="report-header-right"
                      align="right"
                      valign="middle"
                    >
                      <img
                        src="@/assets/img/logo_prime.png"
                        style="width: 175px"
                      />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td colspan="2">
                <br/>
                <h1 class="report-slim-pagetitle">
                  RELATÓRIO GERAL DE IMPORTAÇÕES
                </h1>
              </td>
            </tr>
          </table>

          <div
            class="flex ai-c fgap1 text-bold p-2 mb-2"
            style="
              background-color: var(--shortcut-round-bg);
              border-radius: 10px;
            "
          >
            <template v-for="(col, index) in enabledTableColumns">
              <div
                v-if="index < 10"
                :key="col.field"
                :style="{
                  flex: `0 1 ${100 / enabledTableColumns.length}%`,
                  fontSize: '11px',
                  textAlign: 'center',
                  lineHeight: '14px',
                }"
              >
                {{ col.label }}
              </div>
            </template>
          </div>

          <div style="border-radius: 10px; overflow: hidden">
            <div
              v-for="(result, index) in listOfResults"
              :key="result.id"
              class="flex ai-c fgap1 p-1"
              :style="{
                backgroundColor: index % 2 === 0 ? '#f5f6f7' : 'white',
              }"
            >
              <!--                  <div class="html2pdf__page-break" v-if="index !== 0 && index % 6 === 0" style="margin-top: 10px;"/>-->
              <template v-for="(col, index) in enabledTableColumns">
                <div
                  v-if="index < 10"
                  :key="col.field"
                  class="report-tx-center"
                  :style="{
                    flex: `0 1 ${100 / enabledTableColumns.length}%`,
                    fontSize: '10px',
                    lineHeight: '14px',
                  }"
                >
                  <template v-if="result[col.field.split('.')[0]]">
                    <span v-if="col.type === 'default'">
                      {{ traverse(result, col.field) }}
                    </span>
                    <span v-else-if="col.type === 'identification'">
                      {{ traverse(result, col.field) }}
                    </span>
                    <span v-else-if="col.type === 'money'">
                      {{
                        String(traverse(result, col.field)).toLocaleString(
                          "pt-BR",
                          { format: "currency", currency: "BRL" }
                        )
                      }}
                    </span>
                    <span v-else-if="col.type === 'boolean'">
                      {{ traverse(result, col.field) ? "Sim" : "Não" }}
                    </span>
                    <span v-else-if="col.type === 'date'">
                      {{ traverse(result, col.field) | moment("DD/MM/YYYY") }}
                    </span>
                  </template>
                  <span v-else> N/I </span>
                </div>
              </template>
            </div>
          </div>

          <div class="report-footer">
            <small> </small>
            <br/>
          </div>
        </div>
      </section>
    </vue-html2pdf>

    <VDrawer :minWidth="700" @requestClose="closeDrawer" :handler="drawerOpen">
      <template #header>Organizar Colunas</template>

      <template #content>
        <div class="mb-3">
          <h6 class="slim-pagetitle">Colunas Disponíveis</h6>
          <span class="subtitle"
          >Selecione na coluna direita e ordene na coluna esquerda.</span
          >
        </div>

        <div class="flex jc-sb fgap2">
          <div style="flex: 0 1 50%">
            <h6 class="text-dark">Colunas Habilitadas</h6>

            <Draggable
              v-model="tableColumns"
              class="columns-list active list-group w-100"
            >
              <template v-for="col in tableColumns">
                <li
                  v-if="col.enabled == true"
                  @click.prevent="disableTableColumn(col.field)"
                  class="list-group-item list-group-item-action"
                  :key="col.field"
                >
                  {{ col.label }}
                </li>
              </template>
            </Draggable>
          </div>

          <div style="flex: 0 1 50%">
            <h6 class="text-dark">Colunas Desabilitadas</h6>

            <ul class="columns-list list-group w-100">
              <li class="p-0">
                <input
                  class="wd-100p form-control"
                  type="search"
                  name="searchInTable"
                  id="searchInTable"
                  placeholder="Pesquisar coluna..."
                  v-model="disabledColumnsSearch"
                />
              </li>
              <template v-for="col in filteredTableColumns">
                <li
                  v-if="col.enabled == false"
                  @click.prevent="enableTableColumn(col.field)"
                  class="list-group-item list-group-item-action"
                  :key="col.field"
                >
                  {{ col.label }}
                </li>
              </template>
            </ul>
          </div>
        </div>
      </template>

      <template #footer>
        <div>
          <button
            type="button"
            class="w-100 btn btn-primary pd-x-20 mg-r-10"
            @click.prevent="resetTableColumns"
          >
            REDEFINIR COLUNAS
          </button>
        </div>
      </template>
    </VDrawer>
  </div>
</template>

<script>
import Axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
import config from "@/config.js";
import * as labelsProcesses from "@/labels/labels.processes.js";
import VueHtml2pdf from "vue-html2pdf";
import {mapState} from "vuex";
import moment from "moment";
import {processColumns} from "@/utils/process/processColumns.js";
import VDrawer from "@/components/VDrawer.vue";
import Draggable from "vuedraggable";
import JsonExcel from "vue-json-excel";

export default {
  name: "OperationalGeneral",
  props: [],
  components: {
    DatePicker,
    VueHtml2pdf,
    VDrawer,
    Draggable,
    "download-excel": JsonExcel,
  },
  data() {
    return {
      drawerOpen: false,
      tableColumns: processColumns,
      disabledColumnsSearch: "",
      filter: {
        steps: [],
        exporters: [],
        customers: [],
        customsBrokers: [],
        retreatIcms: [],
        teams: [],
        date: {
          field: "",
          start: moment().startOf("week").toDate(),
          end: moment().endOf("week").toDate(),
        },
      },
      retreatIcmsOptions: [
        {
          value: 2.5,
          label: "2.5",
        },
      ],
      processesIdsToList: "",
      labels: {
        id: {
          label: "ID",
        },
        ...labelsProcesses.labels[0],
      },
      listOfCustomsBrokers: [],
      listOfCustomers: [],
      listOfExporters: [],
      listOfResults: [],
      listOfTeams: [],
    };
  },

  methods: {
    openDrawer() {
      this.drawerOpen = true;
    },
    closeDrawer() {
      this.drawerOpen = false;
    },
    enableTableColumn(field) {
      this.tableColumns = this.tableColumns.map((col) => {
        if (col.field === field) {
          return {
            ...col,
            enabled: true,
          };
        }
        return col;
      });
    },
    disableTableColumn(field) {
      this.tableColumns = this.tableColumns.map((col) => {
        if (col.field === field) {
          return {
            ...col,
            enabled: false,
          };
        }
        return col;
      });
    },
    resetTableColumns() {
      this.tableColumns = processColumns;
    },
    traverse(obj, keys) {
      return keys.split(".").reduce((cur, key) => cur[key], obj);
    },
    getXmlDis() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Relatório Geral de Importações",
        description: "Aguarde enquanto o relatório está sendo gerado...",
      });

      try {
        const data = {
          customersIds: this.filter.customers.map((c) => c.id),
          startDate: `${this.$moment(this.filter.date.start).format(
            "Y-MM-DDT"
          )}00:00:00`,
          endDate: `${this.$moment(this.filter.date.end).format(
            "Y-MM-DDT"
          )}23:59:59`,
        };

        const response = Axios.post(
          `${config.env.API_DEFAULT.host}/newImportProcess/generate-zip-xml-di`,
          data
        );

        Swal.fire(
          "Gerar ZIP com XML das DIs",
          "O ZIP está sendo gerado e assim que for finalizado, você receberá um e-mail com o arquivo.",
          "success"
        );

        this.$store.commit("setLoading", {
          show: false,
        });
      } catch (e) {
        console.log(e);
        Swal.fire(
          "Gerar ZIP com XML das DIs",
          "Erro inesperado ao gerar o ZIP de XML's de DI",
          "error"
        );

        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    generate(type) {
      this.$store.commit("ProcessStore/SET_FILTERED_PROCESSES", []);
      const {filter} = this;

      if (
        filter.date.field === "" ||
        filter.date.start === "" ||
        filter.date.end === ""
      ) {
        Swal.fire(
          "Relatório Geral de Importações",
          "Você precisa selecionar um período para conseguir gerar o relatório.",
          "error"
        );
      } else {
        this.$store.commit("setLoading", {
          show: true,
          label: "Relatório Geral de Importações",
          description: "Aguarde enquanto o relatório está sendo gerado...",
        });

        let params = "";
        const startDate = `${this.$moment(filter.date.start).format(
          "Y-MM-DDT"
        )}00:00:00`;
        const endDate = `${this.$moment(filter.date.end).format(
          "Y-MM-DDT"
        )}23:59:59`;

        params += `${filter.date.field.attribute}&from=${startDate}&to=${endDate}`;

        if (filter.steps.length > 0) {
          const steps = new Array();
          filter.steps.filter((s) => {
            steps.push(s.id);
          });
          params += `&stepsIds=${steps.join()}`;
        }

        if (filter.customers.length > 0) {
          const customers = new Array();
          filter.customers.filter((o) => {
            customers.push(o.id);
          });
          params += `&customersIds=${customers.join()}`;
        }

        if (filter.customsBrokers.length > 0) {
          const customsBrokers = new Array();
          filter.customsBrokers.filter((o) => {
            customsBrokers.push(o.id);
          });
          params += `&customsBrokersIds=${customsBrokers.join()}`;
        }

        if (filter.exporters.length > 0) {
          const exporters = new Array();
          filter.exporters.filter((o) => {
            exporters.push(o.id);
          });
          params += `&exportersIds=${exporters.join()}`;
        }

        if (filter.teams.length > 0) {
          const teams = new Array();
          filter.teams.filter((o) => {
            teams.push(o.id);
          });
          params += `&teamsIds=${teams.join()}`;
        }

        if (filter.retreatIcms != null) {
          const icmss = new Array();
          filter.retreatIcms.filter((o) => {
            icmss.push(o.value);
          });

          params += `&retreatIcms=${icmss.join()}`;
        }

        params += `&columns=${this.enabledTableColumns.map(
          (column) => column.field
        )}`;

        Axios.get(
          `${config.env.API_DEFAULT.host}/process/import/report/general?field=${params}`
        )
          .then((response) => {
            if (response.data.code === "SUCCESS") {
              this.listOfResults = response.data.data;

              this.$store.commit(
                "ProcessStore/SET_PROCESSES",
                response.data.data
              );

              this.processesIdsToList = response.data.data
                .filter((item) => item.id !== "NaN" && item.id !== NaN)
                .map((item) => item.id)
                .join(",");

              if (type === "PDF") {
                this.generatePdfReport();
              } else {
                Swal.fire(
                  "Relatório Geral de Importações",
                  response.data.message,
                  "success"
                );
              }
            } else {
              Swal.fire(
                "Relatório Geral de Importações",
                response.data.message,
                "error"
              );
            }
          })
          .catch((error) => {
            this.$store.commit("setLoading", {
              show: false,
            });
          })
          .finally(() => {
            this.$store.commit("setLoading", {
              show: false,
            });
          });
      }
    },
    async generatePdfReport() {
      this.$refs.html2Pdf.generatePdf();

      this.$store.commit("setLoading", {
        show: false,
      });
    },
    printDemonstrative(data) {
      const requestConfiguration = {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.env.API_JSREPORTONLINE.credentials.token,
          responseType: "arraybuffer",
        },
      };

      const {account} = this.$store.state.credentials.data;

      delete account.logo;
      const requestData = {
        template: {
          shortid: "zNNe8GP",
          recipe: "chrome-pdf",
        },
        data: {
          account: {
            ...account,
            logo: "https://gscmx2-primeinternacional.s3.amazonaws.com/images/logo.png",
          },
          data,
        },
      };

      jsreport.serverUrl = config.env.API_JSREPORTONLINE.host;
      jsreport.headers.Authorization =
        config.env.API_JSREPORTONLINE.credentials.token;

      jsreport
        .renderAsync(requestData)
        .then((res) => {
          // open download dialog
          res.download("Relatório Geral de Importações.pdf");
          res.xhr.getResponseHeader("Content-Type");
          this.$store.commit("setLoading", {
            show: false,
          });
        })
        .catch((error) => {
          this.$store.commit("setLoading", {
            show: false,
          });
          Swal.fire(
            "Relatório Geral de Importações",
            `Houve algum erro ao tentar gerar o demonstrativo desta DI: <strong>${error.response.data.message}</strong>`,
            "error"
          );
        });
    },

    async findProcessByIdentificationSearch(id) {
      try {
        const response = await this.$store.dispatch(
          "ProcessStore/findProcessById",
          id
        );
        if (response) {
          // this.$store.commit("ProcessStore/SET_ACTIVE", response.data);
          this.$router.push(
            "/operational/imp/desk?showProcess=true&filteredByReport=true"
          );
        }
      } catch (error) {
        Swal.fire("Processo de Importação", error.response, "error");
      }
    },
  },
  watch: {},
  computed: {
    ...mapState({
      steps: (state) => state.ProcessStore.steps,
    }),
    excelColumns() {
      const excelCols = {};

      for (let i = 0; i < this.enabledTableColumns.length; i++) {
        const column = this.enabledTableColumns[i];

        if (column.enabled === true) {
          if (column.type === "date") {
            Object.assign(excelCols, {
              [column.label]: {
                field: column.field,
                callback: (value) =>
                  `${value ? moment(value).format("DD/MM/YYYY") : "N/I"}`,
              },
            });
          } else if (column.type === "boolean") {
            Object.assign(excelCols, {
              [column.label]: {
                field: column.field,
                callback: (value) => `${value ? "SIM" : "NÃO"}`,
              },
            });
          } else if (column.type === "money") {
            Object.assign(excelCols, {
              [column.label]: {
                field: column.field,
                callback: (value) => value.toLocaleString(),
              },
            });
          } else {
            Object.assign(excelCols, {
              [column.label]: column.field,
            });
          }
        }
      }

      return excelCols;
    },
    enabledTableColumns: {
      get() {
        return this.tableColumns.filter((col) => col.enabled === true);
      },
      set(value) {
        return value;
      },
    },
    filteredTableColumns() {
      if (this.disabledColumnsSearch != "") {
        const regExp = new RegExp(this.disabledColumnsSearch, "gi");

        return this.tableColumns.filter((col) => regExp.test(col.label));
      }
      return this.tableColumns;
    },
    listOfDatesFields() {
      const data = new Array();

      if (this.labels !== undefined) {
        for (const prop in this.labels) {
          if (this.labels[prop].type === "date") {
            data.push(this.labels[prop]);
          }
        }
      }

      return data;
    },
  },
  mounted() {
    this.$store.dispatch("ProcessStore/getSteps");

    this.$store.commit("setLoading", {
      show: true,
      label: "Aguarde...",
      description: "Carregando informações para filtro do relatório...",
    });

    Axios.get(
      `${config.env.API_DEFAULT.host}/companies/search/findAllByIsCustomBroker?isCustomBroker=true`
    ).then((response) => {
      this.listOfCustomsBrokers = response.data._embedded.companies;
    });

    Axios.get(
      `${config.env.API_DEFAULT.host}/companies/search/findAllByIsExporter?isExporter=true`
    ).then((response) => {
      this.listOfExporters = response.data._embedded.companies;
    });

    Axios.get(
      `${config.env.API_DEFAULT.host}/companies/search/findAllByIsCustomerOrderByName?isCustomer=true`
    ).then((response) => {
      this.listOfCustomers = response.data._embedded.companies;
    });

    Axios.get(
      `${config.env.API_DEFAULT.host}/companies/search/findAllByIsCustomerOrderByName?isCustomer=true`
    ).then((response) => {
      this.listOfCustomers = response.data._embedded.companies;
    });

    Axios.get(`${config.env.API_DEFAULT.host}/teams`).then((response) => {
      this.listOfTeams = response.data._embedded.teams;
    });

    this.$store.commit("setLoading", {show: false});
  },
};
</script>

<style scoped>
.vs__selected-options span.selected-tag {
  margin: 5px 0px 0px -3px !important;
  padding: 5px !important;
}

.report-slim-pagetitle {
  margin-top: 15px;
  margin-bottom: 10px;
  color: #343a40;

  border-left: 4px solid #4662d4;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.5px;
  border-bottom: 1px solid #343a40;
  padding: 5px;
}

.report-box {
  max-width: 1100px;
  padding: 5px;
  margin: 20px;
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #343a40;
}

.report-box table {
  width: 100%;
}

.report-hr {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
}

.report-header {
  font-size: 12px;
}

.report-header-left {
  text-align: left;
  border-left: 6px solid #4662d4;
  padding-left: 5px;
}

.report-header-middle {
  padding-left: 20px;
  text-align: left !important;
  line-height: 20px;
}

.report-header-right {
  vertical-align: middle;
  line-height: 10px;
}

.report-header-right small {
  font-size: 10px;
}

.report-list {
  width: 100%;
  border-collapse: collapse;
}

.report-list th {
  text-transform: uppercase;
  font-size: 13px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
}

.report-list td {
  font-size: 11px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
}

.report-footer {
  position: fixed;
  left: 0;
  bottom: 10px;
  width: 100%;
  text-align: center;
  line-height: 8px;
}

.report-footer small {
  font-size: 9px;
}

.report-tx-center {
  text-align: center;
}

.report-tx-bold {
  font-weight: bold;
}

.report-tx-10 {
  font-size: 10px;
}

.columns-list {
  max-height: calc(100vh - 250px);
  overflow: auto;
  border: 1px solid #e2e2e2;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: white;
}

.columns-list li {
  padding: 0.35rem 0.75rem;
  border-radius: 2px;
  font-weight: 500;
}

.columns-list.active li {
  border-color: #106dc4;
  color: #106dc4;
  background-color: rgba(16, 109, 196, 0.1);
}

.columns-list.active li:hover {
  background-color: #106dc4;
  color: white;
}

.columns-list li:hover {
  cursor: pointer;
}

.columns-list .list-group-item.active {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
</style>
