<template>
  <div id="app">
    <loading v-if="loading"/>

    <template
      v-if="
        this.$store.state.defaultLayout &&
        this.$store.state.credentials.userAuthenticated
      "
    >
      <div
        v-if="this.enviromentIsLocalhost || this.enviromentIsDev"
        class="pd-0 mg-0"
      >
        <div class="alert alert-solid alert-warning mg-0" role="alert">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="alert"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <span v-if="this.enviromentIsLocalhost">
            <strong class="tx-uppercase">Ambiente Local: </strong>
            <br/>
            Endereço da API {{ config.env.API_DEFAULT.host }}
          </span>
          <span v-else-if="this.enviromentIsDev">
            <strong class="tx-uppercase">Ambiente Desenvolvimento: </strong>
            <br/>
            Endereço da API {{ config.env.API_DEFAULT.host }}
          </span>
        </div>
      </div>
      <!--      <Header/>-->
      <MainHeader/>
      <!--      <Navbar/>-->
      <div v-if="automaticallyGeneratedPassword" class="alert alert-danger">
        A sua senha de acesso foi gerada automaticamente pelo sistema, por favor
        altere a senha no seguinte link:
        <router-link to="/configuration/profile">Meus dados</router-link>
        , por questões de segurança!
      </div>
      <router-view :key="$route.path" class="mg-b-40"/>

      <p class="mg-t-20 mg-b-0 tx-12 tx-center">
        Todos os direitos reservados à {{ tenantName }}
      </p>
      <p class="tx-11 tx-center">
        Gescomex © {{ new Date() | moment("YYYY") }} Versão {{ version }}
      </p>
      <FabMenu/>
    </template>

    <template v-else>
      <router-view :key="$route.path"/>
    </template>

    <cookie-policy/>
  </div>
</template>

<script>
import Axios from "axios";
import CONFIG from "@/config.js";
import FabMenu from "@/components/FabMenu.vue";
import CookiePolicy from "@/components/CookiesPolicy.vue";
import MainHeader from "@/components/MainHeader/index.vue";
import Loading from "@/components/utils/Loading.vue";

export default {
  name: "gescomex-app",
  props: [],
  components: {
    MainHeader,
    CookiePolicy,
    FabMenu,
    Loading,
  },
  watch: {},

  data() {
    return {
      tenantName: "",
      tenantLogo: "",
      version: "",
      credential: null,
      config: CONFIG,
      automaticallyGeneratedPassword: false,
    };
  },

  methods: {
    async checkAutomaticallyGeneratedPassword() {
      await Axios.get(
        `${this.config.env.API_DEFAULT.host}/systemUsers/checkAutomaticallyGeneratedPassword`
      ).then((response) => {
        this.automaticallyGeneratedPassword = !(
          response.data.data === null || !response.data.data
        );
      });
    },
  },
  computed: {
    loading() {
      return this.$store.state.loading.show;
    },
    enviromentIsLocalhost() {
      return this.config.env.API_DEFAULT.host === "http://localhost:7777";
    },
    enviromentIsDev() {
      return (
        this.config.env.API_DEFAULT.host ===
        "http://dev.webservice.gscmx.prime.com.vc:7777"
      );
    },
    enviromentIsProd() {
      return (
        this.config.env.API_DEFAULT.host ===
        "http://webservice.gscmx.prime.com.vc:9000"
      );
    },
  },
  mounted() {
    this.$store.state.loading.show = false;
    setTimeout(async () => {
      this.tenantName = "Prime Internacional";
      this.tenantLogo =
        "https://gscmx2-primeinternacional.s3.amazonaws.com/images/logo.png";
      this.version = "2.1.3";
    }, 500);

    if (this.$store.state.credentials.accessToken) {
      this.checkAutomaticallyGeneratedPassword();
    }
  },
};
</script>

<style>
@import "./assets/css/slim.css";
@import "./assets/libs/font-awesome/css/font-awesome.css";
@import "./assets/libs/chartist/css/chartist.css";
@import "./assets/libs/open-iconic-master/css/open-iconic-bootstrap.css";
@import "./assets/libs/Ionicons/css/ionicons.css";
@import "./assets/css/custom.css";
@import "./assets/css/perfect-scrollbar.css";
/* @import 'https://fonts.googleapis.com/icon?family=Material+Icons'; */
@import "https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp";

.swal-modal {
  border-radius: 0px !important;
  z-index: 9999999;
}

.swal-modal .swal-title {
  font-weight: 400 !important;
  font-size: 25px !important;
}

.swal-modal .swal-text {
  text-align: center !important;
}

.swal-button {
  background: #1b84e7 !important;
  border-radius: 0px !important;
}

.swal-button--cancel {
  color: #555 !important;
  background-color: #efefef !important;
}

.form-layout-2 .mx-datepicker input {
  background-color: transparent !important;
  color: #343a40 !important;
  border-radius: 0 !important;
  font-weight: 500 !important;
  border: 0px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  height: 20px !important;
  padding: 0px !important;
  padding-left: 0px !important;
}

.form-layout-2 .mx-input-append {
  background-color: transparent !important;
}

.form-layout-2 .mx-input-append .mx-calendar-icon {
  display: none !important;
  background-color: transparent !important;
  color: transparent;
}

.mx-input-wrapper .mx-clear-wrapper {
  margin-top: -8px !important;
}

.form-layout-2 .mx-input-wrapper {
  width: 80px !important;
}

.form-layout-2 select {
  height: 20px !important;
  padding: 0px !important;
  padding-left: 0px !important;
}

.file-upload .input-wrapper {
  height: 135px !important;
  padding: 25px !important;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif !important;
  background: #1b84e7 !important;
}

.file-upload .input-wrapper .file-upload-label .file-upload-icon {
  font-weight: normal;
}

.file-upload .input-wrapper .file-upload-label div {
  margin-top: 20px;
}

.width-control {
  width: 80% !important;
}

.mention-user {
  display: inline-block;
  background-color: #f5f6f7;
  border: 1px solid #e2e2e2;
  padding: 2px 4px;
  border-radius: 10px;
  font-weight: bold;
}

.swal2-container {
  z-index: 99999999 !important;
}
</style>
