<template>
  <div>
    <div class="report-summary-header mg-b-5 mg-t-0">
      <div>
        <h4 class="tx-inverse mg-b-3">Financeiro do Processo</h4>
        <p class="mg-b-0">
          <i class="icon ion-information-circled mg-r-3"></i>Informações de Numerário e Invoices do processo.
        </p>
      </div>
      <div></div>
    </div>

    <label class="section-title mg-t-20">Informações do Numerário</label>

    <form id="generalForm" class="form-layout form-layout-2 mg-t-3">
      <div class="row no-gutters">

        <div class="col-4">
          <div class="form-group  mg-md-t--1">
            <label class="form-control-label">Valor Previsto do Numerário (R$) </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-money op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <money
                  v-model="model.estimatedStepCashRequestEstimatedValue"
                  class="form-control"
                  name="estimatedStepCashRequestEstimatedValue"
                  v-bind="money"
                ></money>
              </div>
            </div>
          <span v-if="!model.invoiceTotal" class="badge badge-warning tx-normal " style="white-space: break-spaces; text-align: left;">
            Preencha o Valor Total de Invoice para calcular este campo
          </span>
          <span v-else-if="!model.cifValueCurrency" class="badge badge-warning tx-normal " style="white-space: break-spaces; text-align: left;">
            Selecione uma Moeda (Aba Geral) para calcular este campo
          </span>

          </div>
        </div>
        
        <div class="col-4">
          <div class="form-group bd-l-0-force mg-md-t--1">
            <label class="form-control-label">Numerário</label>
            <div class="input-group-solved">
              <toggle-button
                v-model="model.cashRequestDone"
                :color="{ unchecked: '#bf0909' }"
                :height="16"
                :labels="{ checked: 'PAGO', unchecked: 'PENDENTE' }"
                :sync="true"
                :width="75"
                class="mt-1"
                name="cashRequestDone"
                @change="changeCashRequestDone"
              />

              <div class="input-group-solved-before ml-2">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker
                  lang="pt-br"
                  v-model="model.stepCashRequest"
                  v-validate="'required'"
                  class="form-control"
                  format="DD/MM/YY"
                  inputName="stepCashRequest"
                  name="stepCashRequest"
                  placeholder="Selecione"
                ></date-picker>
              </div>
              <div class="input-group-solved-after">
                <label class="ckbox wd-16 mg-b-0">
                  <input
                    v-model="model.stepCashRequestSolved"
                    name="stepCashRequestSolved"
                    type="checkbox"
                  />
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </div>


        <div class="col-4">
          <div class="form-group bd-l-0-force mg-md-t--1">
            <label class="form-control-label">Valor do Numerário (R$)</label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-money op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <money
                  v-model="model.stepCashRequestEstimatedValue"
                  class="form-control"
                  name="stepCashRequestEstimatedValue"
                  v-bind="money"
                ></money>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-1">
          <span v-if="showEstimatedStepCashRequestLog" class="badge badge-warning tx-normal " style="white-space: break-spaces; text-align: left;">
            Valor Previsto do Numerário (R$) informado por {{ lastEstimatedStepCashRequestLog.log.user.name }} em {{ lastEstimatedStepCashRequestLog.log.create | dateTimeFormat }}
          </span>
          <span v-else-if="model.estimatedStepCashRequestEstimatedValueLastUpdate && !showEstimatedStepCashRequestLog" class="badge badge-light tx-normal" style="white-space: break-spaces; text-align: left;">
            Valor Previsto do Numerário (R$) atualizado automaticamente em {{ model.estimatedStepCashRequestEstimatedValueLastUpdate | dateTimeFormat }} com a taxa do {{ model.estimatedStepCashRequestEstimatedValueExchangeRateCoin }} no valor de {{ model.estimatedStepCashRequestEstimatedValueExchangeRate | moneyFormat }}
          </span>
        </div>

      </div>

      <div class="row no-gutters">
        <div class="col-12">
          <label class="section-title mg-t-20">Informações de Invoice</label>
        </div>

        <div class="col-4">
          <div class="form-group mg-md-t--1 pd-b-9-force">
            <label class="form-control-label"> Docs Enviados p/ Câmbio </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-calendar op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <date-picker
                  lang="pt-br"
                  v-model="model.documentsSentForExchange"
                  v-validate="'required'"
                  class="form-control"
                  format="DD/MM/YY"
                  inputName="documentsSentForExchange"
                  name="documentsSentForExchange"
                  placeholder="Selecione"
                ></date-picker>
              </div>
            </div>
          </div>
        </div>

        <div class="col-4">
        <div class="form-group bd-l-0-force mg-md-t--1">
          <label class="form-control-label"> Valor Total de Invoices (Previsto) </label>
          <money
            v-model="model.invoiceTotal"
            class="form-control"
            name="invoiceTotal"
            type="text"
            v-bind="money"
          />
          <span class="badge badge-danger tx-normal" v-if="invoiceInconsistence !== null">{{invoiceInconsistence}}</span>
        </div>
      </div>
      </div>
    </form>

    <hr />

    <div class="report-summary-header mg-b-5 mg-t-40">
      <div>
        <h4 class="tx-inverse mg-b-3">Invoices do Processo</h4>
        <p class="mg-b-0">
          <i class="icon ion-information-circled mg-r-3"></i>Listagem das Invoices vinculadas ao processo.
        </p>
      </div>

      <div>
        <a href="#" @click.prevent="addInvoiceModal" class="btn btn-info">
          <i class="icon ion-plus tx-22"></i> ADICIONAR INVOICE
        </a>
      </div>
    </div>

    <div class="mt-4">
      <div class="invoice-list">
        <div
          class="invoice-item-card"
          v-for="invoice in invoices"
          :key="invoice.id"
        >
          <div
            class="invoice-item-skeleton"
            @click.prevent="editInvoiceModal(invoice)"
          >
            <div class="skeleton-tag">
              {{ invoice.invoiceNumber }}
            </div>
            <span></span>
            <span></span>
            <span></span>
            <span></span>

            <div class="skeleton-eye">
              <i class="fa fa-eye"></i>
            </div>
          </div>

          <button
            @click.prevent="destroyInvoice(invoice.id)"
            type="button"
            class="skeleton-delete crm-button small danger"
          >
            <i class="fas fa-trash"></i>
          </button>

          <div
            @click.prevent="editInvoiceModal(invoice)"
            class="invoice-item-info"
          >
            <ul>
              <li class="flex ai-c fgap1">
                <span style="flex: 0 0 30%">Data: </span>
                <span style="flex: 0 0 70%" class="text-bold">
                  {{ new Date(invoice.invoiceDate) | date("dd/MM/yyyy") }}
                </span>
              </li>
              <li class="flex ai-c fgap1">
                <span style="flex: 0 0 30%">Total: </span>
                <span style="flex: 0 0 70%" class="text-bold text-success">
                  {{
                    invoice.invoiceAmount
                      ? new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: invoice.currency,
                        }).format(invoice.invoiceAmount)
                      : "S/I"
                  }}
                </span>
              </li>
              <li class="flex ai-c fgap1">
                <span style="flex: 0 0 30%">Parcelas: </span>
                <span style="flex: 0 0 70%" class="text-bold">
                  {{ invoice.installments.length }}
                </span>
              </li>
              <li class="flex ai-c fgap1">
                <span style="flex: 0 0 30%">Condição: </span>
                <span style="flex: 0 0 70%" class="text-bold">
                  {{ invoice.paymentTerms.replace("Pagamento", "").trim() }}
                </span>
              </li>
              <li class="flex ai-c fgap1">
                <span style="flex: 0 0 30%">Moeda: </span>
                <span style="flex: 0 0 70%" class="text-bold">
                  {{ invoice.currency }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <v-modal
      :handler="invoiceModal"
      :title="`${invoiceData.id ? 'Atualizar' : 'Adicionar'} Invoice`"
      description=""
      :width="1300"
      @requestClose="closeModal"
    >
      <template #content>
        <form @submit.prevent="persistInvoice">
          <div class="columns-12 md-gap">
            <div class="span-6">
              <v-input
                name="number"
                label="Número da invoice"
                type="text"
                v-model="invoiceData.number"
              />

              <div class="field-error" v-if="getFieldError('number')">
                {{ getFieldError("number") }}
              </div>
            </div>
            <div class="span-6">
              <v-input
                name="invoiceDate"
                label="Data da invoice"
                type="date"
                v-model="invoiceData.invoiceDate"
              />

              <div class="field-error" v-if="getFieldError('invoiceDate')">
                {{ getFieldError("invoiceDate") }}
              </div>
            </div>

            <div class="span-6">
              <select-wrapper
                name="paymentTerms"
                label="Condição"
                v-model="invoiceData.paymentTerms"
                :options="termOptions"
              />

              <div class="field-error" v-if="getFieldError('paymentTerms')">
                {{ getFieldError("paymentTerms") }}
              </div>
            </div>
            <div class="span-6">
              <select-wrapper
                @change="consultarTaxa"
                name="currency"
                label="Moeda"
                v-model="invoiceData.currency"
                :options="coinOptions"
              />

              <div class="field-error" v-if="getFieldError('currency')">
                {{ getFieldError("currency") }}
              </div>
            </div>

            <div class="span-6">
              <virtual-select
                :tabindex="1"
                placeholder="Exportador"
                :options="exporters"
                v-model="invoiceData.vendorName"
              />

              <div class="field-error" v-if="getFieldError('vendorName')">
                {{ getFieldError("vendorName") }}
              </div>
            </div>
            <div class="span-6">
              <v-input
                name="invoiceAmount"
                label="Total da invoice"
                type="text"
                money-mask
                v-model="invoiceData.invoiceAmount"
                disabled
              />
            </div>

            <div class="span-12 invoice-card" v-if="invoiceData.paymentTerms">
              <div class="flex ai-c jc-sb fgap2">
                <h5 class="mb-0">
                  Parcelas da condição de {{ invoiceData.paymentTerms }}
                </h5>

                <a
                  href="#"
                  @click.prevent="addInstallment"
                  class="btn btn-info"
                >
                  <i class="icon ion-plus"></i> ADICIONAR PARCELA
                </a>
              </div>

              <hr />

              <ul class="invoice-item-installments-list">
                <li
                  v-for="(installment, index) in invoiceData.installments"
                  :key="index"
                >
                  <div>
                    <span>{{ installment.parcel }}</span>

                    <div v-if="invoiceData.paymentTerms == 'Pagamento à Prazo'">
                      <select-wrapper
                        @input="(value) => checkInstallmentCondition(value, installment)"
                        clearable
                        name="condition"
                        label="Condição"
                        v-model="installment.condition"
                        :options="[
                          { label: 'Invoice', value: 'Invoice' },
                          { label: 'BL', value: 'BL' },
                        ]"
                      />
                    </div>

                    <div v-if="invoiceData.paymentTerms == 'Pagamento à Prazo'">
                      <v-input
                        @input="(value) => checkInstallmentTerms(value, installment)"
                        name="term"
                        label="Prazo"
                        type="number"
                        v-model="installment.term"
                      />
                    </div>

                    <div>
                      <v-input
                        name="dueDate"
                        label="Data de Vencimento"
                        type="date"
                        v-model="installment.dueDate"
                        :disabled="invoiceData.paymentTerms == 'Pagamento à Prazo'"
                      />
                    </div>

                    <div>
                      <v-input
                        @blur="(value) => calculatePriceInBrl(value, installment)"
                        moneyMask
                        name="value"
                        label="Valor"
                        type="text"
                        v-model="installment.value"
                        hint="Ao remover o foco do campo será calculado o valor na moeda"
                      />
                    </div>

                    <div>
                      <v-input
                        moneyMask
                        name="valueInBrl"
                        label="Valor em R$"
                        type="text"
                        v-model="installment.valueInBrl"
                        disabled
                      />
                    </div>

                    <a
                      href="#"
                      @click.prevent="removeInstallment(index, !!installment.id)"
                      class="btn btn-danger btn-icon rounded-circle"
                    >
                      <div>
                        <i class="icon ion-trash-a"></i>
                      </div>
                    </a>
                  </div>

                  <div v-if="installment.lastExchangeRate && installment.lastExchangeUpdate" class="exchange-container">
                    <p>
                      Câmbio atualizado em {{ installment.lastExchangeUpdate | dateFormat }} com a taxa
                      {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 4 }).format(installment.lastExchangeRate) }}
                    </p>
                  </div>
                </li>
              </ul>
            </div>

            <div class="span-12 flex ai-c jc-c">
              <button type="submit" class="crm-button" v-if="invoiceData.id">
                Atualizar Invoice
              </button>
              <button type="submit" class="crm-button" v-else>
                Adicionar Invoice
              </button>
            </div>
          </div>
        </form>
      </template>
    </v-modal>
  </div>
</template>

<script>
import { dateFilter } from "vue-date-fns";
import VModal from "@/components/VModal.vue";
import config from "@/config";
import Swal from "sweetalert2";
import VInput from "@/components/VInput.vue";
import SelectWrapper from "@/components/SelectWrapper.vue";
import Axios from "axios";
import moment from "moment";
import VirtualSelect from "@/components/VirtualSelect.vue";
import { Money } from "v-money";
import DatePicker from "vue2-datepicker";
import ToggleButton from "vue-js-toggle-button/src/Button.vue";
import ProcessService from "@/services/ProcessService.js";
import { invoiceSchema } from "@/utils/process/invoiceValidation";
import { mapState } from "vuex";
import { EventBus } from "@/events/EventBus.js";

export default {
  name: "imp-form-invoices",
  components: {
    VModal,
    VInput,
    SelectWrapper,
    VirtualSelect,
    Money,
    DatePicker,
    ToggleButton,
  },
  props: {
    process: {
      type: Object,
      required: true,
    },
  },
  filters: {
    date: dateFilter,
    dateFormat(value) {
      return moment(value).format("DD/MM/YY");
    },
    dateTimeFormat(value) {
      return moment(value).format("DD/MM/YY à\\s H:mm:ss");
    },
    moneyFormat(value) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 3 }).format(value);
    }
  },
  data() {
    return {
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      model: {
        account: {
          alias: null,
        },
      },
      lastEstimatedStepCashRequestLog: {},
      initialModel: {},
      exporters: [],
      invoices: [],
      invoiceModal: false,
      cotacaoMoeda: 1,
      dataCotacaoMoeda: null,
      formErrors: [],
      invoiceData: {
        processId: this.process.id,
        items: [],
        number: "",
        invoiceDate: moment(this.process.invoiceDate).format("yyyy-MM-DD"),
        paymentTerms: "",
        currency: "",
        invoiceAmount: 0.00,
        vendorName: "",
        installments: [
          {
            parcel: 1,
            dueDate: "",
            value: "",
            valueInBrl: "",
            term: "",
            condition: null,
          },
        ],
      },
      termOptions: [
        {
          label: "Pagamento Antecipado",
          value: "Pagamento Antecipado",
        },
        {
          label: "Pagamento à Vista",
          value: "Pagamento à Vista",
        },
        {
          label: "Pagamento à Prazo",
          value: "Pagamento à Prazo",
        },
      ],
      coinOptions: [],
    };
  },
  methods: {
    getFieldError(field) {
      const msg = this.formErrors.find((err) => err.path === field);
      if (msg) {
        return msg.message;
      }
      return null;
    },
    checkInstallmentCondition(value, installment) {
      installment.dueDate = null;

      if (value == "Invoice" && this.invoiceData.paymentTerms == "Pagamento à Prazo") {

        if (this.process.invoiceDate) {
          
          if (installment.term) {
            const invoiceDate = moment(this.process.invoiceDate);
            installment.dueDate = invoiceDate.add(parseInt(installment.term), "days").format("yyyy-MM-DD");
          }

        } else if (this.process.datesEstimatedGoodsReadinesDate) {
          
          if(installment.term) {
            const datesEstimatedGoodsReadinesDate = moment(this.process.datesEstimatedGoodsReadinesDate);
            installment.dueDate = datesEstimatedGoodsReadinesDate.add(parseInt(installment.term), "days").format("yyyy-MM-DD");
          }

        } else {
          installment.dueDate = null;
          Swal.fire({ title: "Condição da Parcela", html: "<p>O processo não tem Data de Invoice ou Prontidão, para ser utilizada no cálculo de vencimento.</p>", type: "error" });
        }

      } 
      
      if (value == "BL" && this.invoiceData.paymentTerms == "Pagamento à Prazo") {
        
        if (this.process.datesDepartureDate) {
          
          if(installment.term) {
            const departureDate = moment(this.process.datesDepartureDate);
            installment.dueDate = departureDate.add(parseInt(installment.term), "days").format("yyyy-MM-DD");
          }

        } else if (this.process.datesETD) {

          if(installment.term) {
            const datesETD = moment(this.process.datesETD);
            installment.dueDate = datesETD.add(parseInt(installment.term), "days").format("yyyy-MM-DD");
          }

        } else if (this.process.datesEstimatedGoodsReadinesDate) {
          
          if(installment.term) {
            const datesEstimatedGoodsReadinesDate = moment(this.process.datesEstimatedGoodsReadinesDate);
            installment.dueDate = datesEstimatedGoodsReadinesDate.add(parseInt(installment.term), "days").format("yyyy-MM-DD");
          }

        } else {
          installment.dueDate = null;
          Swal.fire({ title: "Condição da Parcela", html: "<p>O processo não tem Data de Prontidão ou ETD ou Embarque, para ser utilizada no cálculo de vencimento.</p>", type: "error" });
        }

      }
    },
    checkInstallmentTerms(value, installment) {
      installment.dueDate = null;

      if (installment.condition == "Invoice" && this.invoiceData.paymentTerms == "Pagamento à Prazo") {
        if (this.process.invoiceDate) {
          
          if (installment.term) {
            const invoiceDate = moment(this.process.invoiceDate);
            installment.dueDate = invoiceDate.add(parseInt(installment.term), "days").format("yyyy-MM-DD");
          }

        } else if (this.process.datesEstimatedGoodsReadinesDate) {
          
          if(installment.term) {
            const datesEstimatedGoodsReadinesDate = moment(this.process.datesEstimatedGoodsReadinesDate);
            installment.dueDate = datesEstimatedGoodsReadinesDate.add(parseInt(installment.term), "days").format("yyyy-MM-DD");
          }

        } else {
          installment.dueDate = null;
          Swal.fire({ title: "Condição da Parcela", html: "<p>O processo não tem Data de Invoice ou Prontidão, para ser utilizada no cálculo de vencimento.</p>", type: "error" });
        }

      } 
      
      if (installment.condition == "BL" && this.invoiceData.paymentTerms == "Pagamento à Prazo") {
        console.log("checkInstallmentTerms");
        
        if (this.process.datesDepartureDate) {
          
          if(installment.term) {
            const departureDate = moment(this.process.datesDepartureDate);
            installment.dueDate = departureDate.add(parseInt(installment.term), "days").format("yyyy-MM-DD");
          }

        } else if (this.process.datesETD) {

          if(installment.term) {
            const datesETD = moment(this.process.datesETD);
            installment.dueDate = datesETD.add(parseInt(installment.term), "days").format("yyyy-MM-DD");
          }

        } else if (this.process.datesEstimatedGoodsReadinesDate) {
          
          if(installment.term) {
            const datesEstimatedGoodsReadinesDate = moment(this.process.datesEstimatedGoodsReadinesDate);
            installment.dueDate = datesEstimatedGoodsReadinesDate.add(parseInt(installment.term), "days").format("yyyy-MM-DD");
          }

        }  else {
          Swal.fire({ title: "Condição da Parcela", html: "<p>O processo não tem Data de Prontidão ou ETD ou Embarque, para ser utilizada no cálculo de vencimento.</p>", type: "error" });
          installment.dueDate = null;
        }
      }
    },
    async getInvoices() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Buscando invoices",
        description: "Carregando...",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/invoices/by-process-id/${this.process.id}`
        );
        this.invoices = response.data.data.map((inv) => ({
          ...inv,
          number: inv.invoiceNumber,
          vendorName: inv.vendor ? inv.vendor.id : null,
        }));
      } catch (e) {
        Swal.fire({
          title: "Erro ao buscar invoice",
          text: e.response.data.message,
          type: "error",
        });
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    async getCoins() {
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/coins`
        );
        const resCoins = response.data._embedded.coins;
        this.coinOptions = resCoins.map((coin) => ({
          label: `${coin.swiftCode} - ${coin.name}`,
          value: coin.swiftCode,
          code: coin.code,
        }));
      } catch (e) {
        console.error(e);

        Swal.fire({
          title: "Erro ao buscar moedas",
          text: e.response.data.message,
          type: "error",
        });
      }
    },
    clearModalData() {
      this.invoiceData = {
        processId: this.process.id,
        items: [],
        number: "",
        invoiceDate: moment(this.process.invoiceDate).format("yyyy-MM-DD"),
        paymentTerms: "",
        currency: "",
        invoiceAmount: 0.00,
        vendorName: "",
        installments: [
          {
            parcel: 1,
            dueDate: "",
            value: "",
            valueInBrl: "",
            term: "",
            condition: null,
          },
        ],
      };
    },
    async closeModal() {
      this.formErrors = [];
      await this.getInvoices();
      this.clearModalData();
      this.invoiceModal = false;
    },
    addInvoiceModal() {
      this.clearModalData();
      this.invoiceModal = true;
    },
    addInstallment() {
      this.invoiceData.installments.push({
        dueDate: "",
        value: "",
        valueInBrl: "",
        term: "",
        condition: "",
        parcel: this.invoiceData.installments.length + 1,
      });
    },
    async removeInstallment(index, makeRequest = false) {
      if (makeRequest) {
        Swal.fire({
          title: "Remover Parcela da Invoice",
          html: "<p>Tem certeza que deseja remover a parcela?</p>",
          type: "warning",
          showConfirmButton: true,
          confirmButtonText: "Remover",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.value) {
            this.$store.commit("setLoading", {
              show: true,
              label: "Carregando...",
              description: "Removendo parcela da invoice.",
            });

            try {
              const installmentId = this.invoiceData.installments[index].id;
              await Axios.delete(
                `${config.env.API_DEFAULT.host}/invoices/destroy-installment/${installmentId}`
              );
              this.removeInstallmentFromArray(index);
            } catch (e) {
              console.error(e);

              Swal.fire({
                title: "Erro ao buscar moedas",
                text: e.response.data.message,
                type: "error",
              });
            } finally {
              this.$store.commit("setLoading", {
                show: false,
              });
            }
          }
        });
      } else {
        this.removeInstallmentFromArray(index);
      }
    },
    removeInstallmentFromArray(index) {
      if (index < this.invoiceData.installments.length - 1) {
        for (let i = index; i < this.invoiceData.installments.length; i++) {
          const installment = this.invoiceData.installments[i];
          installment.parcel--;
        }
      }
      this.invoiceData.installments.splice(index, 1);
    },
    async editInvoiceModal(invoice) {
      this.clearModalData();

      const response = await Axios.get(`${config.env.API_DEFAULT.host}/invoices/${invoice.id}`);
      if(response){

        var invData = response.data.data;

        this.invoiceData = {
          ...invData,
          number: invData.invoiceNumber,
          vendorName: invData.vendor
            ? invData.vendor.id
            : null,
        };

        this.invoiceModal = true;
      }


    },
    stringToFloat(item) {
      const result =
        typeof item === "string"
          ? parseFloat(item.replaceAll(".", "").replace(",", "."))
          : item;
      return result > 0 ? result : null;
    },
    sanitizeInvoice(invoice) {
      return {
        ...invoice,
        invoiceAmount: this.stringToFloat(invoice.invoiceAmount),
        installments: invoice.installments.map((ins) => ({
          ...ins,
          value: this.stringToFloat(ins.value),
          valueInBrl: this.stringToFloat(ins.valueInBrl),
          lastExchangeUpdate: this.dataCotacaoMoeda,
          lastExchangeRate: this.cotacaoMoeda,
        })),
      };
    },
    async persistInvoice() {
      let invoiceData = this.sanitizeInvoice(this.invoiceData);
      const installmentsTotal = invoiceData.installments.reduce((cur, acc) => cur += acc.value, 0)
      invoiceData.invoiceAmount = Math.round(parseFloat(installmentsTotal) * 100) / 100;

      this.formErrors = [];
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Salvando invoice.",
      });

      try {
        await invoiceSchema.validate(this.invoiceData, {
          abortEarly: false,
        });

        if (this.invoiceData.id) {
          const response = await Axios.put(`${config.env.API_DEFAULT.host}/invoices/${this.invoiceData.id}`, invoiceData);
          const newInvoiceData = await Axios.get(response.data.data);
          this.invoiceData = {
            ...newInvoiceData.data.data,
            number: newInvoiceData.data.data.invoiceNumber,
            vendorName: newInvoiceData.data.data.vendor
              ? newInvoiceData.data.data.vendor.id
              : null,
          };

          Swal.fire({
            title: "Atualizar Invoice",
            text: "Invoice atualizada com sucesso!",
            type: "success",
          });
        } else {
          const response = await Axios.post(`${config.env.API_DEFAULT.host}/invoices`, invoiceData);
          const newInvoiceData = await Axios.get(response.data.data);
          this.invoiceData = {
            ...newInvoiceData.data.data,
            number: newInvoiceData.data.data.invoiceNumber,
            vendorName: newInvoiceData.data.data.vendor
              ? newInvoiceData.data.data.vendor.id
              : null,
          };

          Swal.fire({
            title: "Adicionar Invoice",
            text: "Invoice adicionada com sucesso!",
            type: "success",
          });
        }
      } catch (e) {
        if (e.name == "ValidationError") {
          this.formErrors = e.inner;
        } else {
          console.error(e);
          Swal.fire({
            title: "Erro ao buscar invoice",
            text: e.response.data.message,
            type: "error",
          });
        }
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    async destroyInvoice(id) {
      Swal.fire({
        title: "Remover Invoice",
        html: "<p>Tem certeza que deseja remover a invoice?</p>",
        type: "warning",
        showConfirmButton: true,
        confirmButtonText: "Remover",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: "Carregando...",
            description: "Removendo invoice.",
          });

          try {
            await Axios.delete(`${config.env.API_DEFAULT.host}/invoices/${id}`);
            this.invoices = this.invoices.filter((inv) => inv.id !== id);
          } catch (error) {
            Swal.fire({
              title: "Remover invoice",
              html: `<p>${e.response.data.message}</p>`,
              type: "error",
            });
          } finally {
            this.$store.commit("setLoading", {
              show: false,
            });
          }
        }
      });
    },
    async consultarTaxa(value) {
      if (value != true && value != "") {
        this.$store.commit("setLoading", {
          show: true,
          label: "Moeda",
          description: `Consultando valores...`,
        });

        try {
          const coin = this.coinOptions.find((coin) => coin.value == value);
          const coinResponse = await Axios.get(
            `${config.env.API_DEFAULT.host}/exchangeRates/by-code/${coin.code}`
          );

          this.cotacaoMoeda = coinResponse.data.data.sell;
          this.dataCotacaoMoeda = coinResponse.data.data.date;
          this.invoiceData.installments.forEach((installment) => {
            installment.valueInBrl = new Intl.NumberFormat("pt-BR", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(this.stringToFloat(installment.value) * this.cotacaoMoeda);
          });
        } catch (error) {
          Swal.fire({
            title: "Erro ao consultar taxas da moeda",
            text: error.response.data.message,
            type: "error",
          });
        } finally {
          this.$store.commit("setLoading", {
            show: false,
          });
        }
      }
    },
    calculatePriceInBrl(value, installment) {
      installment.valueInBrl = new Intl.NumberFormat("pt-BR", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(this.stringToFloat(value) * this.cotacaoMoeda);
    },
    async getExporters() {
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/company/findAllExporters`
        );
        this.exporters = response.data.data.map((comp) => ({
          label: comp.completeName,
          value: comp.id,
        }));
      } catch (error) {
        Swal.fire({
          title: "Carregar Exportadores",
          html: `<p>Não foi possível recuperar as empresas</p> <div>${error}</div>`,
          type: "error",
        });
      }
    },
    async changeCashRequestDone(par) {
      const response = await Axios.get(
        `${config.env.API_DEFAULT.host}/process/import/changeCashRequestDone/${this.model.id}/${par.value}`
      );

      if (response) {
        EventBus.$emit("Operational.imp.Form.Sidebar.Timeline.load");
      }
    },
    async diffObjects(fieldName) {
      if (
        this.model.id !== null &&
        this.model.id !== undefined &&
        this.model.identification !== null &&
        this.model.identification !== undefined &&
        this.model.typeOfProcess !== null &&
        this.model.typeOfProcess !== undefined
      ) {
        await ProcessService.verifyIfIdentificationExists(
          this.model.id,
          this.model.identification,
          this.model.typeOfProcess
        )
          .then((r) => {
            const { identification } = this.model;

            if (r.data.data === false) {
              const response = this.$store.dispatch(
                "ProcessStore/diffObjects",
                {
                  initialModel: this.initialModel,
                  model: this.model,
                  fieldName,
                }
              );

              if (response) {
                this.initialModel = {
                  ...this.model,
                };
              }

              // this.$socket.emit('updateProcesses', { idProcess: this.model.id });
            } else {
              if (
                this.initialModel !== null &&
                this.initialModel !== undefined
              ) {
                this.model.identification = this.initialModel.identification;
              } else {
                this.model.identification = "";
              }

              Swal.fire(
                "Referência Interna em Uso",
                `A referência ${identification} já está em uso por outro processo.`,
                "error"
              );
            }
          })
          .catch((e) => {
            this.$store.commit("setLoading", { show: false });
            Swal.fire(
              "Salvar",
              `Não foi possível salvar o processo: ${e}`,
              "error"
            );
          });
      } else if (
        (this.model.id === null || this.model.id === undefined) &&
        this.model.identification !== null &&
        this.model.identification !== undefined
      ) {
        this.model.step = `${config.env.API_DEFAULT.host}/processSteps/7`;
        const response = this.$store.dispatch(
          "ProcessStore/postImportProcess",
          {
            model: this.model,
          }
        );

        if (response) {
          this.initialModel = {
            ...this.model,
          };

          Swal.fire(
            "Novo Processo de Importação",
            `O processo foi salvo sob a referência interna de nº <b>${this.model.identification}</b>.`,
            "success"
          );

          this.model.id = this.process.id;
          this.model.identification = this.process.identification;
        }
      }
    },
    async getEstimatedStepCashRequestLog() {
      const response = await Axios.get(`${config.env.API_DEFAULT.host}/general-logs/find-last-by-field/estimatedStepCashRequestEstimatedValue`);
      this.lastEstimatedStepCashRequestLog = response.data.data;
    },
  },
  computed: {
    ...mapState({
      activeProcess: (state) => state.ProcessStore.activeProcess,
    }),
    showEstimatedStepCashRequestLog() {
      if (this.lastEstimatedStepCashRequestLog && this.lastEstimatedStepCashRequestLog.log && this.model.estimatedStepCashRequestEstimatedValueLastUpdate) {
        const logDate = moment(this.lastEstimatedStepCashRequestLog.log.created).subtract(5, 'second');
        const updateDate = moment(this.model.estimatedStepCashRequestEstimatedValueLastUpdate);

        return logDate.isAfter(updateDate);
      }

      return false;
    },
    options() {
      return [
        "blur",
        (e) => {
          let fieldName = null;

          if (e.srcElement.name === "identification") {
            return false;
          }

          if (e.srcElement.attributes[1].name === "type") {
            fieldName = e.srcElement.attributes.name.nodeValue.toString();
          } else if (e.srcElement.attributes[1] !== undefined) {
            fieldName = e.srcElement.attributes[1].nodeValue.toString();
          } else if (e.path !== undefined && e.srcElement !== undefined) {
            fieldName = e.path[0].name;
          }

          setTimeout(() => {
            this.diffObjects(fieldName);
          }, 200);
        },
        true,
      ];
    },
    invoiceInconsistence() {
      let installmentsTotal = 0;

      if (this.invoices && this.invoices.length > 0) {
        this.invoices.forEach((invoice) => {
          installmentsTotal += invoice.installments.reduce((cur, acc) => cur += acc.value, 0);
        });
      }

      installmentsTotal = Math.round(parseFloat(installmentsTotal) * 100) / 100;

      if (this.model.invoiceTotal > installmentsTotal) {
        return 'Valor MAIOR que o Valor Total das Invoices';
      } else if (this.model.invoiceTotal < installmentsTotal) {
        return 'Valor MENOR que o Valor Total das Invoices';
      }

      return null;
    }
  },
  async mounted() {
    if (window.addEventListener) {
      setTimeout(() => {
        const form = document.getElementById("generalForm");
        form.addEventListener(...this.options);
      }, 200);
    }

    this.model = {
      ...this.process,
    };

    this.initialModel = {
      ...this.process,
    };

    EventBus.$on("Operational.imp.Form.Invoices.load", () => {
      this.getInvoices(),
      this.getCoins(),
      this.getExporters(),
      this.getEstimatedStepCashRequestLog()
    });

    await Promise.all([
      this.getInvoices(),
      this.getCoins(),
      this.getExporters(),
      this.getEstimatedStepCashRequestLog()
    ]);

  },
  watch: {
    activeProcess() {
      const stepOld = this.model.step.code

      this.model = {
        ...this.activeProcess,
      };

      this.initialModel = {
        ...this.activeProcess,
      };

      if((stepOld != this.model.step.code) && this.model.step.code == "12" ){
        this.invoices = [];
      }

      this.getEstimatedStepCashRequestLog();
      
      // const afrmmIsEmpty = this.model.integrationAFRMMValue === null || this.model.integrationAFRMMValue === '' || this.model.integrationAFRMMValue === 0;
      const integrationCustomValueIsEmpty =
        this.model.integrationCustomsValue === null ||
        this.model.integrationCustomsValue === "" ||
        this.model.integrationCustomsValue === 0;

      if (
        (this.model.datesETA === null || this.model.datesETA === "") &&
        (this.model.estimatedStepCashRequest === null ||
          this.model.estimatedStepCashRequest === "")
      ) {
        Swal.fire({
          title: "Preenchimento de Previsão de numerário",
          html: "Quando não houver ETA é necessário preencher a data de previsão de numerário",
          type: "warning",
        });
      }

      if (
        this.model.billingNFsDate !== null &&
        this.model.billingNFsDate !== "" &&
        this.process.customer &&
        this.process.customer.id == 100 &&
        integrationCustomValueIsEmpty &&
        this.process.wayOfTransport === "MARITIMA"
      ) {
        const fieldsWithoutValue = [];

        // if (afrmmIsEmpty) {
        //     fieldsWithoutValue.push('AFRMM');
        // }

        if (integrationCustomValueIsEmpty) {
          fieldsWithoutValue.push("Despesas Aduaneiras");
        }

        const message = `${
          fieldsWithoutValue.length > 1 ? "Os Campos" : "O Campo "
        } ${fieldsWithoutValue.join(", ")} ${
          fieldsWithoutValue.length > 1
            ? " devem ser preenchidos"
            : " deve ser preenchido"
        }`;

        Swal.fire({
          title: "Preenchimento da Data de ",
          html: message,
          type: "warning",
        });
      }

    },
  },
};
</script>

<style scoped>
.invoice-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.invoice-item-card .invoice-item-skeleton {
  position: relative;
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.invoice-item-card .invoice-item-skeleton .skeleton-tag {
  display: inline-block;
  padding: 0.15rem 0.3rem;
  border-radius: 5px;
  background-color: var(--primary);
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  color: white;
  font-size: 0.8rem;
  z-index: 2;
}

.invoice-item-card .invoice-item-skeleton span {
  height: 15px;
  border-radius: 5px;
  background-color: #f5f6f7;
  display: block;
}

.invoice-item-card .invoice-item-skeleton .skeleton-eye {
  position: absolute;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: #e2e2e2;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out 0s;
}

.invoice-item-card .skeleton-delete {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 2;
}

.invoice-item-card .invoice-item-skeleton span:nth-of-type(2) {
  width: 80%;
}

.invoice-item-card .invoice-item-skeleton span:nth-of-type(3) {
  width: 90%;
}

.invoice-item-card .invoice-item-skeleton span:nth-of-type(4) {
  width: 70%;
}

.invoice-item-card {
  border: 2px solid #e2e2e2;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.2s ease-in-out 0s;
  cursor: pointer;
  position: relative;
}

.invoice-item-card:hover {
  border: 2px solid var(--primary);
  transform: scale(1.02);
}

.invoice-item-card:hover .invoice-item-skeleton .skeleton-eye {
  opacity: 1;
  visibility: visible;
}

.invoice-item-card .invoice-item-info {
  padding: 0.75rem;
  background-color: #f5f6f7;
  color: black;
}

.invoice-item-card .invoice-item-info ul,
.invoice-item-installments-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.invoice-card {
  background-color: white;
  border: 1px solid #e2e2e2;
  padding: 1rem;
  border-radius: 10px;
}

.invoice-item-installments-list li > div {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 10px;
}

.invoice-item-installments-list li > div > div {
  flex: 1 1 15%;
}

.invoice-item-installments-list li:nth-of-type(even) {
  background-color: #f5f6f7;
}

.exchange-container p {
  padding: 0.25rem 0.75rem;
  margin-bottom: 0;
  background-color: #f5f6f7;
  border-radius: 0.5rem;
  border: 1px solid #e2e2e2;
  color: black;
  margin-bottom: 0.25rem;
}

.invoice-item-installments-list li:nth-of-type(even) .exchange-container p {
  background-color: white;
}
</style>
