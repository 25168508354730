<template>
  <div>
    <form @submit.prevent="addComissioned" class="contact-form">
      <div class="sp-3 light-bg p-3 bd-b">
        <div :class="['flex ai-c jc-sb fw-w fgap2']">
          <div>
            <h4 class="mb-0">COMISSIONADOS</h4>
            <p class="mb-0">
              No total foram adicionados {{ comissioneds.length }} comisisonados
              para o cliente
            </p>
          </div>
          <div>
            <button
              type="button"
              @click="addDialog = !addDialog"
              class="plus-button"
              v-if="hasPrivilege('CRM_EMPRESAS_COMERCIAL_COMISSIONADOS_CRUD')"
            >
              <plus-icon color="#ffffff" />
            </button>
          </div>
        </div>

        <transition name="activity" mode="out-in">
          <div class="contact-form mt-4 mb-2" v-if="addDialog">
            <div>
              <select-wrapper
                v-model="newComissioned.id"
                :options="selectComissioneds"
                label="Comissionado"
              ></select-wrapper>
            </div>

            <div>
              <v-input
                name="description"
                label="Descrição"
                v-model="newComissioned.description"
              ></v-input>
            </div>

            <div>
              <v-input
                name="percetualOfComission"
                label="Percentual de Comissão"
                v-model="newComissioned.percetualOfComission"
                moneyMask
              ></v-input>
            </div>

            <div class="sp-3 flex jc-fe">
              <button
                class="activity-submit"
                v-if="hasPrivilege('CRM_EMPRESAS_COMERCIAL_COMISSIONADOS_CRUD')"
              >
                Adicionar Comissionado
              </button>
            </div>
          </div>
        </transition>
      </div>

      <div class="sp-3">
        <div
          class="card-body pd-40 sp-3 text-center"
          v-if="comissioneds.length == 0"
        >
          <img src="@/assets/img/icon1.svg" class="wd-100 mb-4" alt="" />
          <h6 class="tx-md-20 tx-inverse">Nenhum Comissionado Cadastrado</h6>
          <p>Adicione um comissionado clicando no botão + que está acima</p>
        </div>

        <div class="ph-2 sp-3" v-else>
          <div class="table-overflow">
            <table class="table table-bordered">
              <thead class="thead-colored bg-primary">
                <tr>
                  <th class="tx-white">Nome</th>
                  <th class="tx-white">E-mail</th>
                  <th class="tx-white">Descrição</th>
                  <th class="tx-white">Percentual</th>
                  <th class="tx-white">Ações</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="comissioned in comissioneds" :key="comissioned.id">
                  <td>
                    <span>{{ comissioned.completeName }}</span>
                  </td>
                  <td>
                    <span>{{
                      comissioned.email !== null
                        ? comissioned.email
                        : comissioned.email1
                    }}</span>
                  </td>
                  <td>
                    <span>{{ comissioned.description }}</span>
                  </td>
                  <td>
                    <span>{{ comissioned.percetualOfComission }}%</span>
                  </td>
                  <td class="flex ai-c jc-c">
                    <a
                      href="#"
                      @click.prevent="removeComissioned(comissioned.id)"
                      class="btn btn-danger btn-icon rounded-circle"
                      v-if="
                        hasPrivilege(
                          'CRM_EMPRESAS_COMERCIAL_COMISSIONADOS_CRUD'
                        )
                      "
                    >
                      <div><i class="icon ion-trash-a tx-20"></i></div>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </form>
    <form @submit.prevent="submitForm" class="contact-form">
      <div class="contact-form ph-2 sp-3">
        <div class="sp-3 bd-t pd-t-10">
          <h4>Informações Comerciais</h4>
        </div>

        <div>
          <select-wrapper
            v-model="customerDetails.salesRadarModality"
            label="Modalidade do Radar"
            :options="salesRadarModalities"
          ></select-wrapper>
        </div>

        <div>
          <v-input
            name="salesDateOfFirstBusiness"
            label="Data do Primeiro Negócio"
            v-model="customerDetails.salesDateOfFirstBusiness"
            type="date"
          ></v-input>
        </div>

        <div>
          <v-input
            name="salesDateOfDocumentOperationalValidation"
            label="Vencimento da Procuração"
            v-model="customerDetails.salesDateOfDocumentOperationalValidation"
            type="date"
          ></v-input>
        </div>

        <div>
          <v-input
            name="ttdAnnouncementsDate"
            label="Envio de comunicados - TTD"
            v-model="customerDetails.ttdAnnouncementsDate"
            type="date"
          ></v-input>
        </div>
        <div>
          <v-input
            name="arReceiptConfirmationDate"
            label="Confirmação de recebimento (AR correio)"
            v-model="customerDetails.arReceiptConfirmationDate"
            type="date"
          ></v-input>
        </div>

        <div class="sp-3">
          <v-textarea
            name="salesObservations"
            label="Descrição"
            v-model="customerDetails.salesObservations"
          ></v-textarea>
        </div>

        <div class="sp-3 mt-3 bd-t pd-t-10">
          <h4>Informações Operacionais / Financeiro / Faturamento</h4>
        </div>

        <div>
          <p class="mb-0">Seguro com a Prime</p>
          <v-switch
            name="insuranceWithPrime"
            :labels="{
              checked: `${customerDetails.name} faz seguro com a Prime`,
              unchecked: `${customerDetails.name} não faz seguro com a Prime`,
            }"
            v-model="customerDetails.insuranceWithPrime"
          />
        </div>

        <div>
          <p class="mb-0">Compliance</p>
          <v-switch
            name="hasCompliance"
            :labels="{
              checked: `${customerDetails.name} tem compliance`,
              unchecked: `${customerDetails.name} não tem compliance`,
            }"
            v-model="customerDetails.hasCompliance"
          />
        </div>

        <div>
          <v-input
            v-if="customerDetails.hasCompliance"
            name="hasComplianceInformation"
            type="text"
            label="Descrição do Compliance"
            v-model="customerDetails.hasComplianceInformation"
          ></v-input>
        </div>

        <div>
          <p class="mb-0">Comissão Mínima?</p>
          <v-switch
            name="isMinimalComissionPercentual"
            :labels="{ checked: `Sim`, unchecked: `Não` }"
            v-model="customerDetails.isMinimalComissionPercentual"
          />
        </div>

        <div>
          <p class="mb-0">Cliente com mais de uma comissão?</p>
          <v-switch
            name="hasMultipleComissions"
            :labels="{ checked: `Sim`, unchecked: `Não` }"
            v-model="customerDetails.hasMultipleComissions"
            @input="checkMultipleComissions"
          />
        </div>

        <div></div>

        <v-input
          name="comissionPercentual"
          type="text"
          label="Comissão (%)"
          moneyMask
          v-model="customerDetails.comissionPercentual"
        ></v-input>

        <v-input
          name="sdaValue"
          type="text"
          label="Valor SDA"
          moneyMask
          v-model="customerDetails.sdaValue"
        ></v-input>

        <v-input
          name="billingTerm"
          type="text"
          label="Prazo de Envio Encerramento"
          v-model="customerDetails.billingTerm"
        ></v-input>

        <div class="sp-3 mt-3 bd-t pd-t-10">
          <h4>Tipos de Processo</h4>
        </div>

        <div
          v-for="typeOfProcess in localTypesOfProcess"
          :key="typeOfProcess.customerDetailsKey"
        >
          <v-switch
            name="hasCompliance"
            :labels="{
              checked: `${typeOfProcess.label}`,
              unchecked: `${typeOfProcess.label}`,
            }"
            :value="customerDetails[typeOfProcess.customerDetailsKey]"
            @input="
              (e) => setTypeOfProcess(e, typeOfProcess.customerDetailsKey)
            "
            class="mb-2"
          />
        </div>

        <div v-for="(typeOfProcess, index) in localTypesOfProcess" :key="index">
          <select-wrapper
            clearable
            v-model="customerDetails[typeOfProcess.teamDetailsKey]"
            :options="teams"
            :label="typeOfProcess.teamLabel"
            v-if="customerDetails[typeOfProcess.customerDetailsKey]"
          ></select-wrapper>
        </div>

        <div class="sp-3 row justify-content-center mt-2 mg-b-40">
          <button type="submit" class="report-button">Atualizar Dados</button>
        </div>
      </div>
    </form>

    <v-modal :contentPadding="false" :handler="showRequirementsModal" title="Gerenciamento de Requisitos" description="" @requestClose="showRequirementsModal = false">
      <template #content>
        <companies-form-new-procedures :company="customerDetails" />
      </template>
    </v-modal>
  </div>
</template>

<script>
import config from "@/config.js";
import { PlusIcon } from "@/components/crm/Icons";
import VInput from "@/components/VInput.vue";
import SelectWrapper from "@/components/SelectWrapper.vue";
import VTextarea from "@/components/VTextarea.vue";
import VSwitch from "@/components/VSwitch.vue";
import CompaniesFormNewProcedures from "@/components/crm/customer/procedures";
import Swal from "sweetalert2";
import Axios from "axios";
import CredentialService from "@/services/CredentialService.js";
import VModal from '../VModal.vue';

export default {
  name: "CustomerCommercial",
  props: {
    customerDetails: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    PlusIcon,
    VInput,
    VSwitch,
    SelectWrapper,
    VTextarea,
    VModal,
    "companies-form-new-procedures": CompaniesFormNewProcedures,
  },
  data() {
    return {
      comissioneds: [],
      selectComissioneds: [],
      addDialog: false,
      localTypesOfProcess: [
        {
          label: "Conta e Ordem",
          teamLabel: "Time para Conta e Ordem",
          customerDetailsKey: "hasTypeAccountAndOrder",
          teamDetailsKey: "team",
        },
        {
          label: "Encomenda",
          teamLabel: "Time para Encomenda",
          customerDetailsKey: "hasTypeOrder",
          teamDetailsKey: "teamOrder",
        },
        {
          label: "Assessoria",
          teamLabel: "Time para Assessoria",
          customerDetailsKey: "hasTypeAdvisory",
          teamDetailsKey: "teamAdvisory",
        },
      ],
      // formData: {
      //   salesRadarModality: '',
      //   salesDateOfFirstBusiness: null,
      //   salesDateOfDocumentOperationalValidation: null,
      //   salesObservations: '',
      //   hasCompliance: false,
      //   hasComplianceInformation: '',
      //   isMinimalComissionPercentual: false,
      //   comissionPercentual: 0.00,
      //   sdaValue: 0.00,
      //   teams: [],
      //   billingTerm: null,
      // },
      newComissioned: {},
      showRequirementsModal: false,
      salesRadarModalities: [
        {
          value: "EXPRESS",
          label: "Expressa USD 50.000",
        },
        {
          value: "LIMITED",
          label: "Limitada USD 150.000",
        },
        {
          value: "ILIMITED",
          label: "Ilimitada USD",
        },
      ],
      teams: [],
    };
  },
  async mounted() {
    await this.getTeams();
    await this.getCustomerTeams();
    await this.getComissioneds();
    await this.getLinkedComissioneds();
  },
  methods: {
    checkMultipleComissions(value) {
      if (value === true) {
        Swal.fire({
          title: "Cliente com multiplas comissões",
          html: "<p>Deseja adicionar um requisito?</p>",
          type: "warning",
          showCancelButton: true,
          cancelButtonText: 'Não',
          showConfirmButton: true,
          confirmButtonText: 'Sim'
        }).then((response) => {
          if (response && response.value) {
            this.showRequirementsModal = true;
          }
        });
      }
    },
    setTypeOfProcess(value, key) {
      this.customerDetails[key] = value;

      if (!value) {
        Swal.fire({
          title: "Tipos de Processo",
          html: "<p>Desativando este Tipo de Processo, você removerá o vínculo com o Time / Equipe Operacional que estava selecionada.</p>",
          type: "warning",
        });
      }
    },
    hasPrivilege(privilege) {
      return CredentialService.hasPrivilege(privilege);
    },
    validateProcessTeams() {
      let showMessage = false;
      if (
        this.customerDetails.hasTypeAccountAndOrder &&
        this.customerDetails.team == null
      ) {
        showMessage = true;
      }

      if (
        this.customerDetails.hasTypeOrder &&
        this.customerDetails.teamOrder == null
      ) {
        showMessage = true;
      }

      if (
        this.customerDetails.hasTypeAdvisory &&
        this.customerDetails.teamAdvisory == null
      ) {
        showMessage = true;
      }

      return showMessage;
    },
    validateMinimalComission() {
      if (
        this.customerDetails.isMinimalComissionPercentual &&
        this.customerDetails.comissionPercentual === "0,00"
      ) {
        Swal.fire({
          title: "Atualização de Dados",
          html: "<p>Defina um valor para a comissão mínima ou desabilite esta opção!</p>",
          type: "warning",
        });
        return false;
      }
      return true;
    },
    async submitForm() {
      if (this.validateProcessTeams()) {
        Swal.fire({
          title: "Tipos de Processo",
          html: "<p>Existem tipos de processo sem time vinculado!</p>",
          type: "warning",
        });
        return;
      }

      const comissionOk = this.validateMinimalComission();

      if (!comissionOk) {
        return;
      }

      Swal.fire({
        title: "Atualização de Dados",
        html: "<p>Tem certeza que deseja atualizar os dados da empresa?</p>",
        type: "warning",
        showConfirmButton: true,
        confirmButtonText: "Atualizar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: "Aguarde...",
            description: "Atualizando os dados da Empresa.",
          });

          try {
            const response = await Axios.put(
              `${config.env.API_DEFAULT.host}/company/${this.customerDetails.id}`,
              this.customerDetails
            );

            Swal.fire({
              title: "Atualização de Dados",
              text: response.data.message,
              type: "success",
            });

            this.getCustomerTeams();
          } catch (e) {
            Swal.fire({
              title: "Atualização de Dados",
              text: e,
              type: "error",
            });
          }
          this.$store.commit("setLoading", {
            show: false,
          });
        }
      });
    },
    async getComissioneds() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Carregando comissinados.",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/commissioned/usuariosComissionados/`
        );
        this.selectComissioneds = response.data.data.map((com) => ({
          value: com.id,
          label: com.completeName,
        }));
      } catch (err) {
        Swal.fire({
          title: "Listar Comissionados!",
          html: `<p>Não foi possível carregar os comissionados!<p> <div>${err.message}</div>`,
          type: "error",
        });
      }

      this.$store.commit("setLoading", { show: false });
    },
    async getLinkedComissioneds() {
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/comissions/getComissionsByCompanyId/${this.customerDetails.id}`
        );
        this.comissioneds = response.data.data;
      } catch (err) {
        Swal.fire({
          title: "Listar Comissionados Vinculados!",
          html: `<p>Não foi possível carregar os comissionados!<p> <div>${err.message}</div>`,
          type: "error",
        });
      }
    },
    async addComissioned() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Adicionar Comissionado",
        description: "Adicionando...",
      });

      try {
        await Axios.post(
          `${config.env.API_DEFAULT.host}/commissioned/vincularComissionado/${this.customerDetails.id}/${this.newComissioned.id}`,
          {
            description: this.newComissioned.description,
            percentual: parseFloat(
              String(this.newComissioned.percetualOfComission)
                .replace(".", "")
                .replace(",", ".")
            ),
          }
        );
        Swal.fire({
          title: "Adicionar Comissionado!",
          html: "<p>Comissionado adicionado com sucesso!<p>",
          type: "success",
        });
        this.newComissioned = {};
        await this.getLinkedComissioneds();
      } catch (err) {
        Swal.fire({
          title: "Adicionar Comissionado!",
          html: `<p>Não foi possível adicionar o comissianado!<p> <div>${err.message}</div>`,
          type: "error",
        });
      }

      this.$store.commit("setLoading", { show: false });
    },
    async removeComissioned(id) {
      const conf = Swal.fire({
        title: "Remover Comissionado!",
        html: "<p>Deseja realmente remover o comissionado?<p>",
        type: "warning",
        showConfirmButton: true,
        confirmButtonText: "Remover",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: "Remover Comissionado",
            description: "Adicionando...",
          });

          try {
            await Axios.delete(
              `${config.env.API_DEFAULT.host}/comissions/${id}`
            );
            this.comissioneds = this.comissioneds.filter(
              (com) => com.id !== id
            );
            Swal.fire({
              title: "Remover Comissionado!",
              html: "<p>Comissionado removido com sucesso!<p>",
              type: "success",
            });
          } catch (err) {
            Swal.fire({
              title: "Remover Comissionado!",
              html: `<p>Não foi possível remover o comissianado!<p> <div>${err.message}</div>`,
              type: "error",
            });
          }

          this.$store.commit("setLoading", { show: false });
        }
      });
    },
    async getTeams() {
      Axios.get(`${config.env.API_DEFAULT.host}/teams`)
        .then((response) => {
          const teams = response.data._embedded.teams.map((team) => ({
            label: `${team.name} - ${team.email}`,
            value: team.id,
          }));

          this.teams = teams;
        })
        .catch((error) => {
          Swal.fire({
            title: "Listar Times!",
            html: `<p>Não foi carregar os times!<p><div>${error}</div>`,
            type: "error",
          });
        });
    },
    async getCustomerTeams() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando Times do Cliente",
        description: "Aguarde...",
      });

      try {
        await Promise.all([
          Axios.get(
            `${config.env.API_DEFAULT.host}/customers/getCustomerTeam/${this.customerDetails.id}`
          ).then((response) => {
            if (response.data.data) {
              this.customerDetails.team = response.data.data.id;
            } else {
              this.customerDetails.team = null;
            }
          }),
          Axios.get(
            `${config.env.API_DEFAULT.host}/customers/getCustomerTeamOrder/${this.customerDetails.id}`
          ).then((response) => {
            if (response.data.data) {
              this.customerDetails.teamOrder = response.data.data.id;
            } else {
              this.customerDetails.teamOrder = null;
            }
          }),
          Axios.get(
            `${config.env.API_DEFAULT.host}/customers/getCustomerTeamAdvisory/${this.customerDetails.id}`
          ).then((response) => {
            if (response.data.data) {
              this.customerDetails.teamAdvisory = response.data.data.id;
            } else {
              this.customerDetails.teamAdvisory = null;
            }
          }),
        ]);
      } catch (e) {
        Swal.fire({
          title: "Listar Times Vinculados!",
          html: `<p>Não foi carregar os times!<p><div>${e}</div>`,
          type: "error",
        });
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
  },
};
</script>

<style scoped>
.plus-button {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #80b7e2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
}

.plus-button:hover {
  background-color: #1768a7;
}

.activity-submit {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #104375;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
  color: white;
  padding: 10px 25px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out 0s;
}

.activity-submit:hover {
  background-color: #1768a7;
}

.activity-enter-active,
.activity-leave-active {
  transition: all 0.5s ease-in-out 0s;
  overflow: hidden;
}

.activity-enter,
.activity-leave-to {
  max-height: 0px;
  overflow: hidden;
}

.activity-enter-to,
.activity-leave {
  transition: all 0.5s ease-in-out 0s;
  max-height: 1000px;
  overflow: hidden;
}

table {
  width: 100%;
}

table tr:not(:last-of-type) td {
  border-bottom: 1px solid #e2e2e2;
}

table tr td,
table tr th {
  padding: 20px 10px;
  color: #343a40;
  text-align: center;
}

table tr th {
  background-color: #8392a51a;
}

.tag {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #e2e2e2;
  font-weight: bold;
}

.tag.success {
  background-color: #c4ffc0;
  color: #0f8906;
}

.tag.danger {
  background-color: #ffc0c0;
  color: #890606;
}

.report-button {
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #104375;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
  color: white;
  padding: 10px 35px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out 0s;
  font-size: 1rem;
}

.report-button:hover {
  background-color: #1768a7;
}
</style>
