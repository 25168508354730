<template>
  <div v-if="tableData.length > 0">
    <div class="config-table-header">
      <div class="config-table-search">
        <div v-if="tableConfig.useSearch">
          <div class="config-search-icon">
            <config-search-icon />
          </div>
          <input
            type="search"
            name="search"
            id="search"
            v-model="search"
            placeholder="Pesquisar"
          />
        </div>
      </div>

      <div class="pagination-right">
        <div v-if="tableConfig.showRecords">Registros: {{ totalRecords }}</div>

        <div>
          <a href="#" @click.prevent="prevPage">
            <config-arrow-left-icon />
          </a>
        </div>
        <div>
          <input
            type="text"
            v-mask="'####'"
            name="page"
            id="page"
            :value="currentPage"
            disabled
          />
        </div>
        <div>
          <a href="#" @click.prevent="nextPage">
            <config-arrow-right-icon />
          </a>
        </div>
      </div>
    </div>

    <table class="config-table" :class="{ centered: tableConfig.centeredCell }">
      <thead>
        <tr>
          <th v-for="item in columns" :key="item.label">
            {{ item.label }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="item in filteredRecords" :key="item.label">
          <td v-for="col in columns" :key="col.field">
            <slot
              :name="col.field"
              :item="item"
              v-if="$scopedSlots[col.field]"
            ></slot>
            <template v-else>
              {{ traverse(item, col.field) }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      class="config-table-pagination"
      :class="{ centered: !tableConfig.showRecords }"
    >
      <div class="pagination-left" v-if="tableConfig.showRecords">
        <div v-if="tableConfig.showRecords">Registros: {{ totalRecords }}</div>
      </div>

      <div class="pagination-right">
        <div>
          <a href="#" @click.prevent="prevPage">
            <config-arrow-left-icon />
          </a>
        </div>
        <div>
          <input
            type="text"
            v-mask="'####'"
            name="page"
            id="page"
            :value="currentPage"
            disabled
          />
        </div>
        <div>
          <a href="#" @click.prevent="nextPage">
            <config-arrow-right-icon />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import ConfigSearchIcon from "@/components/Configuration/icons/ConfigSearchIcon.vue";
import ConfigArrowLeftIcon from "@/components/Configuration/icons/ConfigArrowLeftIcon.vue";
import ConfigArrowRightIcon from "@/components/Configuration/icons/ConfigArrowRightIcon.vue";

export default {
  name: "ConfigTableSSR",
  components: {
    ConfigArrowRightIcon,
    ConfigArrowLeftIcon,
    ConfigSearchIcon,
  },
  directives: { mask },
  props: {
    tableConfig: {
      type: Object,
      default: () => ({
        useSearch: true,
        centeredCell: false,
        showRecords: true,
      }),
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    lastPage: {
      type: Number,
      default: 1,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: "",
      totalRecords: 0,
    };
  },
  methods: {
    traverse(obj, keys) {
      return keys.split(".").reduce((cur, key) => cur[key], obj);
    },
    nextPage() {
      if (this.currentPage + 1 <= this.lastPage) {
        this.$emit("pageChange", this.currentPage + 1);
      }
    },
    prevPage() {
      if (this.currentPage - 1 > 0) {
        this.$emit("pageChange", this.currentPage - 1);
      }
    },
    searchInObjects(objects, searchString) {
      const results = [];

      const regExp = new RegExp(searchString, "gi");

      objects.forEach((obj) => {
        Object.entries(obj).forEach(([key, value]) => {
          if (regExp.test(String(value))) {
            results.push(obj);
          }
        });
      });

      return results;
    },
  },
  computed: {
    filteredRecords() {
      if (this.search !== "") {
        const records = this.searchInObjects(this.tableData, this.search);
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.totalRecords = records.length;

        return records;
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.totalRecords = this.tableData.length;
      return this.tableData;
    },
  },
};
</script>

<style>
.config-table {
  width: 100%;
}

.config-table p,
.config-table-pagination p {
  margin-bottom: 0;
}

.config-table-pagination,
.config-table-header {
  color: black;
  font-weight: bold;
}

.config-table thead tr th:first-of-type,
.config-table tbody tr td:first-of-type {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.config-table thead tr th:last-of-type,
.config-table tbody tr td:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.config-table thead tr th {
  background-color: var(--shortcut-round-bg);
  color: black;
}

.config-table tbody tr td {
  padding: 0.5rem 0.75rem;
}

.config-table thead tr th {
  padding: 1rem 0.75rem;
}

.config-table.centered thead tr th,
.config-table.centered tbody tr td {
  text-align: center;
}

.config-table tbody tr:nth-of-type(even) td {
  background-color: #f5f6f7;
}

.config-table-pagination {
  margin-top: 1rem;
  background-color: var(--shortcut-round-bg);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  border-radius: 10px;
}

.config-table-pagination.centered {
  justify-content: center;
}

.pagination-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.pagination-right a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 35px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: var(--primary-bg-header);
  color: white;
}

.pagination-right input {
  width: 50px;
  height: 35px;
  background: white;
  border: 0;
  border-radius: 5px;
  text-align: center;
}

.config-table-header .pagination-right input {
  border: 1px solid #e2e2e2;
}

.pagination-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.config-table-header select,
.pagination-left select {
  width: 60px;
  height: 35px;
  color: black;
  border: 0;
  padding: 0 0.5rem;
  border-radius: 5px;
  margin-left: 10px;
}

.config-table-header select {
  border: 1px solid #e2e2e2;
}

.config-table-search {
  max-width: 300px;
  position: relative;
  flex: 1 1 auto;
}

.config-table-search input {
  display: block;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  padding: 0 1rem 0 40px;
  border: 1px solid #e2e2e2;
}

.config-table-search .config-search-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-bg-header);

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 40px;
}

.config-table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
}

.config-table-tag {
  display: inline-block;
  padding: 0.1rem 0.5rem;
  border-radius: 5px;
  font-weight: 400;
  color: white;
  font-size: 0.8rem;
}

.config-table-tag.primary {
  background-color: var(--primary-bg-header);
}

.config-table-tag.success {
  background-color: #00a068;
}

.config-table-tag.danger {
  background-color: #dc3545;
}
</style>
