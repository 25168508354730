<template>
  <form id="formInsurance" class="form-layout form-layout-2 form-dates-sidebar">
    <div class="report-summary-header mg-b-5 mg-t-0">
      <div>
        <h4 class="tx-inverse mg-b-3 d-block">Seguro</h4>
        <p class="mg-b-0">
          <i class="icon ion-information-circled mg-r-3"></i> Informações de Seguro do Processo.
        </p>
      </div>
      <div>

      </div>
    </div>

    <div v-if="insuranceTabIsBlocked">
      <div class="alert alert-warning mg-b-0" role="alert">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
        <strong>Campos desabilitados!</strong> Após o preenchimento das informação da Declaração de Importação, não é
        mais possível alterar as informações do seguro.
      </div>
    </div>

    <div class="mt-2">
      <div class="row no-gutters">
        <div class="col-md-4">
          <div class="form-group bd-r-0-force mg-md-t--1 ">
            <label class="form-control-label">Porcentagem </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-percent"></i>
              </div>
              <div class="input-group-solved-center">
                <input type="number"
                       class="form-control"
                       name="insurancePercentage"
                       @blur="calculateAllValues"
                       v-model="model.insurancePercentage"
                       :disabled="insuranceTabIsBlocked && !checkIfUserCanViewPercentage"/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group bd-r-0-force mg-md-t--1 ">
            <label class="form-control-label">Valor FOB </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-money op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <money v-bind="money" class="form-control" name="fobValue" @blur.native="calculateValues"
                       v-model="model.fobValue" :disabled="insuranceTabIsBlocked"></money>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group mg-md-t--1 ">
            <label class="form-control-label">Valor total de Frete </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-money op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <money v-bind="money" class="form-control" name="freightTotalValue" @blur.native="calculateValues"
                       v-model="model.freightTotalValue" :disabled="insuranceTabIsBlocked"></money>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-4">
          <div class="form-group bd-t-0-force bd-r-0-force mg-md-t--1 ">
            <label class="form-control-label">Valor total de impostos</label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-money op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <money v-bind="money" class="form-control" name="taxesTotalValue" v-model="model.taxesTotalValue"
                       @blur.native="calculateValues" :disabled="insuranceTabIsBlocked"></money>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group bd-t-0-force bd-r-0-force mg-md-t--1 ">
            <label class="form-control-label">Despesas </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-money op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <money v-bind="money" class="form-control" name="expensesPercentage" v-model="model.expensesPercentage"
                       @blur.native="calculateFinalValues" :disabled="insuranceTabIsBlocked"></money>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group bd-t-0-force mg-md-t--1 ">
            <label class="form-control-label">Lucros </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-money op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <money v-bind="money" class="form-control" name="profitsPercentage" v-model="model.profitsPercentage"
                       @blur.native="calculateFinalValues" :disabled="insuranceTabIsBlocked"></money>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group bd-t-0-force mg-md-t--1 bg-gray-200">
            <label class="form-control-label">Importância total do Seguro </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-money op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <money v-bind="money" class="form-control" name="totalAmountInsurance"
                       v-model="model.totalAmountInsurance" disabled></money>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group bd-t-0-force bd-r-0-force mg-md-t--1 bg-gray-200">
            <label class="form-control-label">Prêmio </label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-money op-5"></i>
              </div>
              <div class="input-group-solved-center">
                <money v-bind="money" class="form-control" name="insuranceAward" v-model="model.insuranceAward"
                       disabled></money>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group bd-t-0-force mg-md-t--1 ">
            <label class="form-control-label">Descrição da mercadoria</label>
            <div class="input-group-solved">
              <div class="input-group-solved-before">
                <i class="fa fa-info op-5"></i>
              </div>
              <div class="input-group-solved-center wd-150">
                <input class="form-control wd-200" type="text" name="productDescription"
                       v-model="model.productDescription" :disabled="insuranceTabIsBlocked">
              </div>
              <div class="input-group-solved-after"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import {Money} from "v-money";
import {mapState} from "vuex";

export default {
  components: {
    Money
  },
  data() {
    return {
      money: {decimal: ",", thousands: ".", precision: 2, masked: false},
      model: {},
    }
  },
  computed: {
    ...mapState({
      process: state => state.ProcessStore.activeProcess
    }),
    options() {
      return [
        "blur",
        e => {
          setTimeout(() => {
            this.diffObjects();
          }, 200);
        },
        true
      ];
    },
    insuranceTabIsBlocked() {
      return (this.model.diNumber !== null && this.model.diNumber !== '')
        || (this.model.diRegistryDate !== null && this.model.diRegistryDate !== '')
        || (this.model.diResourcefulnessDate !== null && this.model.diResourcefulnessDate !== '')
        || (this.model.diProtocol !== null && this.model.diProtocol !== '');

      // return false;
    },
    checkIfUserCanViewPercentage() {
      const authUser = this.$store.state.credentials.data.person;

      return authUser.email === 'silvia@primeinternacional.com.br'
        || authUser.email === 'ana.eliza@primeinternacional.com.br'
        || authUser.email === 'thayse@primeinternacional.com.br'
        || authUser.email === 'maicon@primeinternacional.com.br'
        || authUser.email === 'matheus@primeinternacional.com.br'
        || authUser.email === 'allysson@primeinternacional.com.br';
    }
  },
  methods: {
    async diffObjects() {
      const response = await this.$store.dispatch("ProcessStore/diffObjects", {
        initialModel: this.initialModel,
        model: this.model
      });

      if (response) this.initialModel = {...this.model};
    },
    calculateValues() {
      if (this.model.fobValue !== null && this.model.freightTotalValue !== null) {
        let total = this.model.fobValue + this.model.freightTotalValue;


        let percentage = (10 * total) / 100;

        // Calcula as despesas
        const expansesValue = percentage;
        this.model.expensesPercentage = expansesValue;

        // Calcula os lucros
        total = total + expansesValue;
        percentage = (10 * total) / 100;
        const profitValue = percentage;

        this.model.profitsPercentage = profitValue;

        // Calcula o valor total do seguro e o prêmio
        if (this.model.taxesTotalValue !== null) {

          this.model.totalAmountInsurance = this.model.fobValue + this.model.freightTotalValue + this.model.expensesPercentage + this.model.profitsPercentage + this.model.taxesTotalValue;
          this.model.insuranceAward = (this.model.insurancePercentage * this.model.totalAmountInsurance) / 100;
          this.model.stepInsuranceValue = this.model.insuranceAward;
        }
      }
    },
    calculateFinalValues() {
      this.model.totalAmountInsurance = this.model.fobValue + this.model.freightTotalValue + this.model.expensesPercentage + this.model.profitsPercentage + this.model.taxesTotalValue;
      this.model.insuranceAward = (this.model.insurancePercentage * this.model.totalAmountInsurance) / 100;
      this.model.stepInsuranceValue = this.model.insuranceAward;
    },
    calculateAllValues() {
      this.calculateValues();
      this.calculateFinalValues();
    }
  },
  watch: {
    // 'model.insurancePercentage': function (newVal, oldVal) {
    //
    //   if (newVal !== oldVal) {
    //     this.calculateValues();
    //     this.calculateFinalValues();
    //   }
    // },
    process: function () {
      this.model = {
        ...this.process
      }
    },
  },
  mounted() {
    if (window.addEventListener) {
      setTimeout(() => {
        const form = document.getElementById("formInsurance");
        form.addEventListener(...this.options);
      }, 200);
    }

    this.model.insurancePercentage = this.model.insurancePercentage ? this.model.insurancePercentage : 0.25;
  },
  created() {
    this.model = {...this.process}
    this.initialModel = {...this.process};
  },
}
</script>