<template>
  <td class="inline-cell" :class="ctype">
    <div class="input-group-solved">
      <!-- se for string -->
      <div v-if="icon" class="input-group-solved-before">
        <i class="fa op-5" :class="icon"></i>
      </div>

      <div :class="icon ? 'input-group-solved-center' : 'input-group-solved'">
        <textarea v-if="ctype == 'textarea'" v-model="cvalue" rows="3">
 Conteúdo </textarea
        >

        <input
          v-if="ctype == 'string'"
          v-model="cvalue"
          type="text"
          :placeholder="placeholder"
          :class="inputClass"
          :name="name"
        />

        <!-- se for email -->
        <input
          v-if="ctype == 'email'"
          v-model="cvalue"
          type="email"
          :placeholder="placeholder"
          :class="inputClass"
          :name="name"
        />

        <!-- se for number -->
        <input
          v-if="ctype == 'number'"
          v-model="cvalue"
          type="text"
          :placeholder="placeholder"
          :class="inputClass"
          :name="name"
        />

        <!-- se for select true/false -->
        <select
          v-if="ctype == 'boolean'"
          v-model="cvalue"
          id=""
          :placeholder="placeholder"
          :name="name"
        >
          <option :value="true">True</option>
          <option :value="false">False</option>
        </select>

        <!-- se for select true/false -->
        <select
          v-if="ctype == 'select'"
          v-model="cvalue"
          :placeholder="placeholder"
          :name="name"
        >
          <option value="">{{ placeholder }}</option>

          <template
            v-if="selectType !== undefined && selectType === 'key|value'"
          >
            <option
              v-for="option in options"
              :key="option.key"
              :value="option.key"
            >
              {{ option.value }}
            </option>
          </template>

          <template
            v-else-if="
              selectType !== undefined && selectType === 'key|{key-value}'
            "
          >
            <option
              v-for="option in options"
              :key="option.key"
              :value="option.key"
            >
              {{ option.key }} - {{ option.value }}
            </option>
          </template>

          <template
            v-else-if="
              selectType !== undefined && selectType === 'key[object]|value'
            "
          >
            <option
              v-for="option in options"
              :key="option.key"
              :value="JSON.stringify(option)"
            >
              {{ option.value }}
            </option>
          </template>

          <template v-else>
            <option
              v-for="option in options"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </template>
        </select>

        <v-select
          v-if="ctype == 'v-select'"
          v-model="cvalue"
          :placeholder="placeholder"
          :searchable="true"
          :label="label"
          :name="name"
          :options="options"
          width="400"
        >
          <template slot="option" slot-scope="option">
            <template
              v-if="
                searchReturnKey !== undefined &&
                searchReturnKey !== null &&
                option.hasOwnProperty(searchReturnKey) !== null
              "
            >
              {{ option[searchReturnKey] }}
            </template>
            <template v-else-if="label !== null && option[label] !== null">
              {{ option[label] }}
            </template>
          </template>
        </v-select>

        <date-picker lang="pt-br"
                     v-if="ctype == 'date'"
                     :name="name"
                     value="cvalue"
                     v-model="cvalue"
                     :class="'form-control tx-center ' + inputClass"
                     format="DD/MM/YY"
                     v-validate="'required'"
                     clear-button="true"
                     :placeholder="placeholder ? placeholder : 'Data'"
        ></date-picker>

        <money
          v-if="ctype == 'money'"
          v-model="cvalue"
          v-bind="cmoney"
          :class="'form-control tx-right ' + inputClass"
          :name="name"
        ></money>
        <money
          v-if="ctype == 'money|####'"
          v-model="cvalue"
          v-bind="cmoney4"
          :class="'form-control tx-right ' + inputClass"
          :name="name"
        ></money>
        <money
          v-if="ctype == 'money|#####'"
          v-model="cvalue"
          v-bind="cmoney5"
          :class="'form-control tx-right ' + inputClass"
          :name="name"
        ></money>
        <money
          v-if="ctype == 'money|#######'"
          v-model="cvalue"
          v-bind="cmoney7"
          :class="'form-control tx-right ' + inputClass"
          :name="name"
        ></money>

        <toggle-button
          v-if="ctype == 'toggle'"
          v-model="cvalue"
          :labels="options"
          :height="16"
          :width="75"
          :color="{ unchecked: '#bf0909' }"
        />

        <vue-bootstrap-typeahead
          v-if="ctype == 'autocomplete'"
          v-model="cvalue"
          ref="typeahead"
          :data="csearchReturn"
          :label="label"
          :placeholder="placeholder"
          :serializer="serializer"
          :minMatchingChars="minMatchingChars"
        />

        <div v-if="ctype == 'actions'">
          <slot></slot>
        </div>
      </div>
    </div>
  </td>
</template>

<script>
import Axios from "axios";
import DatePicker from "vue2-datepicker";
import ToggleButton from "vue-js-toggle-button/src/Button.vue";
import {Money} from "v-money";
import Typeahead from "@/components/typeahead/VueBootstrapTypeahead";
import _ from "lodash";

export default {
  components: {
    DatePicker,
    Money,
    ToggleButton,
    "vue-bootstrap-typeahead": Typeahead,
  },
  props: [
    "value",
    "name",
    "icon",
    "options",
    "type",
    "selectType",
    "placeholder",
    "inputClass",
    "money",
    "searchURL",
    "label",
    "searchReturnKey",
    "searchReturn",
    "serializer",
    "minMatchingChars",
  ],
  data() {
    return {
      cmoney: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      cmoney4: {
        decimal: ",",
        thousands: ".",
        precision: 4,
        masked: false,
      },
      cmoney5: {
        decimal: ",",
        thousands: ".",
        precision: 5,
        masked: false,
      },
      cmoney7: {
        decimal: ",",
        thousands: ".",
        precision: 7,
        masked: false,
      },
      ctype: "",
      cvalue: "",
      edit: false,
      csearchReturn: [],
      searchSelected: null,
    };
  },
  methods: {
    searchAutocomplete: _.debounce(function (query) {
      try {
        this.$store.commit("setLoading", {
          show: true,
          label: "Pesquisando",
          description: `Pesquisando por <b>${query}</b>, aguarde...`,
        });

        Axios.get(this.searchURL.replace(":query", query)).then((response) => {
          this.csearchReturn = response.data._embedded[this.searchReturnKey];
          this.$store.commit("setLoading", {
            show: false,
          });
        });
      } catch (err) {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    }, 500),
  },
  filters: {
    truncate(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      }
      return text;
    },
  },
  watch: {
    cvalue(a) {
      this.$emit("input", this.cvalue);
      this.$emit("change", this.cvalue);
    },

    value(a) {
      if (this.ctype === "autocomplete") {
        this.cvalue = this.value;
        this.searchAutocomplete(a);
      } else if (
        this.ctype === "money" ||
        this.ctype === "money|####" ||
        this.ctype === "money|#####" ||
        this.ctype === "money|#######"
      ) {
        if (this.ctype === "money") {
          this.cvalue = parseFloat(this.value).toFixed(2);
        } else if (this.ctype === "money|####") {
          this.cvalue = parseFloat(this.value).toFixed(4);
        } else if (this.ctype === "money|#####") {
          this.cvalue = parseFloat(this.value).toFixed(5);
        } else if (this.ctype === "money|#######") {
          this.cvalue = parseFloat(this.value).toFixed(7);
        }
      } else {
        this.cvalue = this.value;
      }
    },
  },
  mounted() {
    if (this.ctype === "autocomplete") {
      this.$store.commit("setLoading", {
        show: true,
        label: "Procesando",
        description: "Carregando dados do componente, aguarde...",
      });
    }

    setTimeout(() => {
      if (
        this.ctype === "autocomplete" &&
        this.searchURL !== undefined &&
        this.searchURL !== null &&
        this.$refs.typeahead !== undefined &&
        this.searchReturn === undefined
      ) {
        this.$refs.typeahead.inputValue = this.value;
      } else if (this.ctype === "money") {
        this.cvalue = parseFloat(this.cvalue).toFixed(2);
      } else {
        this.csearchReturn = this.searchReturn;
      }

      this.$store.commit("setLoading", {
        show: false,
      });
    }, 0);
  },
  created() {
    if (!this.type) {
      this.ctype = typeof this.value;

      if (this.ctype == "object") {
        Array.isArray(this.value)
          ? (this.ctype = "array")
          : (this.ctype = "object");
      }
    } else {
      this.ctype = this.type;
    }

    this.cvalue = this.value;

    if (this.money) {
      this.cmoney = this.money;
    }
  },
};
</script>

<style>
.input-group-solved .mx-datepicker table th,
.input-group-solved .mx-datepicker table td {
  padding: 0 !important;
}

.input-group-solved .mx-input-wrapper .mx-clear-wrapper {
  margin-top: -8px !important;
}
</style>

<style scoped>
td {
  vertical-align: middle;
  min-width: 100px;
}

td.date {
  min-width: 160px;
}

input,
select,
textarea {
  border: none;
  background: transparent;
  margin: 0;
  color: #5b636a;
  width: 100%;
}

input:focus,
select:focus,
textarea:focus {
  background: #dee2e6;
  outline: none;
}

.inline-cell .mx-datepicker {
  width: 100%;
}

.input-group-solved-before {
  max-width: 20% !important;
}

.input-group-solved-center {
  width: 100% !important;
  max-width: 100% !important;
}

.minify-td {
  padding: 0.2rem 0.2rem 0.2rem 15px;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
</style>
