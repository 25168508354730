<template>
  <div>
    <form @submit.prevent="submitForm" class="contact-form">
      <div class="contact-form ph-2 sp-3">
        <div class="sp-3 mt-3 pd-t-10">
          <h4>Informações Financeiras</h4>
        </div>

        <div>
          <v-input
            name="financesBank"
            type="financesBank"
            label="Banco"
            v-model="customerDetails.financesBank"
          ></v-input>
          <div class="field-error" v-if="getError('financesBank')">
            {{ getError("financesBank") }}
          </div>
        </div>

        <div>
          <v-input
            name="financesAgency"
            type="financesAgency"
            label="Agência"
            v-model="customerDetails.financesAgency"
          ></v-input>
          <div class="field-error" v-if="getError('financesAgency')">
            {{ getError("financesAgency") }}
          </div>
        </div>

        <div>
          <v-input
            name="financesAccountType"
            type="financesAccountType"
            label="Tipo de Conta"
            v-model="customerDetails.financesAccountType"
          ></v-input>
          <div class="field-error" v-if="getError('financesAccountType')">
            {{ getError("financesAccountType") }}
          </div>
        </div>

        <div>
          <v-input
            name="financesAccount"
            type="financesAccount"
            label="Número da Conta"
            v-model="customerDetails.financesAccount"
          ></v-input>
          <div class="field-error" v-if="getError('financesAccount')">
            {{ getError("financesAccount") }}
          </div>
        </div>

        <div>
          <v-input
            name="financesFavoredName"
            type="financesFavoredName"
            label="Nome do Favorecido"
            v-model="customerDetails.financesFavoredName"
          ></v-input>
          <div class="field-error" v-if="getError('financesFavoredName')">
            {{ getError("financesFavoredName") }}
          </div>
        </div>

        <div>
          <v-input
            name="financesFavoredIdentification"
            type="financesFavoredIdentification"
            label="Documento do Favorecido"
            v-model="customerDetails.financesFavoredIdentification"
            :hint="'Número do CNPJ do Cliente'"
          ></v-input>
          <div
            class="field-error"
            v-if="getError('financesFavoredIdentification')"
          >
            {{ getError("financesFavoredIdentification") }}
          </div>
        </div>

        <div class="sp-3 row justify-content-center mt-2 mb-2">
          <button type="submit" class="report-button">Atualizar Dados</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import config from "@/config.js";
import VInput from "@/components/VInput.vue";
import Swal from "sweetalert2";
import Axios from "axios";
import CredentialService from "@/services/CredentialService.js";

import * as yup from "yup";

export default {
  name: "CustomerCommercial",
  props: {
    customerDetails: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    VInput,
  },
  data() {
    return {
      formErrors: [],
      comissioneds: [],
      selectComissioneds: [],
      addDialog: false,
      newComissioned: {},
      salesRadarModalities: [
        {
          value: "EXPRESS",
          label: "Expressa USD 50.000",
        },
        {
          value: "LIMITED",
          label: "Limitada USD 150.000",
        },
        {
          value: "ILIMITED",
          label: "Ilimitada USD",
        },
      ],
      teams: [],
    };
  },
  async mounted() {},
  methods: {
    getError(path) {
      const er = this.formErrors.find((err) => err.path === path);
      if (er) {
        return er.message;
      }
      return "";
    },
    hasPrivilege(privilege) {
      return CredentialService.hasPrivilege(privilege);
    },
    async submitForm() {
      Swal.fire({
        title: "Atualização de Dados",
        html: "<p>Tem certeza que deseja atualizar os dados da empresa?</p>",
        type: "warning",
        showConfirmButton: true,
        confirmButtonText: "Atualizar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          this.formErrors = [];

          try {
            const financeSchema = yup.object().shape({
              financesBank: yup
                .string()
                .max(100, "Máximo de 100 caractéres")
                .required("Nome do Banco é obrigatório!")
                .typeError("Campo inválido!"),
              financesAgency: yup
                .string()
                .max(100, "Máximo de 100 caractéres")
                .required("Agência é obrigatório!")
                .typeError("Campo inválido!"),
              financesAccountType: yup
                .string()
                .max(100, "Máximo de 100 caractéres")
                .required("Tipo de Conta é obrigatório!")
                .typeError("Campo inválido!"),
              financesAccount: yup
                .string()
                .max(100, "Máximo de 100 caractéres")
                .required("Número da Conta é obrigatório!")
                .typeError("Campo inválido!"),
              financesFavoredName: yup
                .string()
                .max(100, "Máximo de 100 caractéres")
                .required("Nome do Favorecido é obrigatório!")
                .typeError("Campo inválido!"),
              financesFavoredIdentification: yup
                .string()
                .max(100, "Máximo de 100 caractéres")
                .required("Documento do Favorecido é obrigatório!")
                .typeError("Campo inválido!"),
            });

            await financeSchema.validate(this.customerDetails, {
              abortEarly: false,
            });

            this.$store.commit("setLoading", {
              show: true,
              label: "Aguarde...",
              description: "Atualizando os dados da Empresa.",
            });

            const response = await Axios.put(
              `${config.env.API_DEFAULT.host}/company/${this.customerDetails.id}`,
              this.customerDetails
            );

            Swal.fire({
              title: "Atualizar dados da Empresa",
              text: response.data.message,
              type: "success",
            });
          } catch (e) {
            if (e.inner) {
              this.formErrors = e.inner;
            } else {
              Swal.fire({
                title: e.response.data.message,
                text: e.response.data.data,
                type: "error",
              });
            }
          }
          this.$store.commit("setLoading", {
            show: false,
          });
        }
      });
    },
    getTeams() {
      Axios.get(`${config.env.API_DEFAULT.host}/teams`)
        .then((response) => {
          const teams = response.data._embedded.teams.map((team) => ({
            label: `${team.name} - ${team.email}`,
            value: team.id,
          }));

          this.teams = teams;
        })
        .catch((error) => {
          swal(
            "Cadastro de Empresa",
            `Ops, houve algum erro: ${error}`,
            "error"
          );
        });
    },
  },
};
</script>

<style scoped>
.plus-button {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #80b7e2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
}

.plus-button:hover {
  background-color: #1768a7;
}

.activity-submit {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #104375;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
  color: white;
  padding: 10px 25px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out 0s;
}

.activity-submit:hover {
  background-color: #1768a7;
}

.activity-enter-active,
.activity-leave-active {
  transition: all 0.5s ease-in-out 0s;
  overflow: hidden;
}

.activity-enter,
.activity-leave-to {
  max-height: 0px;
  overflow: hidden;
}

.activity-enter-to,
.activity-leave {
  transition: all 0.5s ease-in-out 0s;
  max-height: 1000px;
  overflow: hidden;
}

table {
  width: 100%;
}

table tr:not(:last-of-type) td {
  border-bottom: 1px solid #e2e2e2;
}

table tr td,
table tr th {
  padding: 20px 10px;
  color: #343a40;
  text-align: center;
}

table tr th {
  background-color: #8392a51a;
}

.tag {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #e2e2e2;
  font-weight: bold;
}

.tag.success {
  background-color: #c4ffc0;
  color: #0f8906;
}

.tag.danger {
  background-color: #ffc0c0;
  color: #890606;
}

.report-button {
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #104375;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
  color: white;
  padding: 10px 35px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out 0s;
  font-size: 1rem;
}

.report-button:hover {
  background-color: #1768a7;
}
</style>
